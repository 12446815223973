import React from "react";
import { useParamPageNumber } from "src/hooks/pagination";
import Button from "src/components/ui/form/button";
import { useNavigate } from "react-router-dom";
import { useThemedStyles } from "src/hooks/styles";
import { useSearchParamsString } from "src/hooks/search-params";
import Text from "src/components/ui/text";
import { useTranslation } from "react-i18next";
import Icon from "src/components/ui/icons";
import styles, { IStyles } from "src/components/ui/pagination/LimitOffsetPagination.styles";
import { useBreakpointIsLowerOrEqual } from "src/hooks/responsive";

interface PaginationItemProps {
  page: number;
  style?: React.CSSProperties;
  onClick: (page: number) => void;
}

const PaginationItem: React.FC<PaginationItemProps> = (
  {
    page,
    onClick,
    style = {},
  },
) => {
  const handleClick = () => {
    onClick(page);
  };

  return (
    <Button
      style={style}
      size="sm"
      variant="link"
      key={page}
      onClick={handleClick}
    >
      <span className="text-dark">{page}</span>
    </Button>
  );
};

interface LimitOffsetPaginationProps {
  style?: React.CSSProperties;
  pageSize: number;
  total: number;
  baseUrl: string;
}

const LimitOffsetPagination: React.FC<LimitOffsetPaginationProps> = (
  {
    pageSize,
    total,
    baseUrl,
    style = {},
  },
) => {
  const isXs = useBreakpointIsLowerOrEqual("xs");
  const pageRange = React.useMemo(() => {
    if (isXs) {
      return 1;
    }
    return 2;
  }, [isXs]);
  const { t } = useTranslation();
  const currentPage = useParamPageNumber();
  const navigate = useNavigate();
  const searchParams = useSearchParamsString();
  const themedStyles = useThemedStyles<IStyles>(styles);

  const totalPages = React.useMemo(() => {
    if (total && pageSize) {
      return Math.ceil(total / pageSize);
    }
    return 0;
  }, [pageSize, total]);

  const handleClick = React.useCallback((page: number) => {
    let pathname = baseUrl;
    if (page > 1) {
      pathname = `${baseUrl}${page}/`;
    }
    navigate({ pathname, search: searchParams });
  }, [baseUrl, navigate, searchParams]);

  const items = React.useMemo(() => {
    if (totalPages <= 1) {
      return [];
    }
    const paginationItems = [];
    let rightAddition = 0;

    for (let i = pageRange; i >= 1; i -= 1) {
      const itemPage = currentPage - i;
      if (itemPage < 1) {
        rightAddition += 1;
      } else {
        paginationItems.push((
          <PaginationItem
            style={themedStyles.button}
            key={itemPage}
            onClick={handleClick}
            page={itemPage}
          />
        ));
      }
    }

    paginationItems.push((
      <Button
        style={themedStyles.button}
        key={currentPage}
        variant="primary"
        size="sm"
      >
        {currentPage}
      </Button>
    ));

    let leftAddition = 0;

    for (let i = 1; i <= pageRange + rightAddition; i += 1) {
      const itemPage = currentPage + i;
      if (itemPage <= totalPages) {
        paginationItems.push((
          <PaginationItem
            style={themedStyles.button}
            key={itemPage}
            onClick={handleClick}
            page={itemPage}
          />
        ));
      } else {
        leftAddition += 1;
      }
    }

    if (leftAddition > 0) {
      for (let i = 1; i <= leftAddition; i += 1) {
        const itemPage = currentPage - pageRange - i;
        if (itemPage > 0) {
          paginationItems.unshift((
            <PaginationItem
              style={themedStyles.button}
              key={itemPage}
              onClick={handleClick}
              page={itemPage}
            />
          ));
        }
      }
    }

    if (currentPage - pageRange - leftAddition > 1) {
      paginationItems.unshift((
        <Text
          onClick={() => handleClick(currentPage - pageRange - leftAddition - 1)}
          color="secondary"
          style={themedStyles.link}
          key="prev"
        >
          <Icon
            style={themedStyles.linkArrow}
            variant="inherit"
            name="arrow-left"
            size={12}
          />
          {t("Prev")}
        </Text>
      ));
    }

    if (totalPages > currentPage + pageRange + rightAddition) {
      paginationItems.push((
        <Text
          onClick={() => handleClick(currentPage + pageRange + rightAddition + 1)}
          color="secondary"
          style={themedStyles.link}
          key="next"
        >
          {t("Next")}
          <Icon
            style={{ ...themedStyles.linkArrow, transform: "rotate(180deg)" }}
            variant="inherit"
            name="arrow-left"
            size={12}
          />
        </Text>
      ));
    }

    return paginationItems;
  }, [currentPage, totalPages, handleClick, pageRange]);

  if (total <= pageSize) {
    return null;
  }

  if (currentPage > totalPages) {
    return (
      <nav style={{ ...themedStyles.nav, ...style }}>
        <Text
          onClick={() => handleClick(1)}
          color="secondary"
          style={themedStyles.link}
        >
          <Icon
            style={themedStyles.linkArrow}
            variant="inherit"
            name="arrow-left"
            size={12}
          />
          {t("First page")}
        </Text>
      </nav>
    );
  }

  return (
    <nav style={{ ...themedStyles.nav, ...style }}>
      {items}
    </nav>
  );
};

export default LimitOffsetPagination;

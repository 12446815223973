import create from "zustand";
import { persist } from "zustand/middleware";

export interface IMenuStore {
  isOpen: boolean;
  toggle: () => void;
  setIsOpen: (state: boolean) => void;
}

export const useMenuStore = create<IMenuStore>()(
  persist(
    (set, get) => ({
      isOpen: true,
      toggle: () => set({ isOpen: !get().isOpen }),
      setIsOpen: (isOpen) => set({ isOpen }),
    }),
    {
      name: "menuStore",
    },
  ),
);

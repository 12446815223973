/** @jsxImportSource @emotion/react */
import React from "react";
import Form from "react-bootstrap/esm/Form";
import Icon from "src/components/ui/icons";
import FormGroup, { FormGroupProps } from "src/components/ui/form/group/FormGroup";
import { useThemedCss } from "src/hooks/styles";
import { useBooleanState } from "src/hooks/boolean";
import { css as cssFn, SerializedStyles } from "@emotion/react";

import styles, { IStyles } from "./ExpandableFormGroup.styles";

type ExpandableFormGroupProps = FormGroupProps & {
  label: React.ReactNode;
  isOpen?: boolean;
  css?: SerializedStyles;
  contentCss?: SerializedStyles;
}

const ExpandableFormGroup: React.FC<ExpandableFormGroupProps> = (
  {
    className,
    children,
    label,
    css,
    contentCss,
    isOpen = false,
    ...props
  },
) => {
  const {
    state: isExpanded,
    toggle: toggleExpanded,
  } = useBooleanState(() => isOpen);
  const themedCss = useThemedCss<IStyles>(styles, isExpanded);

  return (
    <FormGroup
      className={className}
      css={[themedCss.container, css]}
      {...props}
    >
      <Form.Label css={themedCss.label} onClick={toggleExpanded}>
        {label}
        <Icon
          css={[
            themedCss.icon,
            cssFn({ transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }),
          ]}
          size={24}
          name="arrow-down"
        />
      </Form.Label>
      {isExpanded && (
        <div css={[themedCss.content, contentCss]}>
          {children}
        </div>
      )}
    </FormGroup>
  );
};

export default ExpandableFormGroup;

import React from "react";
import UIFormControl, { FormControlProps as UIFormControlProps } from "react-bootstrap/esm/FormControl";
import Icon from "src/components/ui/icons";
import InputGroup from "react-bootstrap/esm/InputGroup";
import type { Control } from "react-hook-form/dist/types/form";
import { useController } from "react-hook-form";

type FormControlPhoneProps = UIFormControlProps & {
  name: string;
  control: Control<any>;
  required?: boolean;
};

const FormControlPhone: React.FC<FormControlPhoneProps> = (
  {
    name,
    control,
    ...props
  },
) => {
  const {
    field: {
      onBlur,
      onChange,
      value: formValue,
      ref,
    },
  } = useController({ name, control });

  const [internalValue, setInternalValue] = React.useState<string>(() => {
    if (formValue?.startsWith("+1")) {
      return formValue.replace(/^\+1/, "");
    }
    return formValue || "";
  });

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    let newValue = event.target.value;
    if (newValue.startsWith("+1")) {
      newValue = newValue.replace(/^\+1/, "");
    }
    setInternalValue(newValue);
  };

  React.useEffect(() => {
    if (!internalValue && !formValue) {
      return;
    }

    if (internalValue) {
      onChange(`+1${internalValue}`);
    } else {
      onChange("");
    }
  }, [internalValue]);

  const handleTextClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <InputGroup className="av-input-group av-input-group--no-border" onClick={handleTextClick}>
      <InputGroup.Text>
        <Icon name="phone" size={20} variant="primary" className="me-1" />
        +1
      </InputGroup.Text>
      <UIFormControl
        ref={ref}
        placeholder="(xxx) xxx-xxxx"
        type="text"
        name={name}
        value={internalValue}
        inputMode="tel"
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
    </InputGroup>
  );
};

export default FormControlPhone;

import React from "react";
import Card from "react-bootstrap/esm/Card";
import { AsDateTime, AsPrice } from "src/components/ui/formatters";
import { Link } from "react-router-dom";
import Button from "src/components/ui/form/button";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import { useAccountAgentListRequest } from "src/hooks/api/admin/account";
import { useAgentMarketCampaignUpdateRequest } from "src/hooks/api/agent/market-campaign";
import Select from "react-select";
import { Badge } from "react-bootstrap";
import lazyWithRetry from "src/utils/lazy-with-retry";

interface MarketCampaignCardProps {
  className?: string;
  marketCampaign: AgentAPI.MarketCampaign.MarketCampaignData;
  showLink?: boolean;
  onUpdated?: () => void;
}

const MarketCampaignCard: React.FC<MarketCampaignCardProps> = (
  {
    className,
    marketCampaign,
    showLink = true,
    onUpdated,
  },
) => {
  const canAssignAgent = useProfileHasPermission(MembershipPermission.ACCESS_MARKET_CAMPAIGN_AGENT_ASSIGN);
  const hasRejectMarketCampaignPerm = useProfileHasPermission(MembershipPermission.ACCESS_MARKET_CAMPAIGN_REJECT);
  const hasExecutionPerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGN_EXECUTION);

  const canRejectMarketCampaign = React.useMemo(() => (
    hasRejectMarketCampaignPerm && !marketCampaign.is_rejected
  ), [hasRejectMarketCampaignPerm, marketCampaign.is_rejected]);

  const canCreateParserExecutions = React.useMemo(() => (
    hasExecutionPerm && marketCampaign.executions_count === 0 && marketCampaign.is_active
  ), [hasExecutionPerm, marketCampaign.executions_count]);

  const RejectModal = React.useMemo(() => {
    if (canRejectMarketCampaign) {
      return lazyWithRetry(() => (
        import("src/pages/agent/market-campaigns/components/MarketCampaignRejectModal")
      ));
    }

    return null;
  }, [canRejectMarketCampaign]);

  const ParserExecutionModal = React.useMemo(() => {
    if (canCreateParserExecutions) {
      return lazyWithRetry(() => (
        import("src/pages/agent/market-campaigns/components/MarketCampaignParserExecutionModal")
      ));
    }

    return null;
  }, [canCreateParserExecutions]);

  const {
    data: agentData,
    refetch,
  } = useAccountAgentListRequest({ limit: 50 }, false);

  const {
    mutate,
    data: mutateData,
    isSuccess: mutateSuccess,
  } = useAgentMarketCampaignUpdateRequest(marketCampaign.id);

  React.useEffect(() => {
    refetch().finally();
  }, [canAssignAgent]);

  const agents = React.useMemo(() => {
    if (!agentData) {
      return [];
    }

    return agentData.data.results.map((agent) => ({
      value: agent.id,
      label: `${agent.name} (${agent.email})`,
    }));
  }, [agentData]);

  const handleAgentChange = (selectedAgent: any) => {
    mutate({ agent: selectedAgent.value });
  };

  React.useEffect(() => {
    if (mutateSuccess && mutateData?.data.id && onUpdated) {
      onUpdated();
    }
  }, [mutateSuccess, mutateData, onUpdated]);

  return (
    <Card className={className}>
      <Card.Header className="p-2">
        <Card.Title className="mb-0 d-flex justify-content-between">
          {`${marketCampaign.market.name}, ${marketCampaign.market_state.code}`}
          {marketCampaign.is_active && (
            <Badge bg="success">
              Active
            </Badge>
          )}
          {!marketCampaign.is_active && !marketCampaign.is_rejected && marketCampaign.paid_order.is_completed && (
            <Badge bg="primary">
              Completed
            </Badge>
          )}
          {!marketCampaign.is_active && marketCampaign.is_rejected && marketCampaign.paid_order.is_completed && (
            <Badge bg="info">
              Rejected
            </Badge>
          )}
          {!marketCampaign.is_active && !marketCampaign.paid_order.is_completed && (
            <Badge bg="danger">
              Unpaid
            </Badge>
          )}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
          Ends at:
          <b className="fs-6 text-end">
            <AsDateTime value={marketCampaign.ends_at} />
          </b>
        </Card.Text>
        <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
          Requested by:
          <b className="fs-6 text-end">
            {marketCampaign.requested_by.name}
          </b>
        </Card.Text>
        <Card.Text className="d-flex fs-8 mb-2 justify-content-end">
          <b className="fs-6 text-end">
            {marketCampaign.requested_by.email}
          </b>
        </Card.Text>
        <Card.Text className="d-flex fs-8 mb-2 justify-content-end">
          <b className="fs-6 text-end">
            {marketCampaign.requested_by.phone_number}
          </b>
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
          Max budget:
          <AsPrice className="fs-6 fw-bold text-end" value={marketCampaign.max_budget} />
        </Card.Text>
        <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
          Lease timeline:
          <b className="fs-6 text-end">
            {marketCampaign.lease_timeline_display}
          </b>
        </Card.Text>
        {marketCampaign.notes && (
          <Card.Text className="fs-7">
            {marketCampaign.notes}
          </Card.Text>
        )}
      </Card.Body>

      {canAssignAgent && marketCampaign.paid_order && (
        <Card.Body>
          <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
            Order amount:
            <b className="fs-6 text-end">
              <AsPrice value={marketCampaign.paid_order.total} />
            </b>
          </Card.Text>
          <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
            Payment status:
            <b className="fs-6 text-end">
              {marketCampaign.paid_order.payment_status_display}
            </b>
          </Card.Text>
          <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
            Parser tasks:
            <b className="fs-6 text-end">
              {marketCampaign.executions_count}
            </b>
          </Card.Text>
        </Card.Body>
      )}

      {canAssignAgent && (
        <Card.Body>
          <Card.Text className="d-flex fs-8 mb-2 align-items-center justify-content-between">
            Agent:
            <b className="fs-6 text-end">
              {marketCampaign.agent?.name || "Not assigned"}
            </b>
          </Card.Text>
          {marketCampaign.agent && (
            <Card.Text className="d-flex fs-8 mb-2 justify-content-end">
              <b className="fs-6 text-end">
                {marketCampaign.agent.email}
              </b>
            </Card.Text>
          )}
          {!marketCampaign.paid_order.is_completed && !marketCampaign.agent && (
            <Card.Text className="d-flex fs-7 text-danger">
              Assign agent will be available once order will be paid.
            </Card.Text>
          )}
        </Card.Body>
      )}

      {canAssignAgent && (
        <Card.Body>
          <Select
            className="custom-react-select"
            classNamePrefix="react-select"
            placeholder={marketCampaign?.agent ? "Change agent" : "Assign agent"}
            options={agents}
            onChange={handleAgentChange}
            isSearchable
          />
        </Card.Body>
      )}
      {(showLink || canRejectMarketCampaign || canCreateParserExecutions) && (
        <Card.Footer className="p-2">
          {canRejectMarketCampaign && RejectModal && (
            <React.Suspense>
              <RejectModal
                marketCampaign={marketCampaign}
              />
            </React.Suspense>
          )}
          {canCreateParserExecutions && ParserExecutionModal && (
            <React.Suspense>
              <ParserExecutionModal
                marketCampaign={marketCampaign}
              />
            </React.Suspense>
          )}
          {showLink && (
            <Link to={`/agent/market-campaigns/detail/${marketCampaign.id}`}>
              <Button
                variant="secondary"
                size="sm"
              >
                Open workspace
              </Button>
            </Link>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default MarketCampaignCard;

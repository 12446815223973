import type { RouteObject } from "react-router-dom";
import React from "react";
import AdminRequiredWrapper from "src/pages/admin/AdminRequiredWrapper";
import lazyWithRetry from "src/utils/lazy-with-retry";

const SchedulersPage = lazyWithRetry(() => import("src/pages/admin/schedulers/SchedulersPage"));
const SchedulerExecutionListPage = lazyWithRetry(() => import("src/pages/admin/schedulers/execution-list"));
const Dashboard = lazyWithRetry(() => import("src/pages/admin/components/dashboard"));

const schedulersRouter: RouteObject[] = [
  {
    path: "schedulers/",
    element: <AdminRequiredWrapper><SchedulersPage /></AdminRequiredWrapper>,
    children: [
      {
        index: true,
        element: <AdminRequiredWrapper><Dashboard statisticKey="executions" /></AdminRequiredWrapper>,
      },
      {
        path: "executions/",
        element: <AdminRequiredWrapper><SchedulerExecutionListPage /></AdminRequiredWrapper>,
      },
      {
        path: "executions/:page/",
        element: <AdminRequiredWrapper><SchedulerExecutionListPage /></AdminRequiredWrapper>,
      },
    ],
  },
];

export default schedulersRouter;

import { makeRequest } from "src/api/request";

export const listParserTask = async (params?: AdminAPI.Scrapers.ParserTaskList.Request) => (
  await makeRequest.get<AdminAPI.Scrapers.ParserTaskList.Response>("/admin/scrapers/tasks/", { params })
);

export const createParserTask = async (data: AdminAPI.Scrapers.ParserTaskCreateUpdate.Request) => (
  await makeRequest.post<AdminAPI.Scrapers.ParserTaskCreateUpdate.Response>("/admin/scrapers/tasks/", data)
);

export const updateParserTask = async (
  id: AdminAPI.Scrapers.ParserTask["id"],
  data: AdminAPI.Scrapers.ParserTaskCreateUpdate.Request,
) => (
  await makeRequest.put<AdminAPI.Scrapers.ParserTaskCreateUpdate.Response>(`/admin/scrapers/tasks/${id}/`, data)
);

export const patchParserTask = async (
  id: AdminAPI.Scrapers.ParserTask["id"],
  data: Partial<AdminAPI.Scrapers.ParserTaskCreateUpdate.Request>,
) => (
  await makeRequest.patch<AdminAPI.Scrapers.ParserTaskCreateUpdate.Response>(`/admin/scrapers/tasks/${id}/`, data)
);

export const deleteParserTask = async (id: AdminAPI.Scrapers.ParserTask["id"]) => (
  await makeRequest.delete<AdminAPI.Scrapers.ParserTaskDelete.Response>(`/admin/scrapers/tasks/${id}/`)
);

export const createParserTaskExecution = async (
  id: AdminAPI.Scrapers.ParserTask["id"],
  data: AdminAPI.Scrapers.ParserTaskCreateExecution.Request,
) => (
  await makeRequest.post<AdminAPI.Scrapers.ParserTaskCreateExecution.Response>(
    `/admin/scrapers/tasks/${id}/create-execution/`,
    data,
  )
);

export const listParserExecution = async (params?: AdminAPI.Scrapers.ParserExecutionList.Request) => (
  await makeRequest.get<AdminAPI.Scrapers.ParserExecutionList.Response>(
    "/admin/scrapers/executions/",
    { params },
  )
);

export const parserExecutionSendToQueue = async (id: AdminAPI.Scrapers.ParserExecution["id"]) => (
  await makeRequest.post<AdminAPI.Scrapers.ParserExecutionSendToQueue.Response>(
    `/admin/scrapers/executions/${id}/send-to-queue/`,
  )
);

export const parserExecutionSignedLogUrl = async (id: AdminAPI.Scrapers.ParserExecution["id"]) => (
  await makeRequest.get<AdminAPI.Scrapers.ParserExecutionSignedLogUrl.Response>(
    `/admin/scrapers/executions/${id}/signed-log-url/`,
  )
);

export const collectPropertiesFromQueue = async () => (
  await makeRequest.post<AdminAPI.Scrapers.CollectPropertiesFromQueue.Response>(
    "/admin/scrapers/collect-properties/",
    {
      enabled: false,
    },
  )
);

export const createSingleParserExecution = async (data: AdminAPI.Scrapers.CreateParserExecution.Request) => (
  await makeRequest.post<AdminAPI.Scrapers.CreateParserExecution.Response>(
    "/admin/scrapers/executions/",
    data,
  )
);

export const updateParserExecution = async (
  id: AdminAPI.Scrapers.ParserExecution["id"],
  data: AdminAPI.Scrapers.UpdateParserExecution.Request,
) => (
  await makeRequest.patch<AdminAPI.Scrapers.UpdateParserExecution.Response>(
    `/admin/scrapers/executions/${id}/`,
    data,
  )
);

export const getMarketRequestList = async (
  params: AdminAPI.Scrapers.MarketRequest.Request,
) => (
  await makeRequest.get<AdminAPI.Scrapers.MarketRequest.Response>(
    `/admin/locations/area-requests/`,
    { params },
  )
);

export const marketRequestUpdate = async (
  id: AdminAPI.Scrapers.MarketRequestData["id"],
  data: Partial<AdminAPI.Scrapers.MarketRequestUpdate.Request>,
) => (
  await makeRequest.patch<AdminAPI.Scrapers.MarketRequestUpdate.Response>(
    `/admin/locations/area-requests/${id}/`,
    data,
  )
);

export const getExecutionKeywordList = async () => (
  await makeRequest.get<AdminAPI.Scrapers.ExecutionKeywordList.Response>(
    `/admin/scrapers/execution-keywords/`,
  )
);

import type { NavigateFunction } from "react-router/dist/lib/hooks";

let navigate: NavigateFunction = (path) => {
  window.location.replace(path as string);
};

export const externalNavigate = () => navigate;

export const setExternalNavigate = (navigateFunc: NavigateFunction) => {
  navigate = navigateFunc;
};

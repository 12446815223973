import React from "react";
import PagePreloader from "src/components/loaders";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";

interface ICommonWrapper {
  children: JSX.Element;
}

const CommonWrapper: React.FC<ICommonWrapper> = ({ children }) => (
  <React.Suspense fallback={(<PagePreloader />)}>
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  </React.Suspense>
);

export default CommonWrapper;

import create from "zustand";

export interface ITitleStore {
  title: string;
  setTitle: (title: string) => void;
}

export const useTitleStore = create<ITitleStore>()(
  (set, get) => ({
    title: get()?.title || "",
    setTitle: (title) => set({ title }),
  }),
);

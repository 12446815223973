import React from "react";
import type { RouteObject } from "react-router-dom";
import AgentRequiredWrapper from "src/pages/agent/AgentRequiredWrapper";
import MarketCampaignsPage from "src/pages/agent/market-campaigns/MarketCampaignsPage";
import MyMarketCampaignsPage from "src/pages/agent/market-campaigns/MyMarketCampaignsPage";
import { MembershipPermission } from "src/enums/permission";
import MarketCampaignWorkPage from "src/pages/agent/market-campaigns/work/MarketCampaignWorkPage";
import MarketCampaignWorkDashboardPage from "src/pages/agent/market-campaigns/work/MarketCampaignWorkDashboardPage";
import MarketCampaignWorkSubmitPropertyPage
  from "src/pages/agent/market-campaigns/work/MarketCampaignWorkSubmitPropertyPage";
import MarketCampaignWorkPropertyListPage
  from "src/pages/agent/market-campaigns/work/MarketCampaignWorkPropertyListPage";
import MarketCampaignWorkRentMarketPage from "src/pages/agent/market-campaigns/work/MarketCampaignWorkRentMarketPage";
import MarketCampaignWorkContactPage from "src/pages/agent/market-campaigns/work/MarketCampaignWorkContactPage";

const marketCampaignRouter: RouteObject[] = [
  {
    path: "market-campaigns/",
    element: <AgentRequiredWrapper><MarketCampaignsPage /></AgentRequiredWrapper>,
    children: [
      {
        index: true,
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
            <MyMarketCampaignsPage />
          </AgentRequiredWrapper>
        ),
      },
      {
        path: ":page/",
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
            <MarketCampaignsPage />
          </AgentRequiredWrapper>
        ),
      },
      {
        path: "detail/:id/",
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
            <MarketCampaignWorkPage />
          </AgentRequiredWrapper>
        ),
        children: [
          {
            index: true,
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                <MarketCampaignWorkDashboardPage />
              </AgentRequiredWrapper>
            ),
          },
          {
            path: "properties/",
            children: [
              {
                path: "",
                element: (
                  <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                    <MarketCampaignWorkPropertyListPage />
                  </AgentRequiredWrapper>
                ),
              },
              {
                path: ":page/",
                element: (
                  <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                    <MarketCampaignWorkPropertyListPage />
                  </AgentRequiredWrapper>
                ),
              },
            ],
          },
          {
            path: "rent-market/",
            children: [
              {
                path: "",
                element: (
                  <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                    <MarketCampaignWorkRentMarketPage />
                  </AgentRequiredWrapper>
                ),
              },
              {
                path: ":page/",
                element: (
                  <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                    <MarketCampaignWorkRentMarketPage />
                  </AgentRequiredWrapper>
                ),
              },
            ],
          },
          {
            path: "contacts/",
            children: [
              {
                path: "",
                element: (
                  <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                    <MarketCampaignWorkContactPage />
                  </AgentRequiredWrapper>
                ),
              },
              {
                path: ":page/",
                element: (
                  <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                    <MarketCampaignWorkContactPage />
                  </AgentRequiredWrapper>
                ),
              },
            ],
          },
          {
            path: "submit-property/",
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS}>
                <MarketCampaignWorkSubmitPropertyPage />
              </AgentRequiredWrapper>
            ),
          },
        ],
      },
    ],
  },
];

export default marketCampaignRouter;

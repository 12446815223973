import React from "react";
import { useTranslation } from "react-i18next";
import { Logo } from "src/components/ui/logo";
import Text from "src/components/ui/text";

interface CenterPreloaderProps {
  text?: string;
  className?: string;
  style?: React.CSSProperties;
}

const CenterPreloader: React.FC<CenterPreloaderProps> = (
  {
    text,
    className,
    style,
  },
) => {
  const { t } = useTranslation();

  const loadingText = React.useMemo(() => {
    if (text) {
      return text;
    }

    return t("Loading");
  }, [text]);

  const classNames = React.useMemo(() => {
    const classes = ["d-flex flex-column justify-content-center align-items-center"];
    if (className) {
      classes.push(className);
    }
    return classes.join(" ");
  }, [className]);

  const styles = React.useMemo(() => {
    const defaultStyle = {
      width: "100%",
      paddingTop: "64px",
      paddingBottom: "64px",
    };

    if (!style) {
      return defaultStyle;
    }

    return {
      ...defaultStyle,
      ...style,
    };
  }, [style]);

  return (
    <div
      className={classNames}
      style={styles}
      title={loadingText}
    >
      <Logo animated showBeta={false} />
      <Text color="muted">
        {loadingText}
      </Text>
    </div>
  );
};

export default CenterPreloader;

import React from "react";
import Form from "react-bootstrap/esm/Form";
import { usePropertyTableAdminUpdateRequest } from "src/hooks/api/admin/property";
import { useToastStore } from "src/store/toast";
import { useQueryClient } from "@tanstack/react-query";

interface KeywordCategoryVerifiedCheckboxProps {
  property: AdminAPI.Property.PropertyTableData;
}

const KeywordCategoryVerifiedCheckbox: React.FC<KeywordCategoryVerifiedCheckboxProps> = (
  {
    property,
  },
) => {
  const queryClient = useQueryClient();
  const {
    addToastSuccessWithUndo,
    addToastDanger,
  } = useToastStore();
  const [isChecked, setIsChecked] = React.useState<boolean>(property.is_keyword_category_verified);

  React.useEffect(() => {
    setIsChecked(property.is_keyword_category_verified);
  }, [property.is_keyword_category_verified]);

  const {
    mutate,
    data,
    isSuccess,
    isError,
    error,
  } = usePropertyTableAdminUpdateRequest(property.id);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;
    setIsChecked(checked);
    mutate({
      is_keyword_category_verified: checked,
    });
  };

  React.useEffect(() => {
    if (isSuccess && data?.data) {
      addToastSuccessWithUndo({
        title: `Keyword category verification for ${property.id} updated`,
        body: `Property keyword category verified has been updated to ${data.data.is_keyword_category_verified}`,
      }, () => {
        mutate({
          is_keyword_category_verified: !data.data.is_keyword_category_verified,
        });
      });

      queryClient.setQueriesData(
        ["usePropertyTableAdminRequest"],
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            data: {
              // @ts-ignore
              ...oldData.data,
              // @ts-ignore
              results: oldData.data.results.map((prop) => {
                if (prop.id === property.id) {
                  return {
                    ...prop,
                    is_keyword_category_verified: data.data.is_keyword_category_verified,
                  };
                }
                return prop;
              }),
            },
          };
        },
      );
    }
  }, [isSuccess, data]);

  React.useMemo(() => {
    if (isError && error?.response?.data) {
      addToastDanger({
        title: `Keyword category verification for ${property.id} failed`,
        body: error.response.data.is_keyword_category_verified
          ? error.response.data.is_keyword_category_verified.join(", ")
          : (error.response.data.detail || "Unknown error"),
      });
    }
  }, [isError, error]);

  return (
    <div>
      <Form.Check
        id={`keyword-category-verified-${property.id}`}
        type="checkbox"
        label="Is verified"
        checked={isChecked}
        onChange={handleChange}
      />
    </div>
  );
};

export default KeywordCategoryVerifiedCheckbox;

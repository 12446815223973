import React from "react";
import BSToast from "react-bootstrap/esm/Toast";
import { IToast, useToastStore } from "src/store/toast";
import { useBooleanState } from "src/hooks/boolean";
import Icon from "src/components/ui/icons";
import Button from "src/components/ui/form/button";

interface ToastProps {
  toast: IToast;
  className?: string;
}

const TOAST_DELAY = 15000;

const Toast: React.FC<ToastProps> = (
  {
    toast,
    className,
  },
) => {
  const {
    state: isShow,
    setFalse: hide,
  } = useBooleanState(true);
  const { removeToast } = useToastStore();

  const handleClose = () => {
    hide();
    setTimeout(() => removeToast(toast), 300);
  };

  const handleUndo = () => {
    if (toast.onUndo) {
      toast.onUndo();
      handleClose();
    }
  };

  return (
    <BSToast
      className={className}
      onClose={handleClose}
      show={isShow}
      delay={toast.onUndo ? TOAST_DELAY * 2 : TOAST_DELAY}
      autohide
    >
      <BSToast.Header>
        <Icon
          name="hint"
          size={20}
          style={{
            width: 20,
            height: 20,
            minWidth: 20,
          }}
          variant={toast.variant}
          className="me-2"
        />
        {toast.title}
      </BSToast.Header>
      {(toast.body || toast.onUndo) && (
        <BSToast.Body>
          {toast.body}
          {toast.onUndo && (
            <div className="pt-1 d-flex justify-content-end">
              <Button
                className="px-2 py-1"
                type="button"
                size="sm"
                variant="danger"
                onClick={handleUndo}
              >
                Undo action
              </Button>
            </div>
          )}
        </BSToast.Body>
      )}
    </BSToast>
  );
};

export default Toast;

import React from "react";
import Card from "react-bootstrap/Card";

interface MarketCampaignWorkSummaryCardProps {
  name: string;
  value: string;
}

const MarketCampaignWorkSummaryCard: React.FC<MarketCampaignWorkSummaryCardProps> = (
  {
    name,
    value,
  },
) => (
  <Card className="w-100">
    <Card.Body>
      <Card.Title>{value}</Card.Title>
      <Card.Text>
        {name}
      </Card.Text>
    </Card.Body>
  </Card>
);

export default MarketCampaignWorkSummaryCard;

// eslint-disable-next-line no-shadow
export enum MembershipStatus {
  TRIAL = "trial",
  PENDING = "pending",
  PROCESSING = "processing",
  ACTIVE = "active",
  CANCELLED = "cancelled",
  EXECUTED = "executed",
  EXPIRED = "expired",
}

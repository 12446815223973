/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";
import background from "src/components/course/promo/bg.webp";
import background2x from "src/components/course/promo/bg@2x.webp";
import { color } from "chart.js/helpers";

export type IStyles = IClassBlock<[
    "card" |
    "download" |
    "content" |
    "title" |
    "text"
]>;

const styles: IStyles = {
  card: (theme) => css({
    background: theme.colorWhite,
    border: `1px solid ${theme.colorOutlineLight}`,
    borderRadius: theme.borderRadius,
    padding: theme.spaces.md,
    paddingTop: theme.spaces.sm,
    paddingBottom: theme.spaces.sm,
    display: "flex",
    flexWrap: "nowrap",
  }),
  download: (theme) => css({
    width: 72,
    height: 72,
    flex: "0 0 72px",
    marginRight: theme.spaces.sm,
    borderRadius: theme.borderRadius - 2,
    fontSize: theme.fontSizes.sm,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.colorSecondary,
    color: theme.colorPrimary,
  }),
  content: (theme) => css({
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  }),
  title: (theme) => css({
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeight.semiBold,
    lineHeight: 1,
    color: theme.colorTextPrimary,
    marginBottom: theme.spaces.xxs,
  }),
  text: (theme) => css({
    fontSize: theme.fontSizes.xs,
    color: theme.colorTextSecondary,
    margin: 0,
  }),
};

export default styles;

/** @jsxImportSource @emotion/react */
import React from "react";
import { SerializedStyles } from "@emotion/react";
import { formatPrice } from "src/utils/formatters/number";

interface AsPriceProps {
  value?: string | number | null;
  decimalPlaces?: number;
  showZeros?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const AsPrice: React.FC<AsPriceProps> = (
  {
    value,
    decimalPlaces = 0,
    showZeros = false,
    className,
    ...props
  },
) => {
  const formattedValue: string = React.useMemo(() => (
    formatPrice(value, decimalPlaces, showZeros)
  ), [value, decimalPlaces, showZeros]);
  const classNames = React.useMemo(() => {
    if (!className) {
      return "text-nowrap";
    }
    return `text-nowrap ${className}`;
  }, [className]);

  return (
    <span className={classNames} {...props}>
      {formattedValue}
    </span>
  );
};

export default AsPrice;

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "src/store/account";
import PagePreloader from "src/components/loaders";

interface ILoginRequiredWrapper {
  children: JSX.Element;
}

const AdminRequiredWrapper: React.FC<ILoginRequiredWrapper> = ({ children }) => {
  const { isSuperuser } = useAuthStore();

  if (!isSuperuser) {
    return (
      <Navigate to="/page-not-found" />
    );
  }

  return (
    <React.Suspense fallback={(<PagePreloader />)}>
      {children}
    </React.Suspense>
  );
};

export default AdminRequiredWrapper;

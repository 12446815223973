import React from "react";
import { useSearchParams } from "react-router-dom";

export const useStoreReferralFromQuery = (): void => {
  const [searchParams] = useSearchParams();
  React.useEffect(() => {
    if (searchParams.has("referral") && searchParams.get("referral")) {
      localStorage.setItem("referral", searchParams.get("referral")!);
    }
  }, []);
};

/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "button" | "name" | "email" | "dropdownItem" | "dropdownItemIcon"
]>;

const styles: IStyles = {
  button: (theme) => css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: theme.spaces.md,
  }),
  name: (theme) => css({
    fontWeight: theme.fontWeight.semiBold,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.lineHeight.sm,
  }),
  email: (theme) => css({
    fontSize: 11,
    lineHeight: theme.lineHeight.sm,
    color: theme.colorTextSecondary,
  }),
  dropdownItem: (theme) => css({
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spaces.xs,
    paddingBottom: theme.spaces.xs,
    fontSize: theme.fontSizes.sm,
  }),
  dropdownItemIcon: (theme) => css({
    marginRight: theme.spaces.xs,
    color: theme.colorTextSecondary,
  }),
};

export default styles;

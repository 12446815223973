import type { IConfig } from "./config.d";

const config: IConfig = {
  ENVIRONMENT: process.env.NODE_ENV,
  IS_PRODUCTION: process.env.NODE_ENV === "production",
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL!,
  API_URL: process.env.REACT_APP_API_URL!,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY!,
  SITE_TITLE: process.env.REACT_APP_TITLE!,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  TRIAL_PACKAGE_ID: parseInt(process.env.REACT_APP_TRIAL_PACKAGE_ID!, 10),
  COURSE_PACKAGE_ID: parseInt(process.env.REACT_APP_COURSE_ACCESS_PACKAGE_ID!, 10),
  GOOGLE_MAP_ID: process.env.REACT_APP_GOOGLE_MAP_ID!,
};

export default config;

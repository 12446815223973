import React from "react";
import { useSearchParams } from "react-router-dom";

export const useSearchParamsString = () => {
  const [params] = useSearchParams();

  return React.useMemo(() => {
    const queryString = Array.from(params.entries()).map(([key, value]) => `${key}=${value}`).join("&");
    return queryString ? `?${queryString}` : "";
  }, [params]);
};

export const useQueryFilter = <T extends Object>(initialFilter: Partial<T> | (() => Partial<T>)) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReset, setIsReset] = React.useState(false);
  const [filter, setFilter] = React.useState<Partial<T>>(() => {
    if (typeof initialFilter === "function") {
      // eslint-disable-next-line no-param-reassign
      initialFilter = initialFilter();
    }
    if (searchParams.has("filter")) {
      try {
        const queryFilter = JSON.parse(searchParams.get("filter") || "{}");
        return {
          ...queryFilter,
          ...initialFilter,
        };
      } catch (error) {
        return {};
      }
    }
    return initialFilter;
  });

  const resetFilter = () => {
    setIsReset(true);
    setTimeout(() => {
      setFilter({});
      setSearchParams((currentParams) => {
        currentParams.delete("filter");
        return currentParams;
      });
    });
  };

  const updateFilter = (data: Partial<T>) => {
    let newFilter: Partial<T> = {
      ...filter,
      ...data,
    };

    newFilter = Object.keys(newFilter).reduce<Partial<T>>((acc, key) => {
      // @ts-ignore
      if (!newFilter[key]
        // @ts-ignore
        || (Array.isArray(newFilter[key]) && newFilter[key].length === 0)
        // @ts-ignore
        || (typeof newFilter[key] === "object" && Object.keys(newFilter[key]).length === 0)
      ) {
        return acc;
      }

      return {
        ...acc,
        // @ts-ignore
        [key]: newFilter[key],
      };
    }, {} as Partial<T>);

    setSearchParams((currentParams) => {
      if (Object.keys(newFilter).length === 0) {
        currentParams.delete("filter");
        return currentParams;
      }
      currentParams.set("filter", JSON.stringify(newFilter));
      return currentParams;
    });
    setFilter(newFilter);
  };

  const removeFromFilter = (key: string | string[]) => {
    if (typeof key === "string") {
      // @ts-ignore
      updateFilter({ [key]: undefined });
      return;
    }
    // @ts-ignore
    updateFilter(key.reduce((acc, k) => ({ ...acc, [k]: undefined }), {}));
  };

  return {
    filter,
    updateFilter,
    resetFilter,
    removeFromFilter,
    isReset,
    isApplied: Object.keys(filter).length > 0,
  };
};

export const useQueryOrdering = <T extends string>(initialOrdering: T) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [_ordering, setOrdering] = React.useState<T>(() => {
    if (searchParams.has("ordering")) {
      return searchParams.get("ordering") as T;
    }

    return initialOrdering;
  });

  const updateOrdering = (ordering: T) => {
    setOrdering(ordering);
    setSearchParams((currentParams) => {
      currentParams.set("ordering", ordering);
      return currentParams;
    });
  };

  return {
    ordering: _ordering,
    setOrdering: updateOrdering,
  };
};

import React from "react";
import { useTranslation } from "react-i18next";
import {
  useAgentMarketCampaignAddPropertyRequest,
  useAgentMarketCampaignListRequest,
} from "src/hooks/api/agent/market-campaign";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import FormSelect from "react-bootstrap/esm/FormSelect";
import Button from "src/components/ui/form/button";
import { useToastStore } from "src/store/toast";
import { useRouterParamId } from "src/hooks/router";
import { useLocation } from "react-router-dom";

interface MarketCampaignCellProps {
  property: AdminAPI.Property.PropertyTableData;
}

const MarketCampaignCell: React.FC<MarketCampaignCellProps> = (
  {
    property,
  },
) => {
  const marketCampaignId = useRouterParamId("id");
  const location = useLocation();

  const {
    addToastSuccess,
  } = useToastStore();
  const hasPermission = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS);
  const [selectedMarketCampaign, setSelectedMarketCampaign] = React.useState<string>(() => {
    if (location.pathname.startsWith("/agent/market-campaigns/detail") && marketCampaignId !== -1) {
      return marketCampaignId.toString();
    }
    return "";
  });
  const {
    data,
  } = useAgentMarketCampaignListRequest();

  const {
    mutate,
    isLoading,
    isSuccess,
    data: mutateData,
  } = useAgentMarketCampaignAddPropertyRequest();

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setSelectedMarketCampaign(event.target.value);
  };

  const handleAddToCampaign = () => {
    if (!selectedMarketCampaign) {
      return;
    }

    mutate({
      id: selectedMarketCampaign,
      property_id: property.id,
    });
  };

  React.useEffect(() => {
    if (isSuccess && mutateData?.data) {
      addToastSuccess({
        title: mutateData.data.detail,
      });
      setSelectedMarketCampaign("");
    }
  }, [isSuccess, mutateData]);

  if (!hasPermission) {
    return (
      <span>No permission</span>
    );
  }

  if (!data?.data || data.data.count === 0) {
    return (
      <span>No assigned market campaigns</span>
    );
  }

  return (
    <div className="d-flex flex-nowrap">
      <FormSelect
        size="sm"
        value={selectedMarketCampaign}
        onChange={handleSelectChange}
      >
        <option value="">
          Select market campaign
        </option>
        {data?.data?.results.map((marketCampaign) => (
          <option key={marketCampaign.id} value={marketCampaign.id}>
            {`${marketCampaign.market.name}, ${marketCampaign.market_state.code} for ${marketCampaign.requested_by.name}`}
          </option>
        ))}
      </FormSelect>
      <Button
        size="sm"
        variant="secondary"
        className="ms-2"
        onClick={handleAddToCampaign}
        disabled={!selectedMarketCampaign}
        loading={isLoading}
      >
        Add to campaign
      </Button>
    </div>
  );
};

export default MarketCampaignCell;

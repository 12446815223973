import React from "react";
import { Outlet } from "react-router-dom";
import { ChipMenu } from "src/components/ui/menu";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";

const AgentWrapper: React.FC = () => {
  const hasPropertyTablePerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_PROPERTY_TABLE);
  const hasStrDatabaseVerificationPerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION);
  const hasAgentStatisticPerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_STATISTICS);
  const items = React.useMemo(() => {
    const navItems = [
      { to: "/agent/common/", title: "Dashboard" },
      { to: "/agent/market-campaigns/", title: "Market campaigns" },
    ];

    if (hasPropertyTablePerm) {
      navItems.push({ to: "/agent/property-table/", title: "Property table" });
      navItems.push({ to: "/agent/keyword-verification-table/", title: "Keyword validation" });
    }

    if (hasStrDatabaseVerificationPerm) {
      navItems.push({ to: "/agent/str/", title: "STR Regulations" });
    }

    if (hasAgentStatisticPerm) {
      navItems.push({ to: "/agent/agents/", title: "Agent stats" });
    }

    return navItems;
  }, [hasPropertyTablePerm, hasStrDatabaseVerificationPerm, hasAgentStatisticPerm]);
  return (
    <section>
      <ChipMenu
        level={2}
        style={{ marginBottom: 24 }}
        items={items}
      />

      <Outlet />
    </section>
  );
};

export default AgentWrapper;

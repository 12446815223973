import { useMutation, UseMutationResult, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  getPropertyList,
  getVerifiedPropertyList,
  getPropertyDetail,
  getPropertyDetailByCode,
  togglePropertyFavorite,
  togglePropertyDeal,
  getPropertyMapList,
  getPropertyShareCode,
  getPublicPropertyList,
  getLandlordProperty,
  getPublicPropertyDetail,
  createLandlordConnect,
  underwritePropertyCustomAssumptions,
  getPropertyDealCustomAssumptions,
  getPropertyCustomCompSet,
  getVerifiedPropertyMapList,
  createPropertyLandlord,
  postPropertyPhotoLandlord,
  findPropertyForVerification,
  updatePropertyLandlord,
  createPropertyCSVExport,
  createVerifiedPropertyCSVExport,
  deleteLandlordProperty,
  getArbitragePropertyList,
  getCoHostPropertyList,
  getArbitragePropertyMapList,
  getCoHostPropertyMapList,
  getApartmentPropertyList,
  getApartmentPropertyMapList,
} from "src/api/property";
import { UseQueryResult } from "@tanstack/react-query/src/types";

type IUseListPropertyRequest = (data: API.Property.List.Request) => UseQueryResult<
  AxiosResponse<API.Property.List.Response>,
  AxiosError<API.Property.List.ErrorResponse>
>;

export const useListPropertyRequest: IUseListPropertyRequest = (data) => (
  useQuery(
    ["useListPropertyRequest", data],
    async () => await getPropertyList(data),
  )
);

export const useVerifiedListPropertyRequest: IUseListPropertyRequest = (data) => (
  useQuery(
    ["useVerifiedListPropertyRequest", data],
    async () => await getVerifiedPropertyList(data),
  )
);

export const useApartmentListPropertyRequest: IUseListPropertyRequest = (data) => (
  useQuery(
    ["useApartmentListPropertyRequest", data],
    async () => await getApartmentPropertyList(data),
  )
);

export const useArbitrageListPropertyRequest: IUseListPropertyRequest = (data) => (
  useQuery(
    ["useArbitrageListPropertyRequest", data],
    async () => await getArbitragePropertyList(data),
  )
);

export const useCoHostListPropertyRequest: IUseListPropertyRequest = (data) => (
  useQuery(
    ["useCoHostListPropertyRequest", data],
    async () => await getCoHostPropertyList(data),
  )
);

type IUseListPublicPropertyRequest = (
  data: API.Property.List.Request,
) => UseQueryResult<
  AxiosResponse<API.Property.List.Response>,
  AxiosError<API.Property.List.ErrorResponse>
>;

export const useListPublicPropertyRequest: IUseListPublicPropertyRequest = (data) => (
  useQuery(
    ["useListPublicPropertyRequest", data],
    async () => await getPublicPropertyList(data),
    {
      keepPreviousData: true,
    },
  )
);

type IUseMapListPropertyRequest = (data: API.Property.Map.Request) => UseQueryResult<
  AxiosResponse<API.Property.Map.Response>,
  AxiosError<API.Property.Map.ErrorResponse>
>;

export const useMapListPropertyRequest: IUseMapListPropertyRequest = (data) => (
  useQuery(
    ["useMapListPropertyRequest", data],
    async () => await getPropertyMapList(data),
    {
      keepPreviousData: true,
    },
  )
);

export const useApartmentMapListPropertyRequest: IUseMapListPropertyRequest = (data) => (
  useQuery(
    ["useApartmentMapListPropertyRequest", data],
    async () => await getApartmentPropertyMapList(data),
    {
      keepPreviousData: true,
    },
  )
);

export const useVerifiedMapListPropertyRequest: IUseMapListPropertyRequest = (data) => (
  useQuery(
    ["useVerifiedMapListPropertyRequest", data],
    async () => await getVerifiedPropertyMapList(data),
    {
      keepPreviousData: true,
    },
  )
);

export const useArbitrageMapListPropertyRequest: IUseMapListPropertyRequest = (data) => (
  useQuery(
    ["useArbitrageMapListPropertyRequest", data],
    async () => await getArbitragePropertyMapList(data),
    {
      keepPreviousData: true,
    },
  )
);

export const useCoHostMapListPropertyRequest: IUseMapListPropertyRequest = (data) => (
  useQuery(
    ["useCoHostMapListPropertyRequest", data],
    async () => await getCoHostPropertyMapList(data),
    {
      keepPreviousData: true,
    },
  )
);

type IUseDetailPropertyRequest = (
  id: API.Property.PropertyListData["id"],
) => UseQueryResult<
  AxiosResponse<API.Property.Detail.Response>,
  AxiosError<API.Property.Detail.ErrorResponse>
>;

export const useDetailPropertyRequest: IUseDetailPropertyRequest = (id) => (
  useQuery(
    ["useDetailPropertyRequest", id],
    async () => await getPropertyDetail(id),
    {
      keepPreviousData: true,
    },
  )
);

type IUseDetailPublicPropertyRequest = (
  id: API.Property.PropertyListData["id"],
) => UseQueryResult<
  AxiosResponse<API.Property.Detail.Response>,
  AxiosError<API.Property.Detail.ErrorResponse>
>;

export const useDetailPublicPropertyRequest: IUseDetailPublicPropertyRequest = (id) => (
  useQuery(
    ["useDetailPublicPropertyRequest", id],
    async () => await getPublicPropertyDetail(id),
    {
      keepPreviousData: true,
    },
  )
);

type IUseDetailPropertyByCodeRequest = (
  code: string,
) => UseQueryResult<
  AxiosResponse<API.Property.Detail.Response>,
  AxiosError<API.Property.Detail.ErrorResponse>
>;

export const useDetailPropertyByCodeRequest: IUseDetailPropertyByCodeRequest = (code) => (
  useQuery(
    ["useDetailPropertyByCodeRequest", code],
    async () => await getPropertyDetailByCode(code),
  )
);

type IUseGetPropertyShareCodeRequest = (
  id: API.Property.PropertyListData["id"],
) => UseQueryResult<
  AxiosResponse<API.Property.ShareCode.Response>,
  AxiosError<API.Property.ShareCode.ErrorResponse>
>;

export const useGetPropertyShareCodeRequest: IUseGetPropertyShareCodeRequest = (id) => (
  useQuery(
    ["useGetPropertyShareRequest", id],
    async () => await getPropertyShareCode(id),
    {
      enabled: false,
    },
  )
);

type IUseGetPropertyDealCustomAssumptionsRequest = (
  id: API.Property.PropertyListData["id"],
) => UseQueryResult<
  AxiosResponse<API.Property.CustomAssumptions.Response>,
  AxiosError<API.Property.CustomAssumptions.ErrorResponse>
>;

export const useGetPropertyDealCustomAssumptionsRequest: IUseGetPropertyDealCustomAssumptionsRequest = (id) => (
  useQuery(
    ["useGetPropertyDealCustomAssumptionsRequest", id],
    async () => await getPropertyDealCustomAssumptions(id),
    {
      enabled: false,
    },
  )
);

type IUseTogglePropertyFavoriteRequest = (
  id: API.Property.PropertyListData["id"],
) => UseMutationResult<
  AxiosResponse<API.Property.ToggleFavorite.Response>,
  AxiosError<API.Property.ToggleFavorite.ErrorResponse>,
  API.Property.PropertyListData["id"]
>;

export const useTogglePropertyFavoriteRequest: IUseTogglePropertyFavoriteRequest = () => (
  useMutation({
    mutationFn: async (id) => await togglePropertyFavorite(id),
  })
);

type IUseTogglePropertyDealRequest = () => UseMutationResult<
  AxiosResponse<API.Property.ToggleDeal.Response>,
  AxiosError<API.Property.ToggleDeal.ErrorResponse>,
  API.Property.PropertyListData["id"]
>;

export const useTogglePropertyDealRequest: IUseTogglePropertyDealRequest = () => (
  useMutation({
    mutationFn: async (id) => await togglePropertyDeal(id),
  })
);

type IUseCreateLandlordConnectRequest = () => UseMutationResult<
  AxiosResponse<API.Property.ConnectAgent.Response>,
  AxiosError<API.Property.ConnectAgent.ErrorResponse>,
  API.Property.PropertyListData["id"]
>;

export const useCreateLandlordConnectRequest: IUseCreateLandlordConnectRequest = () => (
  useMutation({
    mutationFn: async (id) => await createLandlordConnect(id),
  })
);

type IUseUnderwritePropertyCustomAssumptionsRequest = (
  id: API.Property.PropertyListData["id"],
) => UseMutationResult<
  AxiosResponse<API.Property.CustomAssumptions.Response>,
  AxiosError<API.Property.CustomAssumptions.ErrorResponse>,
  API.Property.CustomAssumptions.Request
>;

export const useUnderwritePropertyCustomAssumptionsRequest: IUseUnderwritePropertyCustomAssumptionsRequest = (id) => (
  useMutation({
    mutationFn: async (data) => await underwritePropertyCustomAssumptions(id, data),
  })
);

type IUseCreatePropertyLandlordRequest = () => UseMutationResult<
  AxiosResponse<API.Landlord.SubmitProperty.Response>,
  AxiosError<API.Landlord.SubmitProperty.ErrorResponse>,
  API.Landlord.SubmitProperty.Request
>;

export const useCreatePropertyLandlordRequest: IUseCreatePropertyLandlordRequest = () => (
  useMutation({
    mutationFn: async (data) => await createPropertyLandlord(data),
  })
);

type IUsePropertyPhotoUploadLandlordRequest = () => UseMutationResult<
  AxiosResponse<API.Landlord.SubmitPropertyPhoto.Response> |
  AxiosResponse<API.Landlord.SubmitPropertyPhoto.Response>[],
  AxiosError<API.Landlord.SubmitPropertyPhoto.ErrorResponse> |
  AxiosError<API.Landlord.SubmitPropertyPhoto.ErrorResponse>[],
  {
    formData: API.Landlord.SubmitPropertyPhoto.Request | API.Landlord.SubmitPropertyPhoto.Request[];
    id: number | string;
  }
>;

export const usePropertyPhotoUploadLandlordRequest: IUsePropertyPhotoUploadLandlordRequest = () => (
  useMutation({
    mutationFn: async ({ id, formData }) => {
      if (Array.isArray(formData)) {
        return await Promise.all(
          formData.map(async (data) => (
            await postPropertyPhotoLandlord(id, data)
          )),
        );
      }
      return (
        await postPropertyPhotoLandlord(id, formData)
      );
    },
  })
);

type IUseUpdatePropertyLandlordRequest = (
  id: API.Landlord.LandlordPropertyData["id"],
) => UseMutationResult<
  AxiosResponse<API.Landlord.UpdateProperty.Response>,
  AxiosError<API.Landlord.UpdateProperty.ErrorResponse>,
  API.Landlord.UpdateProperty.Request
>;

export const useUpdatePropertyLandlordRequest: IUseUpdatePropertyLandlordRequest = (id) => (
  useMutation({
    mutationFn: async (data) => await updatePropertyLandlord(id, data),
  })
);

type IUseFindPropertyForVerificationRequest = () => UseMutationResult<
  AxiosResponse<API.Landlord.SearchProperty.Response>,
  AxiosError<API.Landlord.SearchProperty.ErrorResponse>,
  API.Landlord.SearchProperty.Request
>;

export const useFindPropertyForVerificationRequest: IUseFindPropertyForVerificationRequest = () => (
  useMutation({
    mutationFn: async (data) => await findPropertyForVerification(data),
  })
);

type IUseGetPropertyCustomCompSetRequest = (
  id: API.Property.PropertyListData["id"],
) => UseQueryResult<
  AxiosResponse<API.Property.CompSet.Response>,
  AxiosError<API.Property.CompSet.ErrorResponse>
>;

export const useGetPropertyCustomCompSetRequest: IUseGetPropertyCustomCompSetRequest = (id) => (
  useQuery(
    ["useGetPropertyCustomCompSetRequest", id],
    async () => await getPropertyCustomCompSet(id),
    {
      enabled: false,
    },
  )
);

type IUsePropertyPublicWithOwnerRequest = () => UseMutationResult<
  AxiosResponse<API.Property.LandlordProperty.Response>,
  AxiosError<API.Property.LandlordProperty.ErrorResponse>,
  API.Property.LandlordProperty.Request & {
  id: API.Property.PropertyListData["id"],
  is_delete?: boolean,
}
>;

export const usePropertyPublicWithOwnerRequest: IUsePropertyPublicWithOwnerRequest = () => (
  useMutation({
    mutationFn: async ({ id, is_delete: isDelete, ...params }) => {
      if (isDelete) {
        return await deleteLandlordProperty(id, params);
      }
      return await getLandlordProperty(id, params);
    },
  })
);

type IUseListPropertyCSVExportRequest = () => UseMutationResult<
  AxiosResponse<API.Property.ListExport.Response>,
  AxiosError<API.Property.ListExport.ErrorResponse>,
  API.Property.ListExport.Request & { verified?: boolean }
>;

export const useListPropertyCSVExportRequest: IUseListPropertyCSVExportRequest = () => (
  useMutation({
    mutationFn: async ({ verified, ...data }) => {
      if (verified) {
        return await createVerifiedPropertyCSVExport(data);
      }
      return await createPropertyCSVExport(data);
    },
  })
);

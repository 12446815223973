import { UseMutationResult, UseQueryResult } from "@tanstack/react-query/src/types";
import { AxiosError, AxiosResponse } from "axios/index";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  collectPropertiesFromQueue,
  createParserTask,
  createParserTaskExecution,
  createSingleParserExecution,
  deleteParserTask,
  listParserExecution,
  listParserTask,
  parserExecutionSendToQueue,
  parserExecutionSignedLogUrl,
  updateParserTask,
  updateParserExecution,
  getMarketRequestList,
  marketRequestUpdate,
  getExecutionKeywordList,
} from "src/api/admin/scrapers";

type IUseParserTaskListRequest = (params?: AdminAPI.Scrapers.ParserTaskList.Request) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ParserTaskList.Response>,
  AxiosError<AdminAPI.Scrapers.ParserTaskList.ErrorResponse>
>;

export const useParserTaskListRequest: IUseParserTaskListRequest = (params) => (
  useQuery(
    ["useParserTaskListRequest", params],
    async () => await listParserTask(params),
  )
);

type IUseParserTaskCreateRequest = (data: AdminAPI.Scrapers.ParserTaskCreateUpdate.Request) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ParserTaskCreateUpdate.Response>,
  AxiosError<AdminAPI.Scrapers.ParserTaskCreateUpdate.ErrorResponse>
>;

export const useParserTaskCreateRequest: IUseParserTaskCreateRequest = (data) => (
  useQuery(
    ["useParserTaskCreateRequest", data],
    async () => await createParserTask(data),
    {
      enabled: false,
    },
  )
);

type IUseParserTaskUpdateRequest = (
  id: AdminAPI.Scrapers.ParserTask["id"],
  data: AdminAPI.Scrapers.ParserTaskCreateUpdate.Request,
) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ParserTaskCreateUpdate.Response>,
  AxiosError<AdminAPI.Scrapers.ParserTaskCreateUpdate.ErrorResponse>
>;

export const useParserTaskUpdateRequest: IUseParserTaskUpdateRequest = (id, data) => (
  useQuery(
    ["useParserTaskUpdateRequest", id, data],
    async () => await updateParserTask(id, data),
    {
      enabled: false,
    },
  )
);

type IUseParserTaskDeleteRequest = (id: AdminAPI.Scrapers.ParserTask["id"]) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ParserTaskCreateUpdate.Response>,
  AxiosError<AdminAPI.Scrapers.ParserTaskCreateUpdate.ErrorResponse>
>;

export const useParserTaskDeleteRequest: IUseParserTaskDeleteRequest = (id) => (
  useQuery(
    ["useParserTaskDeleteRequest", id],
    async () => await deleteParserTask(id),
    {
      enabled: false,
    },
  )
);

type IUseParserTaskExecutionCreateRequest = (
  id: AdminAPI.Scrapers.ParserTask["id"],
) => UseMutationResult<
  AxiosResponse<AdminAPI.Scrapers.ParserTaskCreateExecution.Response>,
  AxiosError<AdminAPI.Scrapers.ParserTaskCreateExecution.ErrorResponse>,
  AdminAPI.Scrapers.ParserTaskCreateExecution.Request
>;

export const useParserTaskExecutionCreateRequest: IUseParserTaskExecutionCreateRequest = (id) => (
  useMutation({
    mutationFn: async (data) => await createParserTaskExecution(id, data),
  })
);

type IUseParserExecutionListRequest = (params?: AdminAPI.Scrapers.ParserExecutionList.Request) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ParserExecutionList.Response>,
  AxiosError<AdminAPI.Scrapers.ParserExecutionList.ErrorResponse>
>;

export const useParserExecutionListRequest: IUseParserExecutionListRequest = (params) => (
  useQuery(
    ["useParserExecutionListRequest", params],
    async () => await listParserExecution(params),
  )
);

type IUseParserExecutionSendToQueueRequest = (id: AdminAPI.Scrapers.ParserExecution["id"]) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ParserExecutionList.Response>,
  AxiosError<AdminAPI.Scrapers.ParserExecutionList.ErrorResponse>
>;

export const useParserExecutionSendToQueueRequest: IUseParserExecutionSendToQueueRequest = (id) => (
  useQuery(
    ["useParserExecutionSendToQueueRequest", id],
    async () => await parserExecutionSendToQueue(id),
    {
      enabled: false,
    },
  )
);

type IUseParserExecutionSignedLogUrlRequest = (id: AdminAPI.Scrapers.ParserExecution["id"]) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ParserExecutionSignedLogUrl.Response>,
  AxiosError<AdminAPI.Scrapers.ParserExecutionSignedLogUrl.ErrorResponse>
>;

export const useParserExecutionSignedLogUrlRequest: IUseParserExecutionSignedLogUrlRequest = (id) => (
  useQuery(
    ["useParserExecutionSignedLogUrlRequest", id],
    async () => await parserExecutionSignedLogUrl(id),
    {
      enabled: false,
    },
  )
);

type IUseCollectPropertiesFromQueueRequest = () => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.CollectPropertiesFromQueue.Response>,
  AxiosError<AdminAPI.Scrapers.CollectPropertiesFromQueue.ErrorResponse>
>;

export const useCollectPropertiesFromQueueRequest: IUseCollectPropertiesFromQueueRequest = () => (
  useQuery(
    ["useCollectPropertiesFromQueueRequest"],
    collectPropertiesFromQueue,
    {
      enabled: false,
    },
  )
);

type IUseMarketRequestListRequest = (data: AdminAPI.Scrapers.MarketRequest.Request) => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.MarketRequest.Response>,
  AxiosError<AdminAPI.Scrapers.MarketRequest.ErrorResponse>
>;

export const useMarketRequestListRequest: IUseMarketRequestListRequest = (data) => (
  useQuery(
    ["useMarketRequestListRequest", data],
    async () => await getMarketRequestList(data),
  )
);

type IUseExecutionKeywordListRequest = () => UseQueryResult<
  AxiosResponse<AdminAPI.Scrapers.ExecutionKeywordList.Response>,
  AxiosError<AdminAPI.Scrapers.ExecutionKeywordList.ErrorResponse>
>;

export const useExecutionKeywordListRequest: IUseExecutionKeywordListRequest = () => (
  useQuery(
    ["useExecutionKeywordListRequest"],
    getExecutionKeywordList,
  )
);

type IUseMarketRequestUpdateRequest = (
  id: AdminAPI.Scrapers.MarketRequestData["id"],
) => UseMutationResult<
  AxiosResponse<AdminAPI.Scrapers.MarketRequestUpdate.Response>,
  AxiosError<AdminAPI.Scrapers.MarketRequestUpdate.ErrorResponse>,
  Partial<AdminAPI.Scrapers.MarketRequestUpdate.Request>
>;

export const useMarketRequestUpdateRequest: IUseMarketRequestUpdateRequest = (id) => (
  useMutation({
    mutationFn: async (data) => await marketRequestUpdate(id, data),
  })
);

type IUseCreateSingleParserExecutionRequest = () => UseMutationResult<
  AxiosResponse<AdminAPI.Scrapers.CreateParserExecution.Response>,
  AxiosError<AdminAPI.Scrapers.CreateParserExecution.ErrorResponse>,
  AdminAPI.Scrapers.CreateParserExecution.Request
>;

export const useCreateSingleParserExecutionRequest: IUseCreateSingleParserExecutionRequest = () => (
  useMutation({
    mutationFn: async (data) => await createSingleParserExecution(data),
  })
);

type IUseUpdateParserExecutionRequest = (
  id: AdminAPI.Scrapers.ParserExecution["id"],
) => UseMutationResult<
  AxiosResponse<AdminAPI.Scrapers.UpdateParserExecution.Response>,
  AxiosError<AdminAPI.Scrapers.UpdateParserExecution.ErrorResponse>,
  AdminAPI.Scrapers.UpdateParserExecution.Request
>;

export const useUpdateParserExecutionRequest: IUseUpdateParserExecutionRequest = (id) => (
  useMutation({
    mutationFn: async (data) => await updateParserExecution(id, data),
  })
);

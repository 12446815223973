import create from "zustand";

export interface ITutorialStore {
  isAllDataReady: boolean;
  setIsAllDataReady: (isAllDataReady: boolean) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  isComponentsReady: (selectors: string[]) => boolean;
  incrementStep: () => void;
  decrementStep: () => void;
  readyComponents: string[];
  setReadyComponents: (selectors: string[]) => void;
  currentStepComponents: string[];
  setCurrentStepComponents: (selectors: string[]) => void;
}

export const useTutorialStore = create<ITutorialStore>()(
  (set, get) => ({
    currentStep: 0,
    isAllDataReady: false,
    setIsAllDataReady: (isAllDataReady) => set({ isAllDataReady }),
    setCurrentStep: (currentStep) => set({ currentStep }),
    readyComponents: [],
    currentStepComponents: [],
    isComponentsReady: (selectors) => {
      const { readyComponents } = get();
      return selectors.every((selector) => readyComponents.includes(selector));
    },
    incrementStep: () => set((state) => ({
      currentStep: state.currentStep + 1,
    })),
    decrementStep: () => set((state) => ({
      currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0,
    })),
    setReadyComponents: (selectors) => set((state) => ({
      readyComponents: [...state.readyComponents, ...selectors],
    })),
    setCurrentStepComponents: (selectors) => set({
      currentStepComponents: selectors,
    }),
  }),
);

import React from "react";
import { useTranslation } from "react-i18next";
import { useRouterParamId } from "src/hooks/router";
import {
  useAgentMarketCampaignDetailRequest,
  useAgentMarketCampaignPropertyListRequest,
} from "src/hooks/api/agent/market-campaign";
import { useLimitOffsetPaginationParams } from "src/hooks/pagination";
import LimitOffsetPagination from "src/components/ui/pagination/LimitOffsetPagination";
import MarketCampaignPropertyTable from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyTable";
import { useQueryFilter } from "src/hooks/search-params";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import { FormGroup } from "src/components/ui/form/group";
import FormLabel from "src/components/ui/form/form-label";
import AddressSearch from "src/components/address-search";
import { FormCheck } from "react-bootstrap";
import Button from "src/components/ui/form/button";

const MarketCampaignWorkPropertyListPage: React.FC = () => {
  const id = useRouterParamId("id");
  const { t } = useTranslation();
  const paginationParams = useLimitOffsetPaginationParams(50);
  const {
    data: marketCampaignData,
  } = useAgentMarketCampaignDetailRequest(id);

  const {
    filter,
    updateFilter,
    removeFromFilter,
    resetFilter,
  } = useQueryFilter<AdminAPI.Property.PropertyTableFilter>({});

  const requestParams = React.useMemo(() => ({
    ...paginationParams,
    filter: JSON.stringify(filter),
  }), [paginationParams, filter]);

  const {
    data,
  } = useAgentMarketCampaignPropertyListRequest(id, requestParams);

  const handleLocationChange = (value: google.maps.LatLngLiteral | undefined) => {
    if (!value) {
      removeFromFilter("point");
      return;
    }

    updateFilter({
      ...filter,
      point: value,
    });
  };

  const handleBooleanChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, checked } = event.target;
    if (!checked) {
      removeFromFilter(name);
    }

    updateFilter({
      ...filter,
      [name]: checked,
    });
  };

  return (
    <div className="overflow-auto">
      <h4 className="mb-3">
        {t("Properties submitted")}
      </h4>

      <Row>
        <Col xs={24} md={16} lg={12} className="mb-3">
          <FormGroup className="mb-0">
            <FormLabel>
              Search by address
            </FormLabel>
            <AddressSearch
              initialValue={filter.point}
              onChange={handleLocationChange}
            />
          </FormGroup>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} className="mb-3 d-flex flex-column">
          <FormCheck
            className="mt-auto"
            id="is_added_to_market_campaign_by_user"
            name="is_added_to_market_campaign_by_user"
            label={t("Added to campaign by user")}
            checked={filter.is_added_to_market_campaign_by_user}
            onChange={handleBooleanChange}
          />
          <FormCheck
            id="is_submitted_by_me"
            name="is_submitted_by_me"
            label={t("Added to campaign by me")}
            checked={filter.is_submitted_by_me}
            onChange={handleBooleanChange}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} className="mb-3 d-flex flex-column">
          <Button
            className="w-100 mt-auto"
            variant="outline-dark"
            onClick={resetFilter}
          >
            Reset
          </Button>
        </Col>
      </Row>

      {marketCampaignData?.data && (
        <MarketCampaignPropertyTable
          properties={data?.data.results || []}
          marketCampaign={marketCampaignData!.data}
        />
      )}

      <div className="d-flex justify-content-center pt-4">
        <LimitOffsetPagination
          pageSize={paginationParams.limit}
          total={data?.data.count || 0}
          baseUrl={`/agent/market-campaigns/detail/${id}/properties/`}
        />
      </div>
    </div>
  );
};

export default MarketCampaignWorkPropertyListPage;

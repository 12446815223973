import React from "react";
import { useGoogleMapLoader } from "src/hooks/map";
import AddressSearch, { AddressSearchProps } from "./AddressSearch";

const AddressSearchWithMap: React.FC<AddressSearchProps> = (props) => {
  const { isLoaded } = useGoogleMapLoader();

  if (!isLoaded) {
    return null;
  }

  return (
    <AddressSearch {...props} />
  );
};

export default AddressSearchWithMap;

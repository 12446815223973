import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  getAreaBoundary,
  getAreaByParent,
  getAreaList,
  getAreaWithPropertyList,
  getAreaWithScrapedList,
  getStateList,
  getStateWithBoundaryList,
  getCityWithBoundaryList,
  listCityWithSTRByState,
} from "src/api/area";

type IUseStateListRequest = () => UseQueryResult<
  AxiosResponse<API.Area.List.Response>,
  AxiosError<API.Area.List.ErrorResponse>
>;

export const useStateListRequest: IUseStateListRequest = () => (
  useQuery(
    ["useStateListRequest"],
    getStateList,
  )
);

type IUseStateWithBoundaryListRequest = () => UseQueryResult<
  AxiosResponse<API.Area.ListWithBoundary.Response>,
  AxiosError<API.Area.ListWithBoundary.ErrorResponse>
>;

export const useStateWithBoundaryListRequest: IUseStateWithBoundaryListRequest = () => (
  useQuery(
    ["useStateWithBoundaryListRequest"],
    getStateWithBoundaryList,
  )
);

type IUseCityWithBoundaryListRequest = (params?: API.Area.ListWithBoundary.Request, enabled?: boolean) => UseQueryResult<
  AxiosResponse<API.Area.ListWithBoundary.Response>,
  AxiosError<API.Area.ListWithBoundary.ErrorResponse>
>;

export const useCityWithBoundaryListRequest: IUseCityWithBoundaryListRequest = (params, enabled) => (
  useQuery(
    ["useCityWithBoundaryListRequest", params],
    async () => await getCityWithBoundaryList(params),
    {
      enabled,
      keepPreviousData: true,
    },
  )
);

type IUseAreaChildListRequest = (parentId: API.Area.AreaData["id"]) => UseQueryResult<
  AxiosResponse<API.Area.List.Response>,
  AxiosError<API.Area.List.ErrorResponse>
>;

export const useAreaChildListRequest: IUseAreaChildListRequest = (parentId) => (
  useQuery(
    ["useAreaRequest", parentId],
    async () => await getAreaByParent(parentId),
    {
      enabled: false,
    },
  )
);

type IUseAreaWithPropertiesListRequest = (
  params: API.Area.List.Request,
) => UseQueryResult<
  AxiosResponse<API.Area.List.Response>,
  AxiosError<API.Area.List.ErrorResponse>
>;

export const useAreaWithPropertiesListRequest: IUseAreaWithPropertiesListRequest = (params) => (
  useQuery(
    ["useAreaWithPropertiesListRequest", params],
    async () => await getAreaWithPropertyList(params),
    {
      enabled: false,
    },
  )
);

type IUseAreaWithScrapedListRequest = (
  params: API.Area.List.Request,
) => UseQueryResult<
  AxiosResponse<API.Area.List.Response>,
  AxiosError<API.Area.List.ErrorResponse>
>;

export const useAreaWithScrapedListRequest: IUseAreaWithScrapedListRequest = (params) => (
  useQuery(
    ["useAreaWithScrapedListRequest", params],
    async () => await getAreaWithScrapedList(params),
    {
      enabled: false,
    },
  )
);

type IUseAreaListRequest = (params: API.Area.List.Request) => UseQueryResult<
  AxiosResponse<API.Area.List.Response>,
  AxiosError<API.Area.List.ErrorResponse>
>;

export const useAreaListRequest: IUseAreaListRequest = (params) => (
  useQuery(
    ["useAreaRequest", params],
    async () => await getAreaList(params),
    {
      enabled: false,
    },
  )
);

type IUseCityWithSTRListRequest = (parentId: API.Area.AreaData["id"]) => UseQueryResult<
  AxiosResponse<API.Area.List.Response>,
  AxiosError<API.Area.List.ErrorResponse>
>;

export const useCityWithSTRListRequest: IUseCityWithSTRListRequest = (stateId) => (
  useQuery(
    ["useCityWithSTRListRequest", stateId.toString()],
    async () => await listCityWithSTRByState(stateId),
    {
      enabled: false,
    },
  )
);

type IUseAreaBoundaryRequest = (parentId: API.Area.AreaData["id"]) => UseQueryResult<
  AxiosResponse<API.Area.AreaBoundary.Response>,
  AxiosError<API.Area.AreaBoundary.ErrorResponse>
>;

export const useAreaBoundaryRequest: IUseAreaBoundaryRequest = (areaId) => (
  useQuery(
    ["useAreaBoundaryRequest", areaId.toString()],
    async () => await getAreaBoundary(areaId),
    {
      enabled: false,
      keepPreviousData: true,
    },
  )
);

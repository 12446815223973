import React from "react";
import { CellContext } from "@tanstack/react-table";
import { useExecutionKeywordListRequest } from "src/hooks/api/admin/scrapers";

interface DescriptionCellProps {
  cell: CellContext<AdminAPI.Property.PropertyTableData, "description">
}

const DescriptionCell: React.FC<DescriptionCellProps> = (
  {
    cell,
  },
) => {
  const {
    data,
  } = useExecutionKeywordListRequest();

  const keywords = React.useMemo(() => {
    if (!data?.data) {
      return [];
    }
    return data.data.map((keyword) => (
      // eslint-disable-next-line no-useless-escape
      keyword.keyword.replaceAll(/\"/g, "")
    ));
  }, [data]);

  const description = React.useMemo(() => {
    if (!cell.row.original.description) {
      return "";
    }

    let output = cell.row.original.description.replaceAll(/\r\n|\r|\n/g, "<br />");
    keywords.forEach((keyword) => {
      output = output.replace(new RegExp(keyword, "gi"), `<strong class="text-primary">${keyword}</strong>`);
    });

    output = output.replace(/no/gi, `<strong class="text-danger">no</strong>`);

    return output;
  }, [cell.row.original.description, keywords]);

  // @ts-ignore
  if (cell.table.getState().descriptionVisible) {
    return (
      <div
        className="av-table-ellipsis__cell--no-ellipsis"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  }
  return (
    <span
      style={{ maxHeight: 30 }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default DescriptionCell;

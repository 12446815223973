import React from "react";
import Button from "src/components/ui/form/button";
import { useBooleanState } from "src/hooks/boolean";
import Modal from "react-bootstrap/esm/Modal";
import MarketCampaignPropertyContactConversationHistory
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyContactConversationHistory";

interface MarketCampaignPropertyContactConversationModalProps {
  className?: string;
  prop: AgentAPI.MarketCampaign.PropertyListData | AdminAPI.Property.PropertyTableData;
}

const MarketCampaignPropertyContactConversationModal: React.FC<MarketCampaignPropertyContactConversationModalProps> = (
  {
    className,
    prop,
  },
) => {
  const {
    state: isShow,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBooleanState(false);

  return (
    <>
      <Button
        variant="secondary"
        size="sm"
        className={className}
        onClick={showModal}
      >
        Conversation
      </Button>
      <Modal
        centered
        onHide={hideModal}
        show={isShow}
        size="lg"
      >
        <Modal.Header closeButton onHide={hideModal}>
          <Modal.Title>
            {`Conversation history with ${prop.contact?.name || "no contact"} about ${prop.title}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MarketCampaignPropertyContactConversationHistory prop={prop} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={hideModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MarketCampaignPropertyContactConversationModal;

/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
  "toastContainer" | "toast"
]>;

const styles: IStyles = {
  toastContainer: (theme) => css({
    position: "fixed",
    top: theme.header.height,
    padding: theme.spaces.md,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      padding: theme.spaces.xs,
    },
  }),
  toast: (theme) => css({
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      width: "100%",
    },
  }),
};

export default styles;

import React from "react";
import { flexRender, Header } from "@tanstack/react-table";

interface TableHeaderProps {
  header: Header<AdminAPI.Property.PropertyTableData, unknown>;
}

const TableHeader: React.FC<TableHeaderProps> = (
  {
    header,
  },
) => {
  const tableResizerClasses = header.column.getIsResizing()
    ? "av-table-resizer av-table-resizer--resizing"
    : "av-table-resizer";

  const resizeHandler = header.getResizeHandler();

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    resizeHandler(event);
  };

  return (
    <th
      scope="col"
      key={header.id}
      colSpan={header.colSpan}
      style={{
        width: header.getSize(),
        position: "relative",
      }}
    >
      <div className="av-table-ellipsis__cell">
        {header.isPlaceholder
          ? null
          : (
            flexRender(
              header.column.columnDef.header,
              header.getContext(),
            )
          )}
        {header.column.getCanResize() && (
          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
          <div
            onMouseDown={handleMouseDown}
            onTouchStart={header.getResizeHandler()}
            className={tableResizerClasses}
          />
        )}
      </div>
    </th>
  );
};

export default TableHeader;

import React from "react";
import Button from "src/components/ui/form/button";
import Icon from "src/components/ui/icons";
import { useBooleanState } from "src/hooks/boolean";
import FormControl from "react-bootstrap/esm/FormControl";
import {
  useAgentMarketCampaignPropertyUpdateRequest,
} from "src/hooks/api/agent/market-campaign";
import { useRouterParamId } from "src/hooks/router";
import { useToastStore } from "src/store/toast";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControlError from "src/components/ui/form/error/FormControlError";
import Form from "react-bootstrap/esm/Form";

interface MarketCampaignPropertyContactUpdateProps {
  initialValue: string;
  field: keyof AgentAPI.MarketCampaign.PropertyUpdate.Request["contact"];
  propertyId: number | string;
}

const MarketCampaignPropertyContactUpdate: React.FC<MarketCampaignPropertyContactUpdateProps> = (
  {
    initialValue,
    field,
    propertyId,
  },
) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const {
    addToastSuccess,
    addToastDanger,
  } = useToastStore();
  const marketCampaignId = useRouterParamId("id");
  const [value, setValue] = React.useState<string>(() => (
    initialValue
  ));
  const {
    state: isEditing,
    setTrue: setIsEditing,
    setFalse: setIsNotEditing,
  } = useBooleanState(false);

  const {
    mutate,
    isSuccess,
    error,
    isError,
    isLoading,
  } = useAgentMarketCampaignPropertyUpdateRequest(marketCampaignId);

  React.useEffect(() => {
    if (isSuccess) {
      addToastSuccess({
        title: `Property contact ${field} updated successfully`,
      });
      setIsNotEditing();
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing, inputRef.current]);

  React.useEffect(() => {
    if (isError && error?.response?.data?.contact?.[field]) {
      addToastDanger({
        title: error.response.data.contact[field]!.join(" "),
      });
    }
  }, [error, isError]);

  const handleSave = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent) => {
    event.preventDefault();
    mutate({
      id: propertyId,
      contact: {
        [field]: value,
      },
    });
    return false;
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      {!isEditing && (
        <div className="d-flex flex-nowrap">
          {value}
          <Button
            variant="transparent"
            size="sm"
            className="ms-2 p-0"
            onClick={setIsEditing}
            title="edit"
            icon={(
              <Icon
                name="note-pen"
                size={20}
              />
            )}
          />
        </div>
      )}

      {isEditing && (
        <Form onSubmit={handleSave}>
          <InputGroup className="d-flex flex-nowrap">
            <FormControl
              ref={inputRef}
              value={value}
              onChange={handleChange}
              size="sm"
              style={{
                minWidth: 200,
              }}
            />
            <Button
              type="submit"
              size="sm"
              variant="success"
              onClick={handleSave}
              loading={isLoading}
            >
              Save
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={setIsNotEditing}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </InputGroup>
          {isError && (
            <FormControlError
              error={error?.response?.data?.contact?.[field]}
              absolute={false}
            />
          )}
        </Form>
      )}

    </div>
  );
};

export default MarketCampaignPropertyContactUpdate;

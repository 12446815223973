import React from "react";
import { SerializedStyles } from "@emotion/react";
import { Breakpoint } from "src/store/responsive";

interface ThemeConfig {
  baseSize: number;
  fontFamily: string;
  colorBackground: string;
  colorWhite: string;
  colorBlack: string;
  colorBackgroundPrimary: string;
  colorPrimary: string;
  palettePrimary: {
    100: string;
    700: string;
    900: string;
    950: string;
  },
  colorSecondary: string;
  colorOutline: string;
  colorOutlineLight: string;
  colorTextPrimary: string;
  colorTextSecondary: string;
  colorTextMuted: string;
  colorDanger: string;
  colorWarning: string;
  colorSuccess: string;
  gridGutterWidth: number;
  borderRadius: number;
  boxShadowCard: string;
  breakpoints: {
    xxxl: number,
    xxl: number,
    xl: number,
    lg: number,
    md: number,
    sm: number,
    xs: number,
    up: (breakpoint: Breakpoint) => string;
    down: (breakpoint: Breakpoint) => string;
    between: (from: Breakpoint, to: Breakpoint) => string;
  },
  mq: {
    xxl: string;
    xl: string;
    lg: string;
    md: string;
    sm: string;
    xs: string;
    retina: string;
  },
  gridGap: number,
  spaces: {
    xxs: number;
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
    xxxl: number;
  }
  fontSizes: {
    xs: string;
    sm: string;
    base: string;
    lg: string;
    titles: {
      h1: number;
      h2: number;
      h3: number;
      h4: number;
      h5: number;
      h6: number;
      h7: number;
    }
  }
  fontWeight: {
    light: number;
    normal: number;
    semiBold: number;
    bold: number;
    bolder: number;
  },
  lineHeight: {
    xs: number;
    sm: number;
    base: number;
    lg: number;
  },
  header: {
    height: number;
  },
  content: {
    paddingX: number;
    paddingY: number;
  },
  menu: {
    sideWidthOpen: number;
    sideWidth: number;
    iconSize: number;
    colorText: string;
    colorActiveBackground: string;
  },
}

const theme: ThemeConfig = {
  baseSize: 16,
  fontFamily: "Montserrat, sans-serif",
  colorWhite: "#FFFFFF",
  colorBlack: "#000000",
  colorBackground: "#FFFFFF",
  colorBackgroundPrimary: "#FFFDFB",
  colorTextPrimary: "#151515",
  colorTextSecondary: "#6B7280",
  colorTextMuted: "#747B88",
  colorPrimary: "#FF5E04",
  palettePrimary: {
    100: "#FFF3E9",
    700: "#FFC5A5",
    900: "#FF9356",
    950: "#FF7C2B",
  },
  colorSecondary: "#FFF3E9",
  colorDanger: "#FF6C6C",
  colorSuccess: "#34A853",
  colorWarning: "#E48515",
  colorOutline: "#E4E6E8",
  colorOutlineLight: "#E7E7E7",
  gridGutterWidth: 16,
  borderRadius: 10,
  boxShadowCard: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
  gridGap: 16,
  spaces: {
    xxs: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 48,
    xxxl: 64,
  },
  breakpoints: {
    xxxl: 1920,
    xxl: 1600,
    xl: 1460,
    lg: 1280,
    md: 860,
    sm: 576,
    xs: 0,
    up: (breakpoint) => `@media all and (min-width: ${theme.breakpoints[breakpoint] + 0.01}px)`,
    down: (breakpoint) => `@media all and (max-width: ${theme.breakpoints[breakpoint]}px)`,
    between: (from, to) => `@media all and (min-width: ${theme.breakpoints[from] + 0.01}px) and (max-width: ${theme.breakpoints[to]}px)`,
  },
  mq: {
    xxl: `@media all and (max-width: 1920px)`,
    xl: `@media all and (max-width: 1600px)`,
    lg: `@media all and (max-width: 1460px)`,
    md: `@media all and (max-width: 1280px)`,
    sm: `@media all and (max-width: 860px)`,
    xs: `@media all and (max-width: 576px)`,
    retina: `@media all and (min-resolution: 192dpi)`,
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.25rem",
    titles: {
      h1: 36,
      h2: 32,
      h3: 28,
      h4: 24,
      h5: 20,
      h6: 16,
      h7: 14,
    },
  },
  fontWeight: {
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 700,
    bolder: 900,
  },
  lineHeight: {
    xs: 1.2,
    sm: 1.3,
    base: 1.5,
    lg: 1.7,
  },
  header: {
    height: 80,
  },
  content: {
    paddingX: 40,
    paddingY: 32,
  },
  menu: {
    sideWidthOpen: 260,
    sideWidth: 90,
    iconSize: 20,
    colorText: "#363636",
    colorActiveBackground: "#FFF3E9",
  },
};

export type IStyleFunction<P = any> = (currentTheme: ThemeConfig, params?: P) => React.CSSProperties;

export type IStyleBlock<K extends string[], P = any> = {
  [key in K[number]]: IStyleFunction<P>;
};

type ThemedStyles<T extends Record<string, IStyleFunction>> = {
  [key in keyof T]: React.CSSProperties;
}

export type IClassFunction<P = any> = (currentTheme: ThemeConfig, params?: P) => SerializedStyles;

type ThemedClasses<T extends Record<string, IClassFunction>> = {
  [key in keyof T]: SerializedStyles;
}

export type IClassBlock<K extends string[], P = any> = {
  [key in K[number]]: IClassFunction<P>;
};

export const useThemedStyles = <T extends Record<string, IStyleFunction>>(
  styles: T, params: any = undefined,
): ThemedStyles<T> => (
    React.useMemo(() => Object.keys(styles).reduce((obj, key) => (
      {
        ...obj,
        [key]: styles[key](theme, params),
      }
    ), {} as ThemedStyles<T>), [styles, params])
  );

export const useThemedCss = <T extends Record<string, IClassFunction>>(
  styles: T, params: any = undefined,
): ThemedClasses<T> => (
    React.useMemo(() => Object.keys(styles).reduce((obj, key) => (
      {
        ...obj,
        [key]: styles[key](theme, params),
      }
    ), {} as ThemedClasses<T>), [styles, params])
  );

export const useTheme = (): ThemeConfig => theme;

import React from "react";
import { useProfileAdminDetailRequest } from "src/hooks/api/admin/account";
import CenterPreloader from "src/components/loaders/CenterPreloader";
import Text from "src/components/ui/text";

interface MarketCampaignInvestorProfileProps {
  marketCampaign: AgentAPI.MarketCampaign.MarketCampaignDetailData;
}

const MarketCampaignInvestorProfile: React.FC<MarketCampaignInvestorProfileProps> = (
  {
    marketCampaign,
  },
) => {
  const {
    data,
    isLoading,
    error,
  } = useProfileAdminDetailRequest(marketCampaign.requested_by.id);

  return (
    <div>
      <h4 className="mb-4">
        Investor profile
      </h4>
      {isLoading && (
        <CenterPreloader />
      )}
      {error?.response?.data.detail && (
        <Text color="danger">
          {error.response.data.detail}
        </Text>
      )}
      {data?.data?.investor_profile && (
        <ul className="av-list" style={{ maxWidth: 480 }}>
          <li className="av-list__item d-flex align-items-center justify-content-between">
            Profile type:
            <b>{data.data.investor_profile.profile_type}</b>
          </li>
          <li className="av-list__item d-flex align-items-center justify-content-between">
            Area:
            <b>{`${data.data.investor_profile.city_text}, ${data.data.investor_profile.state_text}`}</b>
          </li>
          {data.data.investor_profile.business_name && (
            <li className="av-list__item d-flex align-items-center justify-content-between">
              Business name:
              <b>{data.data.investor_profile.business_name}</b>
            </li>
          )}
          {data.data.investor_profile.business_credit_risk_rating && (
            <li className="av-list__item d-flex align-items-center justify-content-between">
              Credit risk rating:
              <b>{data.data.investor_profile.business_credit_risk_rating}</b>
            </li>
          )}
          {data.data.investor_profile.years_in_business && (
            <li className="av-list__item d-flex align-items-center justify-content-between">
              Years in business:
              <b>{data.data.investor_profile.years_in_business}</b>
            </li>
          )}
          {data.data.investor_profile.business_phone_number && (
            <li className="av-list__item d-flex align-items-center justify-content-between">
              Business phone number:
              <b>{data.data.investor_profile.business_phone_number}</b>
            </li>
          )}
          {data.data.investor_profile.business_email && (
            <li className="av-list__item d-flex align-items-center justify-content-between">
              Business email:
              <b>{data.data.investor_profile.business_email}</b>
            </li>
          )}
          {data.data.investor_profile.business_website && (
            <li className="av-list__item d-flex align-items-center justify-content-between">
              Business website:
              <b>{data.data.investor_profile.business_website}</b>
            </li>
          )}
          {data.data.investor_profile.bio && (
            <li className="av-list__item d-flex align-items-center justify-content-between">
              Bio:
              <p>{data.data.investor_profile.bio}</p>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default MarketCampaignInvestorProfile;

/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "container" |
    "content" |
    "label" |
    "icon"
], boolean>;

const styles: IStyles = {
  container: () => css({
    margin: 0,
  }),
  content: (theme) => css({
    marginBottom: theme.spaces.md,
  }),
  label: (theme) => css({
    fontWeight: theme.fontWeight.bold,
    color: theme.colorTextPrimary,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
    paddingTop: theme.spaces.xs,
    paddingBottom: theme.spaces.xs,
    cursor: "pointer",
  }),
  icon: () => css({
    transition: "transform 0.3s ease-in-out",
    transformOrigin: "center",
  }),
};

export default styles;

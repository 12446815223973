import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  addStripePaymentMethod,
  cancelCurrentMembership,
  createMarketRequest,
  deleteProfileBillingMethod,
  getCurrentActiveServices,
  getCurrentMembership,
  getCurrentMemberships,
  getProfile,
  getProfileBillingMethodList,
  getProfileCredits,
  getProfileInvoiceList,
  renewMembership,
  setProfileBillingMethodPrimary,
  updateInvestorProfile,
  updatePassword,
  updateProfile,
  updateProfilePhoneNumber,
  getProfileMarketCampaignList, postProfileMarketCampaignAddProperty,
} from "src/api/profile";
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query/src/types";
import React, { useEffect } from "react";
import { useAuthStore } from "src/store/account";
import { useProfileStore } from "src/store/profile";

export type IUseGetProfileQueryData = UseQueryResult<
  AxiosResponse<API.Profile.Get.Response>,
  AxiosError<API.Profile.Get.ErrorResponse>
>;

type IUseGetProfileRequest = (enabled?: boolean) => IUseGetProfileQueryData;

export const useGetProfileRequest: IUseGetProfileRequest = (enabled: boolean = true) => {
  const queryData: IUseGetProfileQueryData = useQuery(
    ["useGetProfileRequest"],
    getProfile,
    {
      enabled,
      cacheTime: 0,
    },
  );

  React.useEffect(() => {
  }, [queryData.status]);

  const {
    setIsSuperuser,
  } = useAuthStore();

  useEffect(() => {
    if (queryData.status === "success" && queryData.data?.data) {
      setIsSuperuser(queryData.data.data.is_superuser);
    }
  }, [queryData.status]);

  return queryData;
};

type IUseUpdateProfileRequest = (
  callbackFn?: (data: AxiosResponse<API.Profile.Update.Response>) => void,
) => UseMutationResult<
  AxiosResponse<API.Profile.Update.Response>,
  AxiosError<API.Profile.Update.ErrorResponse>,
  Partial<API.Profile.Update.Request>
>;

export const useUpdateProfileRequest: IUseUpdateProfileRequest = (callbackFn) => {
  const {
    setProfileFromResponse,
  } = useProfileStore();
  return (
    useMutation({
      mutationFn: async (data) => await updateProfile(data),
      onSuccess: (data) => {
        setProfileFromResponse(data.data);

        if (callbackFn) {
          callbackFn(data);
        }
      },
    })
  );
};

type IUseUpdatePasswordRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.UpdatePassword.Response>,
  AxiosError<API.Profile.UpdatePassword.ErrorResponse>,
  API.Profile.UpdatePassword.Request
>;

export const useUpdatePasswordRequest: IUseUpdatePasswordRequest = () => (
  useMutation({
    mutationFn: async (data) => await updatePassword(data),
  })
);

type IUseSetPrimaryBillingMethodRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.SetPaymentMethodPrimary.Response>,
  AxiosError<API.Profile.SetPaymentMethodPrimary.ErrorResponse>,
  API.Profile.BillingList.BillingData["id"]
>;

export const useSetPrimaryBillingMethodRequest: IUseSetPrimaryBillingMethodRequest = () => (
  useMutation({
    mutationFn: async (id) => await setProfileBillingMethodPrimary(id),
  })
);

type IUseDeleteBillingMethodRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.DeletePaymentMethod.Response>,
  AxiosError<API.Profile.DeletePaymentMethod.ErrorResponse>,
  API.Profile.BillingList.BillingData["id"]
>;

export const useDeleteBillingMethodRequest: IUseDeleteBillingMethodRequest = () => (
  useMutation({
    mutationFn: async (id) => await deleteProfileBillingMethod(id),
  })
);

type IUseCancelCurrentMembershipRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.CancelMembership.Response>,
  AxiosError<API.Profile.CancelMembership.ErrorResponse>,
  API.Profile.Memberships.MembershipData["id"]
>;

export const useCancelCurrentMembershipRequest: IUseCancelCurrentMembershipRequest = () => (
  useMutation({
    mutationFn: async (id) => await cancelCurrentMembership(id),
  })
);

type IUseRenewMembershipRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.RenewMembership.Response>,
  AxiosError<API.Profile.RenewMembership.ErrorResponse>,
  API.Profile.Memberships.MembershipData["id"]
>;

export const useRenewMembershipRequest: IUseRenewMembershipRequest = () => (
  useMutation({
    mutationFn: async (id) => await renewMembership(id),
  })
);

type IUseCreateMarketRequestRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.RequestMarket.Response>,
  AxiosError<API.Profile.RequestMarket.ErrorResponse>,
  API.Profile.RequestMarket.Request
>;

export const useCreateMarketRequestRequest: IUseCreateMarketRequestRequest = () => (
  useMutation({
    mutationFn: async (data) => await createMarketRequest(data),
  })
);

type IUseProfileBillingMethodListRequest = (enabled?: boolean) => UseQueryResult<
  AxiosResponse<API.Profile.BillingList.Response>,
  AxiosError<API.Profile.BillingList.ErrorResponse>
>;

export const useProfileBillingMethodListRequest: IUseProfileBillingMethodListRequest = (enabled = true) => (
  useQuery(
    ["useProfileBillingMethodListRequest"],
    getProfileBillingMethodList,
    {
      keepPreviousData: true,
      enabled,
    },
  )
);

type IUseAddPaymentMethodStripeRequest = (orderId?: API.Packages.OrderData["id"]) => UseQueryResult<
  AxiosResponse<API.Profile.AddPaymentMethod.Response>,
  AxiosError<API.Profile.AddPaymentMethod.ErrorResponse>
>;

export const useAddPaymentMethodStripeRequest: IUseAddPaymentMethodStripeRequest = (orderId) => (
  useQuery(
    ["useAddPaymentMethodStripeRequest", orderId],
    async () => await addStripePaymentMethod(orderId),
  )
);

type IUseCurrentMembershipsRequest = (data: API.Profile.Memberships.Request) => UseQueryResult<
  AxiosResponse<API.Profile.Memberships.Response>,
  AxiosError<API.Profile.Memberships.ErrorResponse>
>;

export const useCurrentMembershipsRequest: IUseCurrentMembershipsRequest = (data) => (
  useQuery(
    ["useCurrentMembershipsRequest"],
    async () => await getCurrentMemberships(data),
  )
);

type IUseCurrentMembershipRequest = (enabled?: boolean) => UseQueryResult<
  AxiosResponse<API.Profile.CurrentMembership.Response>,
  AxiosError<API.Profile.CurrentMembership.ErrorResponse>
>;

export const useCurrentMembershipRequest: IUseCurrentMembershipRequest = (enabled = true) => (
  useQuery(
    ["useCurrentMembershipsRequest"],
    async () => await getCurrentMembership(),
    { enabled },
  )
);

type IUseCurrentProfileServicesRequest = () => UseQueryResult<
  AxiosResponse<API.Profile.Memberships.Response>,
  AxiosError<API.Profile.Memberships.ErrorResponse>
>;

export const useCurrentProfileServicesRequest: IUseCurrentProfileServicesRequest = () => (
  useQuery(
    ["useCurrentProfileServicesRequest"],
    async () => await getCurrentActiveServices(),
  )
);

type IUseProfileInvoiceListRequest = (
  data: API.Profile.InvoiceList.Request,
) => UseQueryResult<
  AxiosResponse<API.Profile.InvoiceList.Response>,
  AxiosError<API.Profile.InvoiceList.ErrorResponse>
>;

export const useProfileInvoiceListRequest: IUseProfileInvoiceListRequest = (data) => (
  useQuery(
    ["useProfileInvoiceListRequest", data],
    async () => await getProfileInvoiceList(data),
    {
      keepPreviousData: true,
    },
  )
);

type IUseGetProfileCreditsRequest = (
  data?: API.Profile.Credits.Request,
) => UseQueryResult<
  AxiosResponse<API.Profile.Credits.Response>,
  AxiosError<API.Profile.Credits.ErrorResponse>
>;

export const useGetProfileCreditsRequest: IUseGetProfileCreditsRequest = (data) => (
  useQuery(
    ["useGetProfileCreditsRequest", data],
    async () => await getProfileCredits(data),
    {},
  )
);

type IUseProfileMarketCampaignListRequest = () => UseQueryResult<
  AxiosResponse<API.Profile.MarketCampaign.Response>,
  AxiosError<API.Profile.MarketCampaign.ErrorResponse>
>;

export const useProfileMarketCampaignListRequest: IUseProfileMarketCampaignListRequest = () => (
  useQuery(
    ["useProfileMarketCampaignListRequest"],
    getProfileMarketCampaignList,
  )
);

type IUseUpdateInvestmentProfileRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.UpdateInvestorProfile.Response>,
  AxiosError<API.Profile.UpdateInvestorProfile.ErrorResponse>,
  Partial<API.Profile.UpdateInvestorProfile.Request>
>;

export const useUpdateInvestmentProfileRequest: IUseUpdateInvestmentProfileRequest = () => (
  useMutation({
    mutationFn: async (data) => await updateInvestorProfile(data),
  })
);

type IUseProfileMarketCampaignAddPropertyRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.AddPropertyToCampaign.Response>,
  AxiosError<API.Profile.AddPropertyToCampaign.ErrorResponse>,
  API.Profile.AddPropertyToCampaign.Request & { market_campaign_id: string }
>;

export const useProfileMarketCampaignAddPropertyRequest: IUseProfileMarketCampaignAddPropertyRequest = () => (
  useMutation({
    mutationFn: async (
      { market_campaign_id: marketCampaignId, ...data },
    ) => await postProfileMarketCampaignAddProperty(marketCampaignId, data),
  })
);

type IUseProfilePhoneNumberUpdateRequest = () => UseMutationResult<
  AxiosResponse<API.Profile.UpdatePhoneNumber.Response>,
  AxiosError<API.Profile.UpdatePhoneNumber.ErrorResponse>,
  Partial<API.Profile.UpdatePhoneNumber.Request>
>;

export const useProfilePhoneNumberUpdateRequest: IUseProfilePhoneNumberUpdateRequest = () => (
  useMutation({
    mutationFn: async (data) => await updateProfilePhoneNumber(data),
  })
);

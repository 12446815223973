import React from "react";
import { useTitleStore } from "src/store/title";

export const useMetaTitleSetter = (title: string) => {
  const { setTitle } = useTitleStore();

  React.useEffect(() => {
    setTitle(title);
  }, [title]);
};

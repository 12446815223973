/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

type StyleParams = {
  isOpen: boolean;
  isActive: boolean;
};

export type IStyles = IClassBlock<[
  "link" |
  "linkActive" |
  "linkOpen" |
  "linkClosed" |
  "icon" |
  "arrow" |
  "arrowOpen" |
  "arrowFloating" |
  "dropdown" |
  "dropdownItem" |
  "dropdownItemFull" |
  "text"
], StyleParams>;

const styles: IStyles = {
  link: (theme) => css({
    paddingTop: theme.spaces.md,
    paddingBottom: theme.spaces.md,
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    cursor: "pointer",
    color: theme.menu.colorText,
    marginBottom: theme.spaces.xs,
    width: "100%",
    transition: "all 0.2s ease-in-out",
    minHeight: 56,
    position: "relative",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&:hover": {
      color: theme.colorPrimary,
    },
  }),
  linkActive: (theme, state) => css({
    background: theme.menu.colorActiveBackground,
    color: theme.colorPrimary,
  }),
  linkOpen: (theme, state) => css({
    paddingLeft: (theme.menu.sideWidth - theme.menu.iconSize) / 2,
    paddingRight: (theme.menu.sideWidth - theme.menu.iconSize) / 2,
  }),
  linkClosed: (theme, state) => css({
    paddingLeft: theme.spaces.lg,
    justifyContent: "center",
    paddingRight: theme.spaces.lg,
  }),
  icon: (theme) => css({
    marginRight: theme.spaces.md,
  }),
  arrow: (theme) => css({
    marginLeft: "auto",
    marginRight: 0,
    justifySelf: "flex-end",
    transform: "rotate(0deg)",
    transition: "transform 0.2s ease-in-out",
    transformOrigin: "center",
  }),
  arrowOpen: (theme) => css({
    transform: "rotate(180deg)",
  }),
  arrowFloating: (theme) => css({
    position: "absolute",
    top: "50%",
    right: 8,
    marginTop: -10,
  }),
  dropdown: (theme) => css({
    marginBottom: 1,
  }),
  dropdownItem: (theme) => css({
    fontSize: "0.875em",
    paddingTop: theme.spaces.xs,
    paddingBottom: theme.spaces.xs,
    minHeight: 40,
    marginBottom: 1,
    svg: {
      width: 16,
      height: 16,
    },
  }),
  dropdownItemFull: (theme) => css({
    svg: {
      marginLeft: theme.spaces.sm,
    },
  }),
  text: (theme) => css({}),
};

export default styles;

import { makeAnonymousRequest } from "src/api/request";
import { AxiosError } from "axios";

export const login = async (data: API.Authentication.ObtainToken.Request) => (
  await makeAnonymousRequest.post<API.Authentication.ObtainToken.Response>("/auth/token/", data)
);

export const loginWithSocial = async (data: API.Authentication.ObtainTokenSocial.Request) => (
  await makeAnonymousRequest.post<API.Authentication.ObtainTokenSocial.Response>("/auth/token/exchange-from-social/", data)
);

export const verifyToken = async (data: API.Authentication.VerifyToken.Request) => {
  if (!data.token) {
    throw new AxiosError("Token is not provided");
  }
  return await makeAnonymousRequest.post<API.Authentication.VerifyToken.Response>("/auth/token/verify/", data);
};

export const refreshToken = async (data: API.Authentication.RefreshToken.Request) => {
  if (!data.refresh) {
    throw new AxiosError("Token is not provided");
  }
  return await makeAnonymousRequest.post<API.Authentication.RefreshToken.Response>("/auth/token/refresh/", data);
};

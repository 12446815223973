import React from "react";
import { formatNumber } from "src/utils/formatters/number";

interface AsNumberProps {
  value?: string | number | null;
  decimalPlaces?: number;
  style?: React.CSSProperties;
  className?: string;
}

const AsNumber: React.FC<AsNumberProps> = (
  {
    value,
    decimalPlaces = 0,
    ...props
  },
) => {
  const formattedValue: string = React.useMemo(() => (
    formatNumber(value, decimalPlaces)
  ), [value, decimalPlaces]);

  return (
    <span {...props}>{formattedValue}</span>
  );
};

export default AsNumber;

import React from "react";
import { formatPercent } from "src/utils/formatters/number";

interface AsPercentProps {
  value?: string | number | null;
  className?: string;
  decimalPlaces?: number;
  style?: React.CSSProperties;
}

const AsPercent: React.FC<AsPercentProps> = (
  {
    value,
    decimalPlaces = 2,
    ...props
  },
) => {
  const formattedValue: string = React.useMemo(() => (
    formatPercent(value, decimalPlaces)
  ), [value, decimalPlaces]);

  return (
    <span {...props}>{formattedValue}</span>
  );
};

export default AsPercent;

/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "tableContainer" |
    "table"
]>;

const styles: IStyles = {
  tableContainer: (theme) => css({
    overflowX: "scroll",
    position: "relative",
  }),
  table: (theme) => css({
    borderCollapse: "separate",
    borderSpacing: 0,
    th: {
      position: "relative",
    },
    "tfoot td": {
      position: "relative",
    },
  }),
};

export default styles;

import React from "react";
import FormLabel from "react-bootstrap/esm/FormLabel";
import { TooltipIcon } from "src/components/ui/tooltip";
import type { FormLabelProps as ParentFormLabelProps } from "react-bootstrap/esm/FormLabel";

type FormLabelProps = ParentFormLabelProps & {
  hintText: React.ReactNode;
};

const FormLabelWithHint: React.FC<FormLabelProps> = (
  {
    hintText,
    children,
    ...props
  },
) => (
  <FormLabel {...props}>
    {children}
    <TooltipIcon
      text={hintText}
      iconType="secondary"
    />
  </FormLabel>
);

export default FormLabelWithHint;

import React from "react";
import { ChipMenu } from "src/components/ui/menu";
import { Outlet } from "react-router-dom";

const MarketCampaignsPage = () => (
  <section>
    <ChipMenu
      level={2}
      style={{ marginBottom: 24 }}
      items={[
        { to: "/agent/market-campaigns/", title: "Assigned market campaigns" },
      ]}
    />
    <Outlet />
  </section>
);

export default MarketCampaignsPage;

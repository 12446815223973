/** @jsxImportSource @emotion/react */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { jsx } from "@emotion/react";
import Icon, { IconName } from "src/components/ui/icons";
import Text from "src/components/ui/text";
import { useTheme, useThemedCss } from "src/hooks/styles";
import { useMenuActive } from "src/hooks/menu";
import styles, { IStyles } from "src/components/ui/menu/MenuItem.styles";
import { useBooleanState } from "src/hooks/boolean";
import { useTutorialStore } from "src/store/tutorial";

interface MenuItemDropdownProps {
  id?: string;
  title: string;
  iconName: IconName;
  isOpen: boolean;
  children: React.ReactElement[];
  activeTo?: string;
}

// @ts-ignore
const cloneElement = (element, props) => jsx(element.type, {
  ...element.props,
  ...props,
});

const MenuItemDropdown: React.FC<MenuItemDropdownProps> = (
  {
    id,
    title,
    iconName,
    isOpen,
    children,
    activeTo,
  },
) => {
  const links = React.useMemo(() => {
    if (activeTo) {
      return activeTo;
    }
    return children.map((child) => child.props.to);
  }, [children, activeTo]);

  const isActive = useMenuActive(links);

  const { setReadyComponents } = useTutorialStore();

  React.useEffect(() => {
    if (id) {
      setReadyComponents([`#${id}`]);
    }
  }, [id]);

  const {
    state: isDropdownOpen,
    toggle: toggleDropdown,
  } = useBooleanState(() => (
    isActive
  ));
  const { menu: { iconSize } } = useTheme();
  const themedCss = useThemedCss<IStyles>(styles);

  const themedLinkCss = React.useMemo(() => {
    const css = [themedCss.link, themedCss.dropdown];
    if (isOpen) {
      css.push(themedCss.linkOpen);
    } else {
      css.push(themedCss.linkClosed);
    }
    if (isActive) {
      css.push(themedCss.linkActive);
    }
    return css;
  }, [isOpen, isActive]);

  const arrowCss = React.useMemo(() => {
    const css = [themedCss.arrow];

    if (!isOpen) {
      css.push(themedCss.arrowFloating);
    }

    if (isDropdownOpen) {
      css.push(themedCss.arrowOpen);
    }

    return css;
  }, [isOpen, isDropdownOpen]);

  const dropdownItemCss = React.useMemo(() => {
    const css = [themedCss.dropdownItem];

    if (isOpen) {
      css.push(themedCss.dropdownItemFull);
    }

    return css;
  }, [isOpen]);

  return (
    <>
      <div
        id={id}
        css={themedLinkCss}
        onClick={toggleDropdown}
      >
        <Icon
          name={iconName}
          variant="inherit"
          css={isOpen ? themedCss.icon : undefined}
          size={iconSize}
          style={{
            minWidth: iconSize,
            maxWidth: iconSize,
          }}
        />
        {isOpen && (
          <Text inherit css={themedCss.text}>{title}</Text>
        )}
        <Icon
          name="arrow-down"
          variant="inherit"
          css={arrowCss}
          size={iconSize}
          style={{
            minWidth: iconSize,
            maxWidth: iconSize,
          }}
        />
      </div>
      {isDropdownOpen && children.map((child, index) => cloneElement(child, {
        // eslint-disable-next-line react/no-array-index-key
        key: index.toString(),
        css: dropdownItemCss,
      }))}
    </>
  );
};

export default MenuItemDropdown;

import * as yup from "yup";
import { PropertyType } from "src/enums/property";

export type FormValue = API.Landlord.SubmitProperty.Request;

const invalidEmailMessage = "The given email address is not valid.";
const requiredMessage = "This field is required.";
const positiveNumberRequiredMessage = "This field should be a positive number";
const maxValueTextError = "The value could not exceed [max] characters.";

const testNumberOrEmpty = (value: any): boolean => (
  !value || parseInt(value, 10) > 0
);

export const landlordFormSchema = yup.object({
  furnishing: yup.string().oneOf(["", "furnished", "not_furnished", "partially_furnished"]).required(requiredMessage),
  income_requirement: yup.string().min(0, positiveNumberRequiredMessage).nullable(),
  apply_options: yup.array().of(yup.string().oneOf([
    "", "zillow", "renter_applications", "third_party",
  ])),
  lease_length: yup.array().of(yup.string().oneOf([
    "", "month_lease_6", "year_lease_1", "year_lease_2", "year_lease_4",
  ])),
  amenities: yup.array().of(yup.string().oneOf([
    "", "pool", "backyard", "garage", "hottub",
  ])),
  aware_str_regulations: yup.string().oneOf([
    "", "yes", "no", "not_sure",
  ]).required(requiredMessage),
  consider_selling: yup.string().oneOf([
    "", "yes", "no", "maybe",
  ]),
  consider_selling_period: yup.string().oneOf([
    "", "immediately", "month", "curious",
  ]),
  have_other_properties: yup.boolean(),
  additional_information: yup.string(),
  interested_in_portfolio_expand: yup.string().oneOf([
    "", "yes", "no",
  ]),
  interested_in_portfolio_expand_details: yup.string(),
  want_be_reached: yup.string().oneOf([
    "", "yes", "no",
  ]),
  have_management_team: yup.string().oneOf([
    "", "yes", "no",
  ]),
  open_for_property_management: yup.string().oneOf([
    "", "yes", "no",
  ]),
});

export const contactSchema = yup.object({
  name: yup.string().required(requiredMessage),
  email: yup.string().email(invalidEmailMessage).required(requiredMessage),
  phone: yup.string().max(15, maxValueTextError.replace("[max]", "15"))
    .test(
      "test-phone-number",
      "Phone number can contain only the following characters: -, (, ), digits and spaces.",
      (value) => {
        if (!value) {
          return true;
        }
        return value.match(/^[\d +()-]+$/g) !== null;
      },
    ).required(requiredMessage),
  business_name: yup.string(),
}).required();

export const baseSchema = yup.object({
  address: yup.string().required(requiredMessage),
  city: yup.string().required(requiredMessage),
  state: yup.string().required(requiredMessage),
  zip_code: yup.string(),
  rent_price: yup.number().min(0, positiveNumberRequiredMessage).required(requiredMessage),
  external_listed_at: yup.string().required(requiredMessage),
  hoa_monthly: yup.mixed().test(
    "testNumberOrEmpty",
    positiveNumberRequiredMessage,
    testNumberOrEmpty,
  ),
  bedrooms: yup.string().oneOf(["", "1", "2", "3", "4", "5", "6", "7"]).required(requiredMessage),
  bathrooms: yup.string().oneOf(["", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5", "6", "6.5", "7"]).required(requiredMessage),
  home_type: yup.string().oneOf(Object.values(PropertyType)).required(requiredMessage),
  property_area: yup.number().required(requiredMessage),
  deposit_amount: yup.mixed().test(
    "testNumberOrEmpty",
    positiveNumberRequiredMessage,
    testNumberOrEmpty,
  ),
  external_url: yup.string(),
  authorize_list_property: yup.boolean(),
  referral_code: yup.string().nullable(),
}).required();

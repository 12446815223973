/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";
import bgImage from "src/components/promo/sign-up.url.svg";

export type IStyles = IClassBlock<[
    "label" |
    "grid" |
    "dropArea" |
    "placeholder" |
    "photoPreviewContainer" |
    "photoDelete" |
    "photoPreview" |
    "photoPreviewSingle" |
    "input"
]>;

const styles: IStyles = {
  label: (theme) => css({
    display: "block",
    position: "relative",
    width: "100%",
    maxWidth: 480,
    "&:before": {
      content: "''",
      display: "block",
      width: 1,
      paddingTop: "30%",
      marginLeft: -1,
    },
  }),
  grid: (theme) => css({
    display: "grid",
    grid: "auto-flow/repeat(auto-fill, minmax(180px, 1fr))",
    gap: theme.spaces.sm,
    marginBottom: theme.spaces.md,
  }),
  input: (theme) => css({
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  }),
  dropArea: (theme) => css({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "2px dashed #E7E7E7",
    background: `${theme.colorSecondary}40`,
    padding: theme.spaces.md,
    borderRadius: theme.borderRadius,
  }),
  placeholder: (theme) => css({
    color: theme.colorTextSecondary,
    fontSize: theme.fontSizes.sm,
    textAlign: "center",
  }),
  photoPreviewContainer: (theme) => css({
    border: `1px solid ${theme.colorSecondary}`,
    borderRadius: theme.borderRadius - 2,
  }),
  photoDelete: (theme) => css({
    position: "absolute",
    top: 0,
    right: 0,
    padding: 0,
    cursor: "pointer",
    zIndex: 3,
  }),
  photoPreview: (theme) => css({
    width: "calc(100% - 4px)",
    height: "calc(100% - 4px)",
    objectFit: "cover",
    borderRadius: theme.borderRadius,
    top: 2,
    left: 2,
    zIndex: 2,
  }),
  photoPreviewSingle: (theme) => css({
    width: "100%",
    height: "auto",
    objectFit: "cover",
    borderRadius: theme.borderRadius,
  }),
};

export default styles;

import create from "zustand";
import { persist } from "zustand/middleware";
import { useProfileStore } from "src/store/profile";

interface IAuthStore {
  isAuthenticated: boolean;
  accessToken: string;
  refreshToken: string;
  isSuperuser: boolean;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsSuperuser: (isSuperuser: boolean) => void;
  unAuthenticate: () => void;
  isAccessTokenValid: () => boolean;
}

export const useAuthStore = create<IAuthStore>()(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      accessToken: "",
      refreshToken: "",
      isSuperuser: false,
      setIsSuperuser: (isSuperuser) => set({ isSuperuser }),
      setAccessToken: (accessToken) => set({ accessToken }),
      isAccessTokenValid: () => {
        const { accessToken } = get();

        if (!accessToken) {
          return false;
        }

        const tokenParts = accessToken.split(".");
        if (tokenParts.length !== 3) {
          return false;
        }

        try {
          const { exp } = JSON.parse(atob(tokenParts[1]));
          return Number.isNaN(exp) || exp > (Date.now() / 1000);
        } catch (e) {
          return false;
        }
      },
      setRefreshToken: (refreshToken) => set({ refreshToken }),
      setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
      unAuthenticate: () => {
        const { reset } = useProfileStore.getState();
        reset();
        return set({
          isAuthenticated: false,
          accessToken: "",
          refreshToken: "",
          isSuperuser: false,
        });
      },
    }),
    {
      name: "authStore",
    },
  ),
);

import React from "react";
import Button from "src/components/ui/form/button";
import {
  usePropertyContactConversationListRequest,
  usePropertyCreateContactConversationRequest,
  useUpdatePartialPropertyContactRequest,
} from "src/hooks/api/admin/property";
import { AsDateTime } from "src/components/ui/formatters";
import { AttachmentWithNote } from "src/components/attachment";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import FormControl from "src/components/ui/form/control/FormControl";
import FormGroup from "src/components/ui/form/group/FormGroup";
import Icon from "src/components/ui/icons";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { useToastStore } from "src/store/toast";
import FormControlError from "src/components/ui/form/error/FormControlError";

type FormValue = AdminAPI.Property.ConversationCreate.Request;

type ListFormValue = {
  attachments: FormValue[];
};

interface ConversationAttachmentProps {
  className?: string;
  index: number;
  register: ReturnType<typeof useForm<ListFormValue>>["register"];
  field: ReturnType<typeof useFieldArray>["fields"][0];
  fieldArray: ReturnType<typeof useFieldArray>;
}

const ConversationAttachment: React.FC<ConversationAttachmentProps> = (
  {
    className,
    index,
    register,
    field,
    fieldArray,
  },
) => {
  const [preview, setPreview] = React.useState<string | null>(null);
  const handleRemoveClick = () => {
    fieldArray.remove(index);
  };

  React.useEffect(() => {
    // @ts-ignore
    if (!field.attachment || !(field.attachment?.type || "").startsWith("image")) {
      return () => {
      };
    }

    const objectUrl = URL.createObjectURL(field.attachment as unknown as File);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [field]);

  return (
    <div className={`d-flex flex-nowrap ${className}`}>
      <div className="ratio ratio-4x3 me-2" style={{ width: 120 }}>
        {preview && (
          <img
            src={preview}
            alt="Preview"
            className="img-thumbnail"
            style={{ objectFit: "contain" }}
          />
        )}
        {!preview && (
          <Icon name="documents" size={48} />
        )}
      </div>
      <FormGroup className="m-0">
        <Form.Label>
          Notes
        </Form.Label>
        <InputGroup>
          <FormControl
            type="text"
            required
            {...register(`attachments.${index}.notes`)}
          />
          <Button
            className="flex-grow-0"
            variant="danger"
            onClick={handleRemoveClick}
            icon={<Icon name="cross" size={24} variant="inherit" />}
          />
        </InputGroup>
      </FormGroup>
    </div>
  );
};

interface MarketCampaignPropertyContactConversationHistoryProps {
  className?: string;
  prop: AgentAPI.MarketCampaign.PropertyListData |
    AgentAPI.MarketCampaign.SubmitProperty.Response |
    API.Landlord.SubmitProperty.Response |
    AdminAPI.Property.PropertyTableData;
}

const schema = yup.object().shape({
  attachments: yup.array().of(
    yup.object().shape({
      notes: yup.string().required("Notes is required"),
      attachment: yup.mixed().required("Attachment is required"),
    }),
  ),
});

const defaultValues: ListFormValue = {
  attachments: [],
};

const MarketCampaignPropertyContactConversationHistory: React.FC<MarketCampaignPropertyContactConversationHistoryProps> = (
  {
    className,
    prop,
  },
) => {
  const [contactSummary, setContactSummary] = React.useState<string>(() => {
    if (prop.contact) {
      return prop.contact.contact_summary || "";
    }

    return "";
  });

  const {
    addToastDanger,
    addToastSuccess,
  } = useToastStore();

  const {
    mutate: updateContact,
    status: contactUpdateStatus,
    isSuccess: contactUpdateIsSuccess,
    isError: contactUpdateIsError,
    error: contactUpdateError,
  } = useUpdatePartialPropertyContactRequest(prop.id);

  const handleUpdateConversationSummary = () => {
    updateContact({
      contact_summary: contactSummary,
    });
  };

  React.useEffect(() => {
    if (contactUpdateIsSuccess) {
      addToastSuccess({
        title: `Contact summary successfully updated for #${prop.id}.`,
      });
    } else if (contactUpdateIsError) {
      addToastDanger({
        title: "Failed to update contact summary",
      });
    }
  }, [contactUpdateIsSuccess, contactUpdateIsError]);

  const {
    control,
    register,
    handleSubmit,
  } = useForm<ListFormValue>(
    {
      defaultValues,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    },
  );

  const fieldArray = useFieldArray({
    control,
    name: "attachments",
  });

  const {
    data,
    refetch,
  } = usePropertyContactConversationListRequest(prop.id, false);

  React.useEffect(() => {
    refetch().finally();
  }, []);

  const {
    mutate,
    status: mutateStatus,
    isSuccess: mutateIsSuccess,
  } = usePropertyCreateContactConversationRequest(prop.id);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        fieldArray.append({
          notes: file.name,
          attachment: file,
        });
      });
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = "";
  };

  React.useEffect(() => {
    if (mutateIsSuccess) {
      refetch().finally();
      fieldArray.remove();
    }
  }, [mutateIsSuccess]);

  const onSubmit = (listFormData: ListFormValue) => {
    const preparedFormData = listFormData.attachments.map((itemData) => {
      if (!itemData.notes || !itemData.attachment) {
        return null;
      }

      const formDataInstance = new FormData();
      formDataInstance.append("notes", itemData.notes);
      formDataInstance.append("attachment", itemData.attachment);
      return formDataInstance;
    }).filter((fd) => fd !== null) as FormData[];

    if (preparedFormData.length > 0) {
      mutate(preparedFormData);
    }
  };

  return (
    <div className={className}>
      <Row>
        <Col xs={24}>
          <h5 className="mb-3">
            Previous uploads
          </h5>
        </Col>
        {data?.data && data?.data.map((conversation) => (
          <Col xs={24} lg={12} className="mb-3" key={conversation.id}>
            <AttachmentWithNote
              link={conversation.attachment}
              title={conversation.notes}
              subtitle={<AsDateTime value={conversation.created_at} />}
            />
          </Col>
        ))}
        {data?.data && data.data.length === 0 && (
          <Col xs={24} className="mb-3">
            <p>
              No uploads found for this property contact.
            </p>
          </Col>
        )}
      </Row>

      <Form
        className="mb-3"
        name="conversation-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <FormGroup>
          <Form.Label>
            Upload conversation files
          </Form.Label>
          <Form.Control
            type="file"
            multiple
            onChange={handleFileChange}
          />
        </FormGroup>

        {fieldArray.fields.map((field, index) => (
          <ConversationAttachment
            className="mb-3"
            // @ts-ignore
            fieldArray={fieldArray}
            key={field.id}
            // @ts-ignore
            field={field}
            index={index}
            register={register}
          />
        ))}

        {fieldArray.fields.length > 0 && (
          <Button
            type="submit"
            variant="primary"
            loading={mutateStatus === "loading"}
          >
            Upload
          </Button>
        )}
      </Form>
      <div className="pt-5">
        <h5 className="mb-3">
          Conversation summary
        </h5>
        <FormGroup>
          <Form.Control
            as="textarea"
            placeholder="Input conversation summary"
            value={contactSummary}
            onChange={(event) => setContactSummary(event.target.value)}
          />
          {contactUpdateIsError && contactUpdateError?.response?.data?.contact_summary && (
            <FormControlError
              error={contactUpdateError?.response?.data?.contact_summary}
            />
          )}
        </FormGroup>
        <Button
          onClick={handleUpdateConversationSummary}
          loading={contactUpdateStatus === "loading"}
          size="sm"
        >
          Update summary
        </Button>
      </div>
    </div>
  );
};

export default MarketCampaignPropertyContactConversationHistory;

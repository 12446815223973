import React from "react";
import { useToastStore } from "src/store/toast";
import Button from "src/components/ui/form/button";
import Text from "src/components/ui/text";
import Icon from "src/components/ui/icons";

interface ClipboardCellProps {
  value: string;
  showExternalLink?: boolean;
}

const ClipboardCell: React.FC<ClipboardCellProps> = (
  {
    value,
    showExternalLink,
  },
) => {
  const { addToastSuccess, addToastDanger } = useToastStore();
  const [copyFailed, setCopyFailed] = React.useState<boolean>(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(value).then(() => {
      addToastSuccess({
        title: "Value is copied to clipboard.",
        body: value,
      });
    }).catch(() => {
      addToastDanger({
        title: "Failed to copy the value to clipboard.",
        body: value,
      });
      setCopyFailed(true);
    });
  };

  if (copyFailed) {
    return (
      <span className="fs-7">
        {value}
      </span>
    );
  }

  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        className="py-1 px-2"
        onClick={handleClick}
      >
        Copy
      </Button>
      {showExternalLink && (
        <Text.ExternalLink href={value} className="ms-2" blank>
          <Icon name="exit" variant="muted" />
        </Text.ExternalLink>
      )}
    </>
  );
};

export default ClipboardCell;

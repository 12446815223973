import React from "react";
import { useAuthStore } from "src/store/account";
import { useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

export const useLogout = (): () => void => {
  const { unAuthenticate } = useAuthStore();
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    unAuthenticate();
    queryClient.cancelQueries().finally();
    queryClient.invalidateQueries().finally();
    queryClient.getQueryCache().clear();
    Sentry.setUser(null);
  }, [unAuthenticate, queryClient]);
};

import React from "react";
import ChipMenuItem, { ChipMenuItemProps } from "src/components/ui/menu/ChipMenuItem";
import { useThemedStyles } from "src/hooks/styles";

import styles, { IStyles } from "src/components/ui/menu/ChipMenu.styles";

interface ChipMenuProps {
  items: Pick<ChipMenuItemProps, "to" | "title">[];
  style?: React.CSSProperties;
  level?: 1 | 2 | 3 | 4 | 5;
  vertical?: boolean;
}

const ChipMenu: React.FC<ChipMenuProps> = (
  {
    items,
    style = {},
    level = 2,
    vertical,
  },
) => {
  const themedStyles = useThemedStyles<IStyles>(styles);

  const navStyle = React.useMemo(() => {
    if (vertical) {
      return {
        ...themedStyles.nav,
        ...themedStyles.navVertical,
        ...style,
      };
    }

    return { ...themedStyles.nav, ...style };
  }, [vertical, style]);

  return (
    <nav style={navStyle}>
      {items.map((item) => (
        <ChipMenuItem
          level={level}
          key={item.to}
          style={themedStyles.button}
          to={item.to}
          title={item.title}
        />
      ))}
    </nav>
  );
};

export default ChipMenu;

import React from "react";
import Button, { ButtonProps } from "src/components/ui/form/button";
import Icon, { IconName } from "src/components/ui/icons";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/esm/Modal";
import { useBooleanState } from "src/hooks/boolean";
import Text from "src/components/ui/text";

interface BookCallProps {
  buttonText?: React.ReactNode;
  buttonIconName?: IconName;
  buttonVariant?: ButtonProps["variant"];
  className?: string;
}

const BookCall: React.FC<BookCallProps> = (
  {
    className,
    buttonText,
    buttonIconName,
    buttonVariant = "primary",
  },
) => {
  const { t } = useTranslation();
  const {
    state: show,
    setTrue: showBookCall,
    setFalse: hideBookCall,
  } = useBooleanState(false);

  const buttonIcon = React.useMemo(() => {
    if (buttonIconName) {
      return <Icon name={buttonIconName} variant="inherit" size={24} />;
    }
    return null;
  }, [buttonIconName]);
  const text = React.useMemo(() => {
    if (buttonText) {
      return buttonText;
    }
    return t("Book a call");
  }, [buttonText]);

  return (
    <>
      <Text.ExternalLink blank href="https://demo1.airvana.co/survey1">
        <Button
          className={className}
          variant={buttonVariant}
          icon={buttonIcon}
        >
          {text}
        </Button>
      </Text.ExternalLink>
      <Modal show={show} onHide={hideBookCall} size="lg">
        <Modal.Header closeButton onHide={hideBookCall}>
          <Modal.Title>{t("Book a call")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="Book a 30 min call"
            src="https://meet.brevo.com/airvanademo/30-minute-meeting"
            style={{
              width: "100%",
              height: 600,
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookCall;

import React from "react";

const refreshPageKey = "kt-page-force-refreshed";

const lazyWithRetry = <T extends React.ComponentType<any>>(
  componentImport: any,
) => React.lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(refreshPageKey) || "false");

    try {
      const component = await componentImport();

      window.localStorage.setItem(refreshPageKey, "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(refreshPageKey, "true");
        return window.location.reload();
      }

      throw error;
    }
  });

export default lazyWithRetry;

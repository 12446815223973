import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "src/pages/Layout";
import UnauthorizedLayout from "src/pages/UnauthorizedLayout";
import { AnonymousRequiredWrapper, CommonWrapper, LoginRequiredWrapper } from "src/pages/wrappers";
import adminRoutes from "src/pages/admin/router";
import agentRoutes from "src/pages/agent/router";
import DemoWrapper from "src/pages/wrappers/DemoWrapper";
import { MembershipPermission } from "src/enums/permission";
import lazyWithRetry from "src/utils/lazy-with-retry";

const LoginPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LoginPage" */ "src/pages/login"),
);
const SocialCompletePage = lazyWithRetry(
  () => import(/* webpackChunkName: "SocialCompletePage" */ "src/pages/login/social-complete"),
);
const SignUpPage = lazyWithRetry(
  () => import(/* webpackChunkName: "SignUpPage" */ "src/pages/sign-up"),
);
const SignUpSuccessPage = lazyWithRetry(
  () => import(/* webpackChunkName: "SignUpSuccessPage" */ "src/pages/sign-up").then(
    (module) => ({ default: module.SignUpSuccessPage }),
  ),
);
const SignUpConfirmEmailPage = lazyWithRetry(
  () => import(/* webpackChunkName: "SignUpConfirmEmailPage" */ "src/pages/sign-up").then(
    (module) => ({ default: module.SignUpConfirmEmailPage }),
  ),
);
const ForgotPasswordPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ForgotPasswordPage" */ "src/pages/login/forgot-password"),
);
const ForgotPasswordSentPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ForgotPasswordSentPage" */ "src/pages/login/forgot-password-sent"),
);
const ResetPasswordPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ResetPasswordPage" */ "src/pages/login/reset-password"),
);
const ResetPasswordSuccessPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ResetPasswordSuccessPage" */ "src/pages/login/reset-password-success"),
);
const SettingsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "SettingsPage" */ "src/pages/settings"),
);
const PersonalInformationPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PersonalInformationPage" */ "src/pages/settings").then(
    (module) => ({ default: module.PersonalInformationPage }),
  ),
);
const UpdatePasswordPage = lazyWithRetry(
  () => import(/* webpackChunkName: "UpdatePasswordPage" */ "src/pages/settings").then(
    (module) => ({ default: module.UpdatePasswordPage }),
  ),
);
const ReferralProgramPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ReferralProgramPage" */ "src/pages/settings").then(
    (module) => ({ default: module.ReferralProgramPage }),
  ),
);
const BillingPage = lazyWithRetry(
  () => import(/* webpackChunkName: "BillingPage" */ "src/pages/settings").then(
    (module) => ({ default: module.BillingPage }),
  ),
);
const MembershipPage = lazyWithRetry(
  () => import(/* webpackChunkName: "MembershipPage" */ "src/pages/settings").then(
    (module) => ({ default: module.MembershipPage }),
  ),
);
const OrdersPage = lazyWithRetry(
  () => import(/* webpackChunkName: "MembershipPage" */ "src/pages/settings").then(
    (module) => ({ default: module.OrdersPage }),
  ),
);
const HomePage = lazyWithRetry(
  () => import(/* webpackChunkName: "HomePage" */ "src/pages/home/HomePage"),
);
const CompleteInvestorProfilePage = lazyWithRetry(
  () => import(/* webpackChunkName: "CompleteInvestorProfilePage" */ "src/pages/complete-investor-profile/CompleteInvestorProfilePage"),
);
const STRDatabasePage = lazyWithRetry(
  () => import(/* webpackChunkName: "STRDatabasePage" */ "src/pages/str-database"),
);
const STRDatabasePageDemo = lazyWithRetry(
  () => import(/* webpackChunkName: "STRDatabasePageDemo" */ "src/pages/str-database").then(
    (module) => ({ default: module.STRDatabasePageDemo }),
  ),
);
const DealsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "DealsPage" */ "src/pages/deals"),
);
const DealsPageDemo = lazyWithRetry(
  () => import(/* webpackChunkName: "DealsPageDemo" */ "src/pages/deals").then(
    (module) => ({ default: module.DealsPageDemo }),
  ),
);
const PropertyListPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PropertyListPage" */ "src/pages/property-list").then(
    (module) => ({ default: module.PropertyListPage }),
  ),
);
const VerifiedPropertyListPage = lazyWithRetry(
  () => import(/* webpackChunkName: "VerifiedPropertyListPage" */ "src/pages/property-list").then(
    (module) => ({ default: module.VerifiedPropertyListPage }),
  ),
);
const ArbitragePropertyListPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ArbitragePropertyListPage" */ "src/pages/property-list").then(
    (module) => ({ default: module.ArbitragePropertyListPage }),
  ),
);
const CoHostPropertyListPage = lazyWithRetry(
  () => import(/* webpackChunkName: "CoHostPropertyListPage" */ "src/pages/property-list").then(
    (module) => ({ default: module.CoHostPropertyListPage }),
  ),
);
const ApartmentListPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ApartmentListPage" */ "src/pages/property-list").then(
    (module) => ({ default: module.ApartmentListPage }),
  ),
);
const PropertyListPageDemo = lazyWithRetry(
  () => import(/* webpackChunkName: "PropertyListPageDemo" */ "src/pages/property-list").then(
    (module) => ({ default: module.PropertyListPageDemo }),
  ),
);
const PropertyDetailPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PropertyDetailPage" */ "src/pages/property-detail"),
);
const PropertyDetailRedirectPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PropertyDetailRedirectPage" */ "src/pages/property-detail").then(
    (module) => ({ default: module.PropertyDetailRedirectPage }),
  ),
);
const PublicPropertyDetailPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PublicPropertyDetailPage" */ "src/pages/public-property-detail"),
);
const PublicPropertyDealDetailPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PublicPropertyDealDetailPage" */ "src/pages/public-property-deal-detail"),
);
const SavedSearchPage = lazyWithRetry(
  () => import(/* webpackChunkName: "SavedSearchPage" */ "src/pages/saved-searches"),
);
const AirbnbEstimatorPage = lazyWithRetry(
  () => import(/* webpackChunkName: "AirbnbEstimatorPage" */ "src/pages/airbnb-estimator"),
);
const AirbnbEstimatorDetailPage = lazyWithRetry(
  () => import(/* webpackChunkName: "AirbnbEstimatorDetailPage" */ "src/pages/airbnb-estimator-detail"),
);
const SelectMembershipPage = lazyWithRetry(
  () => import(/* webpackChunkName: "SelectMembershipPage" */ "src/pages/membership/select"),
);
const OrderLocationPage = lazyWithRetry(
  () => import(/* webpackChunkName: "OrderLocationPage" */ "src/pages/order/location"),
);
const OrderCheckoutPage = lazyWithRetry(
  () => import(/* webpackChunkName: "OrderCheckoutPage" */ "src/pages/order/checkout"),
);
const OrderCheckoutCompletePage = lazyWithRetry(
  () => import(/* webpackChunkName: "OrderCheckoutCompletePage" */ "src/pages/order/complete"),
);
const LearningHubOldPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LearningHubOldPage" */ "src/pages/learning-hub").then((module) => ({
    default: module.LearningHubPage,
  })),
);
const LearningHubPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LearningHubPage" */ "src/pages/learning-hub"),
);
const CoursePage = lazyWithRetry(
  () => import(/* webpackChunkName: "CoursePage" */ "src/pages/course"),
);
const VendorsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "VendorsPage" */ "src/pages/vendors"),
);
const PartnersPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PartnersPage" */ "src/pages/partners"),
);
const PartnerDetailPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PartnerDetailPage" */ "src/pages/partner-detail"),
);
const VendorDetailPage = lazyWithRetry(
  () => import(/* webpackChunkName: "VendorDetailPage" */ "src/pages/vendor-detail"),
);
const CreateVendorPage = lazyWithRetry(
  () => import(/* webpackChunkName: "CreateVendorPage" */ "src/pages/create-vendor"),
);
const CreatePartnerPage = lazyWithRetry(
  () => import(/* webpackChunkName: "CreatePartnerPage" */ "src/pages/create-partner"),
);
const LandlordPropertyPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LandlordPropertyPage" */ "src/pages/landlords"),
);
const LandlordSubmitPropertyPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LandlordSubmitPropertyPage" */ "src/pages/landlords/submit-property"),
);
const LandlordUpdatePropertyPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LandlordUpdatePropertyPage" */ "src/pages/landlords/update-property/LandlordUpdatePropertyPage"),
);
const LandlordRemovePropertyPage = lazyWithRetry(
  () => import(/* webpackChunkName: "LandlordRemovePropertyPage" */ "src/pages/landlords/remove-property/LandlordRemovePropertyPage"),
);
const HelpPage = lazyWithRetry(
  () => import(/* webpackChunkName: "HelpPage" */ "src/pages/help"),
);
const HelpCategoryPage = lazyWithRetry(
  () => import(/* webpackChunkName: "HelpCategoryPage" */ "src/pages/help").then(
    (module) => ({ default: module.HelpCategoryPage }),
  ),
);

const MyPeoplePage = lazyWithRetry(
  () => import(/* webpackChunkName: "MyPeoplePage" */ "src/pages/my-people"),
);
const SavedVendorsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "SavedVendorsPage" */ "src/pages/my-people/vendors"),
);

const PurchaseOrderPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PurchaseOrderPage" */ "src/pages/purchase-order"),
);
const PurchaseOrderPaymentDetailsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PurchaseOrderPaymentDetailsPage" */ "src/pages/purchase-order/payment-details"),
);
const PurchaseOrderConfirmPage = lazyWithRetry(
  () => import(/* webpackChunkName: "PurchaseOrderConfirmPage" */ "src/pages/purchase-order/confirm"),
);

const NotFoundPage = lazyWithRetry(
  () => import(/* webpackChunkName: "NotFoundPage" */ "src/pages/NotFoundPage"),
);
const ErrorPage = lazyWithRetry(
  () => import(/* webpackChunkName: "ErrorPage" */ "src/pages/ErrorPage"),
);

const authRoutes = [
  {
    path: "/sign-up/",
    errorElement: <ErrorPage />,
    element: <UnauthorizedLayout />,
    children: [
      {
        index: true,
        element: <AnonymousRequiredWrapper><SignUpPage /></AnonymousRequiredWrapper>,
      },
      {
        path: "/sign-up/success/",
        element: <AnonymousRequiredWrapper><SignUpSuccessPage /></AnonymousRequiredWrapper>,
      },
      {
        path: "/sign-up/confirm-email/",
        element: <AnonymousRequiredWrapper><SignUpConfirmEmailPage /></AnonymousRequiredWrapper>,
      },
    ],
  },
  {
    path: "/verify-account-login/",
    errorElement: <ErrorPage />,
    element: <UnauthorizedLayout />,
    children: [
      {
        index: true,
        element: <CommonWrapper><SignUpSuccessPage onlyVerification /></CommonWrapper>,
      },
      {
        path: "confirm-email/",
        element: <CommonWrapper><SignUpConfirmEmailPage onlyVerification /></CommonWrapper>,
      },
    ],
  },
  {
    path: "/sign-up-for-free/",
    errorElement: <ErrorPage />,
    element: <UnauthorizedLayout />,
    children: [
      {
        index: true,
        element: <AnonymousRequiredWrapper><SignUpPage freeTrial /></AnonymousRequiredWrapper>,
      },
    ],
  },
  {
    path: "/login/",
    errorElement: <ErrorPage />,
    element: <UnauthorizedLayout />,
    children: [
      {
        index: true,
        element: <AnonymousRequiredWrapper><LoginPage /></AnonymousRequiredWrapper>,
      },
      {
        path: "forgot-password/",
        element: <AnonymousRequiredWrapper><ForgotPasswordPage /></AnonymousRequiredWrapper>,
      },
      {
        path: "social-complete/",
        element: <CommonWrapper><SocialCompletePage /></CommonWrapper>,
      },
      {
        path: "forgot-password/sent/",
        element: <AnonymousRequiredWrapper><ForgotPasswordSentPage /></AnonymousRequiredWrapper>,
      },
      {
        path: "reset-password/",
        element: <AnonymousRequiredWrapper><ResetPasswordPage /></AnonymousRequiredWrapper>,
      },
      {
        path: "reset-password/success/",
        element: <AnonymousRequiredWrapper><ResetPasswordSuccessPage /></AnonymousRequiredWrapper>,
      },
      {
        path: "create-investor-profile/",
        element: <LoginRequiredWrapper><CompleteInvestorProfilePage /></LoginRequiredWrapper>,
      },
    ],
  },
];

const router = createBrowserRouter(
  [
    ...authRoutes,
    {
      path: "/landlords/",
      element: <UnauthorizedLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <CommonWrapper><LandlordPropertyPage /></CommonWrapper>,
        },
        {
          path: "submit/",
          element: <CommonWrapper><LandlordSubmitPropertyPage /></CommonWrapper>,
        },
        {
          path: "verify-ownership/",
          element: <CommonWrapper><LandlordUpdatePropertyPage /></CommonWrapper>,
        },
        {
          path: "remove-property/",
          element: <CommonWrapper><LandlordRemovePropertyPage /></CommonWrapper>,
        },
      ],
    },
    {
      path: "/add-vendor/",
      element: <UnauthorizedLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <CommonWrapper><CreateVendorPage /></CommonWrapper>,
        },
      ],
    },
    {
      path: "/add-partner/",
      element: <UnauthorizedLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <CommonWrapper><CreatePartnerPage /></CommonWrapper>,
        },
      ],
    },
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <CommonWrapper><HomePage /></CommonWrapper>,
        },
        {
          path: "properties/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_PROPERTY_FEED}
                  demoComponent={<PropertyListPageDemo verified={false} />}
                >
                  <PropertyListPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_PROPERTY_FEED}
                  demoComponent={<PropertyListPageDemo verified={false} />}
                >
                  <PropertyListPage />
                </DemoWrapper>
              ),
            },
            {
              path: "detail/:id/",
              element: <LoginRequiredWrapper><PropertyDetailRedirectPage /></LoginRequiredWrapper>,
            },
          ],
        },
        {
          path: "property-details/",
          children: [
            {
              path: ":id/",
              element: <LoginRequiredWrapper><PropertyDetailPage /></LoginRequiredWrapper>,
            },
            {
              path: "shared/:code/",
              element: <CommonWrapper><PublicPropertyDetailPage /></CommonWrapper>,
            },
            {
              path: "deals/:id/",
              element: <CommonWrapper><PublicPropertyDealDetailPage /></CommonWrapper>,
            },
          ],
        },
        {
          path: "verified-properties/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_VERIFIED_PROPERTY_FEED}
                  demoComponent={<PropertyListPageDemo verified />}
                >
                  <VerifiedPropertyListPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_VERIFIED_PROPERTY_FEED}
                  demoComponent={<PropertyListPageDemo verified />}
                >
                  <VerifiedPropertyListPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "apartments/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_VERIFIED_APARTMENTS_FEED}
                  demoComponent={<PropertyListPageDemo title="Viewing apartments" verified />}
                >
                  <ApartmentListPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_VERIFIED_APARTMENTS_FEED}
                  demoComponent={<PropertyListPageDemo title="Viewing apartments" verified />}
                >
                  <ApartmentListPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "arbitrage-properties/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_PROPERTY_ARBITRAGE_LIST}
                  demoComponent={<PropertyListPageDemo title="Viewing arbitrage properties" verified />}
                >
                  <ArbitragePropertyListPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_PROPERTY_ARBITRAGE_LIST}
                  demoComponent={<PropertyListPageDemo title="Viewing arbitrage properties" verified />}
                >
                  <ArbitragePropertyListPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "co-host-properties/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_PROPERTY_CO_HOST_LIST}
                  demoComponent={<PropertyListPageDemo title="Viewing co-host properties" verified />}
                >
                  <CoHostPropertyListPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_PROPERTY_CO_HOST_LIST}
                  demoComponent={<PropertyListPageDemo title="Viewing co-host properties" verified />}
                >
                  <CoHostPropertyListPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "vendors/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper permission={MembershipPermission.ACCESS_VENDORS_PAGE}>
                  <VendorsPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper permission={MembershipPermission.ACCESS_VENDORS_PAGE}>
                  <VendorsPage />
                </DemoWrapper>
              ),
            },
            {
              path: "detail/:id/",
              element: (
                <DemoWrapper permission={MembershipPermission.ACCESS_VENDORS_PAGE}>
                  <VendorDetailPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "partners/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper permission={MembershipPermission.ACCESS_PARTNER_PAGE}>
                  <PartnersPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper permission={MembershipPermission.ACCESS_PARTNER_PAGE}>
                  <PartnersPage />
                </DemoWrapper>
              ),
            },
            {
              path: "detail/:id/",
              element: (
                <DemoWrapper permission={MembershipPermission.ACCESS_PARTNER_PAGE}>
                  <PartnerDetailPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "arbitrage-calculator/",
          children: [
            {
              index: true,
              element: <LoginRequiredWrapper><AirbnbEstimatorPage /></LoginRequiredWrapper>,
            },
            {
              path: "detail/:id--:placeId/",
              element: <LoginRequiredWrapper><AirbnbEstimatorDetailPage /></LoginRequiredWrapper>,
            },
          ],
        },
        {
          path: "str-database/",
          element: (
            <DemoWrapper
              permission={[MembershipPermission.ACCESS_STR_DATABASE, MembershipPermission.ACCESS_STR_DATABASE_STATE]}
              demoComponent={<STRDatabasePageDemo />}
            >
              <STRDatabasePage />
            </DemoWrapper>
          ),
        },
        {
          path: "deals/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_DEAL_ALERTS}
                  demoComponent={<DealsPageDemo />}
                >
                  <DealsPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: <LoginRequiredWrapper><DealsPage /></LoginRequiredWrapper>,
            },
          ],
        },
        {
          path: "saved-search/",
          children: [
            {
              index: true,
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_SAVED_SEARCH}
                  demoComponent={<SavedSearchPage />}
                >
                  <SavedSearchPage />
                </DemoWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_SAVED_SEARCH}
                  demoComponent={<SavedSearchPage />}
                >
                  <SavedSearchPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "/settings/",
          element: <LoginRequiredWrapper><SettingsPage /></LoginRequiredWrapper>,
          children: [
            {
              index: true,
              element: <Navigate to="/settings/profile/" />,
            },
            {
              path: "profile/",
              element: <LoginRequiredWrapper><PersonalInformationPage /></LoginRequiredWrapper>,
            },
            {
              path: "referrals/",
              element: <LoginRequiredWrapper><ReferralProgramPage /></LoginRequiredWrapper>,
            },
            {
              path: "referrals/:page/",
              element: <LoginRequiredWrapper><ReferralProgramPage /></LoginRequiredWrapper>,
            },
            {
              path: "password/",
              element: <LoginRequiredWrapper><UpdatePasswordPage /></LoginRequiredWrapper>,
            },
            {
              path: "billing/",
              element: <LoginRequiredWrapper><BillingPage /></LoginRequiredWrapper>,
            },
            {
              path: "membership/",
              element: <LoginRequiredWrapper><MembershipPage /></LoginRequiredWrapper>,
            },
            {
              path: "membership/:page/",
              element: <LoginRequiredWrapper><MembershipPage /></LoginRequiredWrapper>,
            },
            {
              path: "orders/",
              element: <LoginRequiredWrapper><OrdersPage /></LoginRequiredWrapper>,
            },
            {
              path: "orders/:page/",
              element: <LoginRequiredWrapper><OrdersPage /></LoginRequiredWrapper>,
            },
          ],
        },
        {
          path: "/purchase-order/:id/",
          element: <LoginRequiredWrapper><PurchaseOrderPage /></LoginRequiredWrapper>,
          children: [
            {
              index: true,
              element: <PurchaseOrderPaymentDetailsPage />,
            },
            {
              path: "confirm/",
              element: <PurchaseOrderConfirmPage />,
            },
          ],
        },
        {
          path: "/my-people/",
          element: <LoginRequiredWrapper><MyPeoplePage /></LoginRequiredWrapper>,
          children: [
            {
              index: true,
              element: <Navigate to="/my-people/vendors/" />,
            },
            {
              path: "vendors/",
              children: [
                {
                  index: true,
                  element: <LoginRequiredWrapper><SavedVendorsPage /></LoginRequiredWrapper>,
                },
                {
                  path: ":page/",
                  element: <LoginRequiredWrapper><SavedVendorsPage /></LoginRequiredWrapper>,
                },
              ],
            },
          ],
        },
        {
          path: "help/",
          element: (
            <DemoWrapper
              permission={MembershipPermission.ACCESS_HELP}
            >
              <HelpPage />
            </DemoWrapper>
          ),
          children: [
            {
              path: ":categoryId/",
              element: (
                <DemoWrapper
                  permission={MembershipPermission.ACCESS_HELP}
                >
                  <HelpCategoryPage />
                </DemoWrapper>
              ),
            },
          ],
        },
        {
          path: "learning-hub/",
          element: <CommonWrapper><LearningHubPage /></CommonWrapper>,
        },
        {
          path: "page-not-found/",
          element: <CommonWrapper><NotFoundPage /></CommonWrapper>,
        },
        {
          path: "courses/:path--:id/",
          element: <CommonWrapper><CoursePage /></CommonWrapper>,
        },
        {
          path: "*",
          element: <CommonWrapper><NotFoundPage /></CommonWrapper>,
        },
      ],
    },
    {
      path: "/order/",
      element: <UnauthorizedLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "checkout/complete/",
          element: <LoginRequiredWrapper><OrderCheckoutCompletePage /></LoginRequiredWrapper>,
        },
        {
          path: "checkout/:id/complete/",
          element: <LoginRequiredWrapper><OrderCheckoutCompletePage /></LoginRequiredWrapper>,
        },
        {
          path: "checkout/:id/location/",
          element: <LoginRequiredWrapper><OrderLocationPage /></LoginRequiredWrapper>,
        },
        {
          path: "checkout/:id/payment-information/",
          element: <LoginRequiredWrapper><OrderCheckoutPage /></LoginRequiredWrapper>,
        },
      ],
    },
    {
      path: "/membership/",
      errorElement: <ErrorPage />,
      element: <UnauthorizedLayout />,
      children: [
        {
          path: "select/",
          element: <LoginRequiredWrapper><SelectMembershipPage /></LoginRequiredWrapper>,
        },
      ],
    },
    {
      path: "page-not-found/",
      element: <CommonWrapper><NotFoundPage /></CommonWrapper>,
    },
    {
      path: "*",
      element: <CommonWrapper><NotFoundPage /></CommonWrapper>,
    },
    adminRoutes,
    agentRoutes,
  ],
);

export default router;

import { useLocation } from "react-router-dom";
import React from "react";

export const useMenuActive = (to?: string | string[], level: 1 | 2 | 3 | 4 | 5 = 1): boolean => {
  const location = useLocation();

  const checkIfActive = (path: string): boolean => {
    if (!to) {
      return false;
    }

    const pathParts = path.replace(/^\//, "").replace(/\/$/, "").split("/");

    if (Array.isArray(to)) {
      return to.some((t) => (
        pathParts.includes(t.replace(/^\//, "").replace(/\/$/, ""))
      ));
    }

    const toParts = to.replace(/^\//, "").replace(/\/$/, "").split("/");

    return to === path || toParts[level - 1] === pathParts[level - 1];
  };

  const [isActive, setIsActive] = React.useState(() => checkIfActive(location.pathname));

  React.useEffect(() => {
    setIsActive(checkIfActive(location.pathname));
  }, [location.pathname, level, to]);

  return isActive;
};

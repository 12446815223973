/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "grid" |
    "gridNoSidebar" |
    "sidebar" |
    "sidebarToggle" |
    "sidebarToggleClosed" |
    "sticky"
]>;

const styles: IStyles = {
  grid: (theme) => css({
    display: "grid",
    grid: "auto-flow / 300px 1fr",
    rowGap: theme.spaces.md,
    columnGap: theme.spaces.lg,
  }),
  gridNoSidebar: (theme) => css({
    grid: "auto-flow / 1px 1fr",
  }),
  sidebar: (theme) => css({
    position: "relative",
  }),
  sidebarToggle: (theme) => css({
    border: `1px solid ${theme.colorOutlineLight}`,
    borderRadius: "50%",
    width: 32,
    height: 32,
    position: "absolute",
    top: -16,
    left: -16,
    paddingLeft: "11px !important",
    paddingRight: "11px !important",
    background: theme.colorWhite,
    zIndex: 2,
  }),
  sidebarToggleClosed: (theme) => css({
    svg: {
      transform: "rotate(180deg)",
    },
  }),
  sticky: (theme) => css({
    position: "sticky",
    top: theme.spaces.lg,
  }),
};

export default styles;

/** @jsxImportSource @emotion/react */
import React from "react";
import Icon, { IconName } from "src/components/ui/icons";
import Text from "src/components/ui/text";
import { useTheme, useThemedCss } from "src/hooks/styles";
import { useMenuActive } from "src/hooks/menu";
import styles, { IStyles } from "src/components/ui/menu/MenuItem.styles";
import { useTutorialStore } from "src/store/tutorial";

interface MenuItemProps {
  id?: string;
  onClick?: () => void;
  to?: string;
  title: string;
  iconName: IconName;
  isOpen: boolean;
  className?: string;
  menuLevel?: 1 | 2 | 3 | 4;
}

const MenuItem: React.FC<MenuItemProps> = (
  {
    id,
    className,
    onClick,
    to,
    title,
    iconName,
    isOpen,
    menuLevel = 1,
  },
) => {
  const isActive = useMenuActive(to, menuLevel);

  const { menu: { iconSize } } = useTheme();
  const themedCss = useThemedCss<IStyles>(styles);

  const { setReadyComponents } = useTutorialStore();

  React.useEffect(() => {
    if (id) {
      setReadyComponents([`#${id}`]);
    }
  }, [id]);

  const themedLinkCss = React.useMemo(() => {
    const css = [themedCss.link];
    if (isActive) {
      css.push(themedCss.linkActive);
    }
    if (isOpen) {
      css.push(themedCss.linkOpen);
    } else {
      css.push(themedCss.linkClosed);
    }
    return css;
  }, [isActive, isOpen]);

  const linkContent = (
    <>
      <Icon
        id={id}
        name={iconName}
        variant="inherit"
        css={isOpen ? themedCss.icon : undefined}
        size={iconSize}
        style={{
          minWidth: iconSize,
          maxWidth: iconSize,
        }}
      />
      {isOpen && <Text inherit css={themedCss.text}>{title}</Text>}
    </>
  );

  if (onClick) {
    return (
      <Text
        id={id}
        onClick={onClick}
        css={themedLinkCss}
        className={className}
        title={title}
      >
        {linkContent}
      </Text>
    );
  }

  if (!to) {
    return null;
  }

  return (
    <Text.Link
      id={id}
      to={to}
      css={themedLinkCss}
      className={className}
      title={title}
    >
      {linkContent}
    </Text.Link>
  );
};

export default MenuItem;

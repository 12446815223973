/** @jsxImportSource @emotion/react */
import React from "react";
import { useToastStore } from "src/store/toast";
import ToastContainer from "react-bootstrap/esm/ToastContainer";
import Toast from "src/components/toast/Toast";
import { useThemedCss } from "src/hooks/styles";
import styles, { IStyles } from "src/components/toast/Toasts.style";

const Toasts: React.FC = () => {
  const { toasts } = useToastStore();
  const themedCss = useThemedCss<IStyles>(styles);
  const toastsToShow = React.useMemo(() => {
    if (toasts.length <= 3) {
      return toasts;
    }
    return toasts.slice(toasts.length - 3);
  }, [toasts]);
  return (
    <ToastContainer css={themedCss.toastContainer}>
      {toastsToShow.map((toast) => (
        <Toast css={themedCss.toast} key={toast.id} toast={toast} />
      ))}
    </ToastContainer>
  );
};

export default Toasts;

import React from "react";
import { useRouterParamId } from "src/hooks/router";
import { useLimitOffsetPaginationParams } from "src/hooks/pagination";
import { useAgentMarketCampaignDetailRequest } from "src/hooks/api/agent/market-campaign";
import PropertyTablePage from "src/pages/admin/properties/table-page";
import Text from "src/components/ui/text";

interface MarketCampaignWorkRentMarketPageProps {
}

const MarketCampaignWorkRentMarketPage: React.FC<MarketCampaignWorkRentMarketPageProps> = () => {
  const id = useRouterParamId("id");
  const {
    data: marketCampaignData,
  } = useAgentMarketCampaignDetailRequest(id);

  const tableFilter: Partial<AdminAPI.Property.PropertyTableFilter> | null = React.useMemo(() => {
    if (!marketCampaignData?.data) {
      return null;
    }

    return {
      location: marketCampaignData.data.parser_bounds || undefined,
      is_tracers_succeeded: true,
    };
  }, [marketCampaignData]);

  if (!marketCampaignData?.data?.parser_bounds) {
    return null;
  }

  if (!tableFilter?.location) {
    return (
      <h3 className="text-danger">
        No parser executions found for the market campaign. Please create parser tasks first.
      </h3>
    );
  }

  return (
    <>
      <Text className="mb-3" alignment="start" color="danger" fontSize="8">
        Property list is filtered according to the scraper bounds. If you are using the state or address filter - make
        sure it is within the bounds.
      </Text>
      <PropertyTablePage
        baseUrl={`/agent/market-campaigns/detail/${id}/contacts/`}
        pageTitle="Potential contacts"
        overrideFilter={tableFilter}
        allowExport
      />
    </>
  );
};

export default MarketCampaignWorkRentMarketPage;

import create from "zustand";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";

export interface IResponsiveStore {
  breakpoint: Breakpoint;
  windowWidth: number;
  setWindowWidth: (width: number) => void;
}

const breakpoints: Record<Breakpoint, number> = {
  xxxl: 1920,
  xxl: 1600,
  xl: 1460,
  lg: 1280,
  md: 860,
  sm: 576,
  xs: 0,
};

const getBreakpoint = (width: number): Breakpoint => {
  const current = Object.entries(breakpoints).find(([key, value]) => width > value);
  if (current) {
    return current[0] as Breakpoint;
  }
  return "xs";
};

export const useResponsiveStore = create<IResponsiveStore>()(
  (set, get) => ({
    breakpoint: getBreakpoint(window.innerWidth),
    windowWidth: window.innerWidth,
    setWindowWidth: (width) => set({ breakpoint: getBreakpoint(width), windowWidth: width }),
  }),
);

type InputValue = number | string | null;

export const formatNumber = (value?: InputValue, decimalPlaces: number = 0): string => {
  if (value === undefined || value === null) {
    return "-";
  }

  const numberValue = parseFloat(value.toString());

  if (Number.isNaN(numberValue)) {
    return "-";
  }

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(numberValue);
};

export const formatPrice = (value?: InputValue, decimalPlaces: number = 0, showZeros: boolean = false): string => {
  if (value === undefined || value === null) {
    return "-";
  }

  const numberValue = parseFloat(value.toString());

  if (Number.isNaN(numberValue)) {
    return "";
  }

  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numberValue);

  if (decimalPlaces === 0 || (!showZeros && formatted.endsWith(".00"))) {
    return formatted.slice(0, -3);
  }
  return formatted;
};

export const formatPercent = (value?: InputValue, decimalPlaces?: number): string => (
  `${formatNumber(value, decimalPlaces)}%`
);

import React from "react";
import UIFormControl, { FormControlProps as UIFormControlProps } from "react-bootstrap/esm/FormControl";
import Icon from "src/components/ui/icons";
import { useTranslation } from "react-i18next";

type FormControlProps = UIFormControlProps & {
  autoComplete?: string;
};

const forwardedFormControlPassword = (
  {
    type,
    placeholder,
    ...props
  }: FormControlProps,
  ref: React.ForwardedRef<typeof UIFormControl>,
) => {
  const { t } = useTranslation();
  const [controlType, setControlType] = React.useState<"text" | "password">("password");
  const handleIconClick = () => {
    if (controlType === "text") {
      setControlType("password");
    } else {
      setControlType("text");
    }
  };

  const newPlaceholder = React.useMemo(() => {
    if (controlType === "text") {
      return t("Type your password").toString();
    }
    return "••••••••••••••••";
  }, [controlType]);
  return (
    <div className="form-control-with-icon form-control-with-icon--two-side">
      <Icon
        name="lock"
        className="form-control-with-icon__icon"
        size={20}
        variant="primary"
      />
      <UIFormControl
        ref={ref}
        placeholder={newPlaceholder}
        type={controlType}
        {...props}
      />
      <Icon
        name="eye"
        className="form-control-with-icon__icon form-control-with-icon__icon--right"
        variant="secondary"
        onClick={handleIconClick}
        size={20}
      />
    </div>
  );
};

export default React.forwardRef(forwardedFormControlPassword);

/* eslint-disable */
import React from "react";

const PropertyCardPlaceholder: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg width="719" height="598" viewBox="0 0 719 598" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{ ...(props.style || {}), filter: "grayscale(1)" }}>
    <path fillRule="evenodd" clipRule="evenodd" d="M247.481 390.248C248.016 397.583 249.099 398.599 256.093 399.166C249.099 399.728 248.016 400.74 247.481 408.079C246.949 400.74 245.863 399.728 238.869 399.166C245.863 398.599 246.949 397.583 247.481 390.248ZM224.451 445.998C224.979 453.24 226.049 454.241 232.954 454.795C226.049 455.35 224.979 456.351 224.451 463.596C223.923 456.351 222.856 455.35 215.949 454.795C222.856 454.241 223.923 453.24 224.451 445.998ZM277.038 410.216C278.028 423.78 280.029 425.654 292.963 426.697C280.029 427.745 278.028 429.614 277.038 443.178C276.049 429.614 274.047 427.745 261.113 426.697C274.047 425.654 276.049 423.78 277.038 410.216Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M506.334 361.49C505.927 367.107 505.095 367.879 499.741 368.309C505.095 368.743 505.927 369.515 506.334 375.132C506.742 369.515 507.57 368.743 512.928 368.309C507.57 367.879 506.742 367.107 506.334 361.49ZM523.963 404.164C523.559 409.711 522.744 410.476 517.454 410.906C522.744 411.333 523.559 412.097 523.963 417.643C524.37 412.097 525.19 411.333 530.476 410.906C525.19 410.476 524.37 409.711 523.963 404.164ZM483.706 376.773C482.945 387.156 481.411 388.595 471.511 389.394C481.411 390.193 482.945 391.624 483.706 402.016C484.462 391.624 485.996 390.193 495.9 389.394C485.996 388.595 484.462 387.156 483.706 376.773Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M522.787 480.466H353.382V135.967L522.787 200.099V480.466Z" fill="#6497D6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M522.781 256.576V261.45H426.31V480.457H421.44V340.564H353.378V335.691H421.44V161.732L426.31 163.573V256.576H522.781Z" fill="#7FAFE2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M236.65 488.94H222.131V364.051H236.65V488.94Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M353.386 480.465H254.693V364.051H353.386V480.465Z" fill="#E1BD9E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M353.378 364.051V480.457H254.697V364.051H353.378Z" fill="#CEE8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M353.378 364.051V480.457H323.782C322.552 471.505 320.465 461.075 317.043 449.38C309.432 423.346 277.566 406.031 254.693 396.703L254.697 364.051H353.378Z" fill="#AED1F0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M524.612 506.728H176.536L172.663 478.491H528.269L524.612 506.728Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M353.385 364.966H188.23L203.751 215.231H353.385V364.966Z" fill="#7FAFE2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M203.696 215.746L203.751 215.229H353.378L203.751 215.231L203.696 215.746Z" fill="#CFA88A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M353.386 364.966H317.567L331.862 248.338C320.143 226.7 252.268 223.978 218.776 223.978C211.562 223.978 205.944 224.105 202.824 224.195L203.696 215.745L203.751 215.231H353.386V364.966Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M353.386 364.966H185.067V356.879H353.386V364.966Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M421.445 463.026H382.147V460.256C382.147 460.182 382.147 460.112 382.147 460.043H421.445V463.026ZM497.815 463.026H426.315V460.043H485.05C485.05 458.898 484.765 457.824 484.257 456.881V351.603H426.315H491.801V455.799H492.317C495.358 455.799 497.815 457.797 497.815 460.256V463.026Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M426.311 463.026H421.442V460.043H426.311V463.026Z" fill="#7FAFE2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M484.252 456.882H384.084V351.605H484.252V456.882Z" fill="#AED1F0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M390.547 456.882V357.888H478.162V456.882H390.547Z" fill="#CEE8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M408.754 373.302H478.162V357.888H390.547V456.882H408.754V373.302Z" fill="#CEE8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M479.697 453.468V356.591H389.021V453.332H388.852C385.148 453.332 382.147 456.338 382.147 460.043H485.048C485.048 456.8 482.753 454.1 479.697 453.468ZM392.077 383.142H409.56V405.829H392.077V383.142ZM392.077 407.9H409.56V431.281H392.077V407.9ZM432.293 407.9V431.281H412.012V407.9H432.293ZM412.012 405.829V383.142H432.293V405.829H412.012ZM457.678 405.829H434.745V383.142H457.678V405.829ZM457.678 407.9V431.281H434.745V407.9H457.678ZM457.678 433.345V453.332H434.745V433.345H457.678ZM460.126 453.332V433.345H476.64V453.332H460.126ZM476.64 431.281H460.126V407.9H476.64V431.281ZM476.64 405.829H460.126V383.142H476.64V405.829ZM457.678 381.07H434.745V359.182H457.678V381.07ZM432.293 381.07H412.012V359.182H432.293V381.07ZM432.293 433.345V453.332H412.012V433.345H432.293ZM476.64 381.07H460.126V359.182H476.64V381.07ZM409.56 359.182V381.07H392.077V359.182H409.56ZM392.077 433.345H409.56V453.332H392.077V433.345Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M430.982 404.662H413.012V383.961H430.982V404.662Z" fill="#F3FBFD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M455.779 429.641H436.122V408.544H455.779V429.641Z" fill="#F3FBFD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M456.602 380.206H436.122V373.302H456.602V380.206Z" fill="#F3FBFD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M456.602 373.302H436.122V360.537H456.602V373.302Z" fill="#CEE8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M456.602 403.242H436.122V383.961H456.602V403.242Z" fill="#F3FBFD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M430.156 430.308H413.012V409.064H430.156V430.308Z" fill="#F3FBFD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M430.156 451.642H413.792V435.331H430.156V451.642Z" fill="#F3FBFD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M456.606 244.8H353.385V208.655H456.606V244.8Z" fill="#AED1F0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M451.302 240.333H353.385V212.719H451.302V240.333Z" fill="#F3FBFD" />
    <path fillRule="evenodd" clipRule="evenodd" d="M451.302 214.646H353.385V212.719H451.302V214.646Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M451.302 240.333H353.385V238.405H451.302V240.333Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M451.302 240.333H449.782V213.682H451.302V240.333Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M381.139 239.367H379.514V213.682H381.139V239.367Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M411.705 239.367H410.08V213.682H411.705V239.367Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M325.124 478.491H279.331V388.348H325.124V478.491Z" fill="#6D9EDA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M318.894 418.909H286.527V396.982H318.894V418.909ZM288.964 416.477H316.461V399.422H288.964V416.477Z" fill="#AED1F0" />
    <path fillRule="evenodd" clipRule="evenodd" d="M64.0258 458.185C63.9773 458.484 63.9722 458.775 63.9955 459.062C63.9858 458.364 64.139 457.677 64.4055 457.029C64.2255 457.398 64.0921 457.782 64.0258 458.185Z" fill="#F790AC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M31.3812 421.228C31.2644 420.968 31.181 420.794 31.181 420.794C31.2694 420.918 31.3342 421.065 31.3812 421.228Z" fill="#D44C23" />
    <path fillRule="evenodd" clipRule="evenodd" d="M47.6026 433.947C47.7194 433.947 47.814 433.854 47.814 433.733C47.814 433.621 47.7194 433.524 47.6026 433.524C47.4858 433.524 47.3912 433.621 47.3912 433.733C47.3912 433.854 47.4858 433.947 47.6026 433.947Z" fill="#CEE8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M47.5425 433.419C47.5743 433.419 47.601 433.392 47.601 433.361C47.601 433.33 47.5743 433.303 47.5425 433.303C47.3376 433.303 47.1712 433.47 47.1712 433.671C47.1712 433.706 47.198 433.733 47.2294 433.733C47.2612 433.733 47.288 433.706 47.288 433.671C47.288 433.532 47.4028 433.419 47.5425 433.419Z" fill="#CEE8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M42.8119 433.978C42.9484 433.978 43.0567 433.869 43.0567 433.733C43.0567 433.602 42.9484 433.489 42.8119 433.489C42.6769 433.489 42.5671 433.602 42.5671 433.733C42.5671 433.869 42.6769 433.978 42.8119 433.978Z" fill="#CEE8FB" />
    <path fillRule="evenodd" clipRule="evenodd" d="M42.7436 433.369C42.7801 433.369 42.8103 433.342 42.8103 433.303C42.8103 433.268 42.7801 433.233 42.7436 433.233C42.507 433.233 42.3138 433.427 42.3138 433.667C42.3138 433.698 42.3436 433.733 42.3805 433.733C42.417 433.733 42.4472 433.698 42.4472 433.667C42.4472 433.501 42.5803 433.369 42.7436 433.369Z" fill="#CEE8FB" />
  </svg>
);

export default PropertyCardPlaceholder;

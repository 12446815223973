import React from "react";
import { Badge, CloseButton } from "react-bootstrap";
import {
  usePropertyTableAdminUpdateRequest,
} from "src/hooks/api/admin/property";
import { useToastStore } from "src/store/toast";
import { AxiosResponse } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import Icon from "src/components/ui/icons";
import Dropdown from "react-bootstrap/esm/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "../../../../../components/ui/form/button";

interface KeywordCategoryBadgeProps {
  category: API.Property.PropertyKeywordCategory;
  onRemove: (category: API.Property.PropertyKeywordCategory) => void;
}

const KeywordCategoryBadge: React.FC<KeywordCategoryBadgeProps> = (
  {
    category,
    onRemove,
  },
) => {
  const handeRemove = () => {
    onRemove(category);
  };

  return (
    <Badge className="me-1">
      {category}
      <CloseButton
        variant="white"
        onClick={handeRemove}
      />
    </Badge>
  );
};

interface KeywordCategoryUpdateCellProps {
  property: AdminAPI.Property.PropertyTableData;
}

const keywordCategories: API.Property.PropertyKeywordCategory[] = [
  "arbitrage",
  "co_host",
];

const KeywordCategoryUpdateCell: React.FC<KeywordCategoryUpdateCellProps> = (
  {
    property,
  },
) => {
  const [prevState, setPrevState] = React.useState<API.Property.PropertyKeywordCategory[] | null>(() => property.keyword_category);

  const {
    addToastSuccessWithUndo,
  } = useToastStore();

  const queryClient = useQueryClient();

  const {
    mutate,
    data,
    isSuccess,
  } = usePropertyTableAdminUpdateRequest(property.id, {
    onSuccess: (newData) => {
      queryClient.setQueriesData(
        ["usePropertyTableAdminRequest"],
        (oldData: AxiosResponse<AdminAPI.Property.PropertyTable.Response> | undefined) => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              results: oldData.data.results.map(
                (prop) => (
                  prop.id === newData.data.id
                    ? ({
                      ...prop,
                      keyword_category: newData.data.keyword_category,
                    })
                    : prop
                ),
              ),
            },
          };
        },
      );

      addToastSuccessWithUndo({
        title: `Keyword category for ${property.id} updated`,
        body: `Property keyword category has been updated to [${(newData.data.keyword_category || []).join(", ")}]`,
      }, () => {
        mutate({
          keyword_category: prevState ?? [],
        });
      });
    },
  });

  const handleRemove = (category: API.Property.PropertyKeywordCategory) => {
    mutate({
      keyword_category: property.keyword_category!.filter((c) => c !== category),
    });
  };

  const categoriesToAdd = React.useMemo(() => (
    keywordCategories.filter((category) => !property.keyword_category?.includes(category))
  ), [property.keyword_category]);

  if (!property.keyword_category) {
    return (
      <span>N/A</span>
    );
  }

  return (
    <div className="av-table-ellipsis__cell--no-ellipsis">
      {property.keyword_category.map((category) => (
        <KeywordCategoryBadge
          key={category}
          category={category}
          onRemove={handleRemove}
        />
      ))}

      {categoriesToAdd.length > 0 && (
        <div className="pt-2">
          <Dropdown as={ButtonGroup} autoClose="outside" align="end">
            <Button variant="outline-dark" size="sm">
              Add category
            </Button>
            <Dropdown.Toggle size="sm" split variant="outline-dark" />
            <Dropdown.Menu>
              {categoriesToAdd.map((category) => (
                <Dropdown.Item
                  key={category}
                  onClick={() => {
                    mutate({
                      keyword_category: [...(property.keyword_category ?? []), category],
                    });
                  }}
                >
                  <Icon name="plus" size={20} />

                  {category}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default KeywordCategoryUpdateCell;

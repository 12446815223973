import React from "react";
import { CellContext } from "@tanstack/react-table";
import Text from "src/components/ui/text";
import Button from "src/components/ui/form/button";
import { usePropertyTableAdminUpdateRequest } from "src/hooks/api/admin/property";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useToastStore } from "src/store/toast";

interface ContactWithTracersCellProps {
  cell: CellContext<AdminAPI.Property.PropertyTableData, "description">;
  originalValue: React.ReactElement;
  tracerColumn: "name" | "email" | "phone";
}

interface CellValueProps {
  index: number;
  propertyId: number;
  tracerPerson: AdminAPI.Property.PropertyTableData["tracer_persons"][0];
  tracerColumn: "name" | "email" | "phone";
}

const colors = [
  "#37ff00",
  "#a86c00",
  "#d2d200",
  "#1d30ff",
  "#ff3dcf",
  "#3dffff",
  "#ff3d5a",
  "#3db5ff",
  "#3dff81",
  "#ff6200",
  "#a811ff",
  "#ff0080",
];

const CellValue: React.FC<CellValueProps> = (
  {
    index,
    propertyId,
    tracerPerson,
    tracerColumn,
  },
) => {
  const queryClient = useQueryClient();
  const {
    addToastDanger,
    addToastSuccess,
  } = useToastStore();
  const {
    mutate,
    error,
    isError,
  } = usePropertyTableAdminUpdateRequest(propertyId, {
    onSuccess: (data) => {
      if (data?.data) {
        addToastSuccess({
          title: `Property contact ${tracerColumn} updated to ${data.data.contact![tracerColumn]}`,
        });

        queryClient.setQueriesData<AxiosResponse<AdminAPI.Property.PropertyTable.Response>>(
          ["usePropertyTableAdminRequest"],
          (prev) => {
            if (!prev) {
              return prev;
            }

            return {
              ...prev,
              data: {
                ...prev.data,
                results: prev.data.results.map((property) => {
                  if (property.id.toString() === propertyId.toString()) {
                    return {
                      ...property,
                      contact: data.data.contact,
                    };
                  }
                  return property;
                }),
              },
            };
          },
        );
      }
    },
  });

  React.useEffect(() => {
    if (isError && error?.response?.data?.contact?.[tracerColumn]) {
      addToastDanger({
        title: error.response.data.contact[tracerColumn]!.join(" "),
      });
    }
  }, [isError, error]);

  const handleSetDefault = (value: string) => {
    mutate({
      contact: {
        [tracerColumn]: value,
      },
    });
  };
  const renderValues = React.useMemo(() => {
    let values: string[] = [];

    switch (tracerColumn) {
      case "name":
        values = [`${tracerPerson.first_name} ${tracerPerson.last_name}`.trim()];
        break;
      case "email":
        values = [tracerPerson.email.trim()];
        break;
      case "phone":
        values = [
          tracerPerson.phone_number_1.trim(),
          tracerPerson.phone_number_2.trim(),
          tracerPerson.phone_number_3.trim(),
        ];
        break;
      default:
        values = ["Error"];
        break;
    }

    return values.filter((value) => value.length > 0);
  }, [tracerPerson, tracerColumn]);

  return (
    <>
      {renderValues.map((value, idx) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <Text key={idx} alignment="start" className="mb-1" fontSize="8" style={{ color: colors[index] }}>
          {value}
          <Button
            size="sm"
            variant="secondary"
            className="ms-2 p-1 fs-8"
            onClick={() => handleSetDefault(value)}
          >
            Set as contact default
          </Button>
        </Text>
      ))}
    </>
  );
};

const ContactWithTracersCell: React.FC<ContactWithTracersCellProps> = (
  {
    cell,
    originalValue,
    tracerColumn,
  },
) => {
  if (cell.row.original.tracer_persons.length === 0) {
    return originalValue;
  }

  return (
    <div>
      {originalValue}
      <Text alignment="start" fontSize="7">
        T - Public records:
      </Text>
      {cell.row.original.tracer_persons.length > 0 && (
        cell.row.original.tracer_persons.map((tracerPerson, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <CellValue key={index} index={index} tracerPerson={tracerPerson} tracerColumn={tracerColumn} propertyId={cell.row.original.id} />
        ))
      )}
    </div>
  );
};

export default ContactWithTracersCell;

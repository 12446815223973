/** @jsxImportSource @emotion/react */
import React from "react";
import { useAuthStore } from "src/store/account";
import { useProfileStoreWithRefetch } from "src/hooks/profile";
import { useThemedCss } from "src/hooks/styles";
import Button from "src/components/ui/form/button";
import Text from "src/components/ui/text";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/esm/Dropdown";
import Icon from "src/components/ui/icons";
import styles, { IStyles } from "src/components/ui/menu/ProfileMenu.styles";
import { Link } from "react-router-dom";
import { useBreakpointIsLowerOrEqual } from "src/hooks/responsive";
import { useLogout } from "src/hooks/account";

const ProfileMenu: React.FC = () => {
  const { isAuthenticated } = useAuthStore();
  const { t } = useTranslation();
  const profile = useProfileStoreWithRefetch();
  const themedCss = useThemedCss<IStyles>(styles);
  const isMobile = useBreakpointIsLowerOrEqual("sm");

  const handleLogout = useLogout();
  const buttonSize = React.useMemo(() => {
    if (isMobile) {
      return "sm";
    }
    return undefined;
  }, [isMobile]);

  if (!isAuthenticated) {
    return (
      <div className="d-inline-flex flex-nowrap justify-content-end align-items-center">
        <Text.Link to="/login/">
          <Button
            variant="outline-primary"
            size={buttonSize}
            className="me-2 me-md-3"
          >
            {t("Sign In")}
          </Button>
        </Text.Link>
        <Text.Link to="/sign-up/">
          <Button
            size={buttonSize}
            variant="primary"
          >
            {t("Sign Up")}
          </Button>
        </Text.Link>
      </div>
    );
  }

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as="div">
        <div css={themedCss.button}>
          <span css={themedCss.name}>
            {profile.name}
          </span>
          <span css={themedCss.email}>
            {profile.email}
          </span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item css={themedCss.dropdownItem} as={Link} to="/saved-search/">
          <Icon css={themedCss.dropdownItemIcon} variant="inherit" name="property-search" size={16} />
          {t("Saved searches")}
        </Dropdown.Item>
        <Dropdown.Item css={themedCss.dropdownItem} as={Link} to="/my-people/">
          <Icon css={themedCss.dropdownItemIcon} variant="inherit" name="heart" size={16} />
          {t("My people")}
        </Dropdown.Item>
        <Dropdown.Item css={themedCss.dropdownItem} as={Link} to="/settings/billing/">
          <Icon css={themedCss.dropdownItemIcon} variant="inherit" name="wallet" size={16} />
          {t("Billing")}
        </Dropdown.Item>
        <Dropdown.Item css={themedCss.dropdownItem} as={Link} to="/settings/membership/">
          <Icon css={themedCss.dropdownItemIcon} variant="inherit" name="documents" size={16} />
          {t("Membership")}
        </Dropdown.Item>
        <Dropdown.Item css={themedCss.dropdownItem} as={Link} to="/settings/orders/">
          <Icon css={themedCss.dropdownItemIcon} variant="inherit" name="time-oclock" size={16} />
          {t("Orders")}
        </Dropdown.Item>
        <Dropdown.Item
          css={themedCss.dropdownItem}
          as="a"
          href="https://form.asana.com/?k=dt778Q_1GFSJeNPw7dr52g&d=1202851120158061"
          target="_blank"
        >
          {t("Submit an error")}
        </Dropdown.Item>
        <Dropdown.Item css={themedCss.dropdownItem} onClick={handleLogout}>
          <Icon css={themedCss.dropdownItemIcon} variant="inherit" name="exit" size={16} />
          {t("Log out")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;

/** @jsxImportSource @emotion/react */
import React from "react";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";
import Icon, { IconType } from "src/components/ui/icons";
import { css } from "@emotion/react";
import type { SerializedStyles } from "@emotion/react/dist/emotion-react.cjs";

interface TooltipIconProps {
  text?: React.ReactNode;
  iconType?: IconType;
  customCss?: SerializedStyles;
  className?: string;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  placement?: "auto-start" | "auto" | "auto-end" | "top-start" | "top" | "top-end" | "right-start" | "right" |
    "right-end" | "bottom-end" | "bottom" | "bottom-start" | "left-end" | "left" | "left-start";
  onClick?: React.MouseEventHandler;
}

const iconCss = css({
  lineHeight: 0,
  display: "inline-block",
  marginLeft: 4,
});

const TooltipIcon: React.FC<TooltipIconProps> = (
  {
    text,
    customCss,
    icon,
    placement = "auto",
    iconType = "inherit",
    ...props
  },
) => {
  if (!text) {
    return null;
  }

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 120, hide: 800 }}
      defaultShow={false}
      trigger={["hover", "focus"]}
      overlay={(
        <Tooltip>
          {text}
        </Tooltip>
      )}
    >
      <span css={[iconCss, customCss]} {...props}>
        {icon || <Icon name="hint" size={16} variant={iconType} />}
      </span>
    </OverlayTrigger>
  );
};

export default TooltipIcon;

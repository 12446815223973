/** @jsxImportSource @emotion/react */
import React from "react";
import { formatDateTime } from "src/utils/formatters/date-time";

interface AsDateTimeProps {
  value?: string;
  style?: React.CSSProperties;
  stringFormat?: string;
  className?: string;
}

const AsDateTime: React.FC<AsDateTimeProps> = (
  {
    value,
    stringFormat,
    ...props
  },
) => {
  const formattedValue: string = React.useMemo(() => {
    if (!value) {
      return "";
    }

    return formatDateTime(value, stringFormat);
  }, [value, stringFormat]);

  return (
    <span {...props}>
      {formattedValue}
    </span>
  );
};

export default AsDateTime;

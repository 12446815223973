import React from "react";
import type { RouteObject } from "react-router-dom";
import AgentRequiredWrapper from "src/pages/agent/AgentRequiredWrapper";
import STRVerificationPage from "src/pages/agent/str-verification/STRVerificationPage";
import { MembershipPermission } from "src/enums/permission";
import lazyWithRetry from "src/utils/lazy-with-retry";

const STRVerificationPerplexityPage = lazyWithRetry(
  () => import("src/pages/agent/str-verification/perplexity/STRVerificationPerplexityPage"),
);

const STRDatabaseAgentListPage = lazyWithRetry(
  () => import("src/pages/agent/str-verification/database/STRDatabaseAgentListPage"),
);

const STRDatabaseStateAgentListPage = lazyWithRetry(
  () => import("src/pages/agent/str-verification/database/STRDatabaseStateAgentListPage"),
);

const strVerificationRouter: RouteObject[] = [
  {
    path: "str/",
    element: <AgentRequiredWrapper><STRVerificationPage /></AgentRequiredWrapper>,
    children: [
      {
        path: "verification/",
        children: [
          {
            index: true,
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION}>
                <STRVerificationPerplexityPage />
              </AgentRequiredWrapper>
            ),
          },
          {
            path: ":page/",
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION}>
                <STRVerificationPerplexityPage />
              </AgentRequiredWrapper>
            ),
          },
        ],
      },
      {
        path: "database/",
        children: [
          {
            index: true,
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_MODIFICATION}>
                <STRDatabaseAgentListPage />
              </AgentRequiredWrapper>
            ),
          },
          {
            path: ":page/",
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_MODIFICATION}>
                <STRDatabaseAgentListPage />
              </AgentRequiredWrapper>
            ),
          },
        ],
      },
      {
        path: "state-database/",
        children: [
          {
            index: true,
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_MODIFICATION}>
                <STRDatabaseStateAgentListPage />
              </AgentRequiredWrapper>
            ),
          },
          {
            path: ":page/",
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_MODIFICATION}>
                <STRDatabaseStateAgentListPage />
              </AgentRequiredWrapper>
            ),
          },
        ],
      },
    ],
  },
];

export default strVerificationRouter;

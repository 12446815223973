import React from "react";
import { Outlet } from "react-router-dom";
import { useMetaTitleSetter } from "src/hooks/meta";
import { ChipMenu } from "src/components/ui/menu";
import { Helmet } from "react-helmet";

const AdminWrapper: React.FC = () => {
  const title = "Admin Panel";
  useMetaTitleSetter(title);

  return (
    <section>
      <ChipMenu
        level={2}
        style={{ marginBottom: 24 }}
        items={[
          { to: "/admin/", title: "Dashboard" },
          { to: "/admin/scrapers/", title: "Scrapers" },
          { to: "/admin/schedulers/", title: "Schedulers" },
          { to: "/admin/memberships/", title: "Memberships" },
          { to: "/admin/properties/", title: "Properties" },
          { to: "/admin/agents/", title: "Agents" },
        ]}
      />

      <Outlet />

      <Helmet>
        <title>{title}</title>
      </Helmet>
    </section>
  );
};

export default AdminWrapper;

import create from "zustand";
import { persist } from "zustand/middleware";

export interface IRedirectContextStore<Context = Object> {
  context: Context | null;
  setContext: (context: Object) => void;
  clearContext: () => void;
}

type IUseRedirectContextStore = <Context = Object>() => IRedirectContextStore<Context>;

export const useRedirectContextStore: IUseRedirectContextStore = create<IRedirectContextStore>()(
  (set, get) => ({
    context: null,
    setContext: (context) => set({ context }),
    clearContext: () => set({ context: null }),
  }),
);

export interface INextPathStore {
  nextPath: string | null;
  setNextPath: (nextPath: string) => void;
  clearNextPath: () => void;
}

export const useNextPathStore = create<INextPathStore>()(
  persist(
    (set, get) => ({
      nextPath: null,
      setNextPath: (nextPath) => set({ nextPath }),
      clearNextPath: () => set({ nextPath: null }),
    }),
    {
      name: "nextPathStore",
    },
  ),
);

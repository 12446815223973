/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "section" |
    "form" |
    "formFooter" |
    "checkboxButtonGroup" |
    "checkboxButton" |
    "checkboxButtonInput" |
    "checkboxButtonLabel"
]>;

const styles: IStyles = {
  section: (theme) => css({
    paddingBottom: theme.spaces.xl,
  }),
  form: (theme) => css({
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
  }),
  formFooter: (theme) => css({
    paddingTop: theme.spaces.lg,
  }),
  checkboxButtonGroup: (theme) => css({
    display: "grid",
    grid: "auto-flow/repeat(2, 1fr)",
    columnGap: theme.spaces.xs,
    rowGap: theme.spaces.xs,
  }),
  checkboxButton: (theme) => css({
    position: "relative",
  }),
  checkboxButtonInput: (theme) => css({
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    "&:checked + label": {
      background: theme.colorPrimary,
      color: theme.colorWhite,
      borderColor: theme.colorPrimary,
    },
  }),
  checkboxButtonLabel: (theme) => css({
    cursor: "pointer",
    border: `1px solid ${theme.colorOutlineLight}`,
    borderRadius: theme.borderRadius,
    outline: "none",
    display: "block",
    textAlign: "center",
    padding: `${theme.spaces.xs}px ${theme.spaces.sm}px`,
    color: theme.colorTextSecondary,
    fontWeight: theme.fontWeight.semiBold,
    background: theme.colorSecondary,
    transition: "all 0.2s ease",
  }),
};

export default styles;

import React from "react";
import { useProfileStore, IProfileStore } from "src/store/profile";
import { IUseGetProfileQueryData, useGetProfileRequest } from "src/hooks/api/profile";
import { useAuthStore } from "src/store/account";
import { MembershipPermission } from "src/enums/permission";
import { useToastStore } from "src/store/toast";
import { useTranslation } from "react-i18next";

type IUseProfileStoreWithRefetch = () => IProfileStore;
type IUseProfileStoreWithRefetchAndRequest = () => [IProfileStore, IUseGetProfileQueryData];

export const useProfileStoreWithRefetchAndRequest: IUseProfileStoreWithRefetchAndRequest = () => {
  const profile = useProfileStore();
  const { t } = useTranslation();
  const { addToastDanger } = useToastStore();
  const auth = useAuthStore();

  const request = useGetProfileRequest(false);

  const {
    data,
    status,
    error,
    isError,
    isFetched,
    fetchStatus,
    refetch,
  } = request;

  React.useEffect(() => {
    if (auth.isAuthenticated && fetchStatus === "idle" && !isFetched) {
      refetch().finally();
    }
  }, [auth.isAuthenticated, fetchStatus, isFetched]);

  React.useEffect(() => {
    if (isError && isFetched) {
      if (error?.response?.data?.detail) {
        addToastDanger({ title: error.response.data.detail });
      } else if (isFetched) {
        addToastDanger({ title: t("Error loading profile. Please, try again later.") });
      }
    }
  }, [error, isError, isFetched]);

  React.useEffect(() => {
    if (status === "success" && data?.data) {
      profile.setProfileFromResponse(data.data);
      auth.setIsAuthenticated(true);
      auth.setIsSuperuser(data.data.is_superuser);
    }
    if (status === "error") {
      auth.setIsSuperuser(false);
    }
  }, [status, data]);

  return [profile, request];
};

export const useProfileStoreWithRefetch: IUseProfileStoreWithRefetch = () => {
  const [profile] = useProfileStoreWithRefetchAndRequest();
  return profile;
};

export const useProfileHasPermission = (permission: MembershipPermission) => {
  const profile = useProfileStoreWithRefetch();
  return React.useMemo(() => (
    profile.isSuperuser || profile.permissions.includes(permission)
  ), [profile, permission]);
};

export const useProfileHasAnyPermission = (permissions: MembershipPermission | MembershipPermission[]) => {
  const profile = useProfileStoreWithRefetch();
  let permissionsToCheck: MembershipPermission[];
  if (!Array.isArray(permissions)) {
    permissionsToCheck = [permissions];
  } else {
    permissionsToCheck = permissions;
  }
  return React.useMemo(() => (
    profile.isSuperuser || permissionsToCheck.some((perm) => profile.permissions.includes(perm))
  ), [profile, permissions]);
};

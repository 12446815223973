import type { RouteObject } from "react-router-dom";
import React from "react";
import AdminRequiredWrapper from "src/pages/admin/AdminRequiredWrapper";
import lazyWithRetry from "src/utils/lazy-with-retry";

const PropertiesPage = lazyWithRetry(() => import("src/pages/admin/properties/PropertiesPage"));
const CSVFeed = lazyWithRetry(() => import("src/pages/admin/properties/csv-feed"));
const PropertyVerificationPage = lazyWithRetry(() => import("src/pages/admin/properties/verification-page"));
const PropertyTablePage = lazyWithRetry(() => import("src/pages/admin/properties/table-page"));
const Dashboard = lazyWithRetry(() => import("src/pages/admin/components/dashboard"));

const schedulersRouter: RouteObject[] = [
  {
    path: "properties/",
    element: <AdminRequiredWrapper><PropertiesPage /></AdminRequiredWrapper>,
    children: [
      {
        index: true,
        element: <AdminRequiredWrapper><Dashboard statisticKey="properties" /></AdminRequiredWrapper>,
      },
      {
        path: "csv-feed/",
        element: <AdminRequiredWrapper><CSVFeed /></AdminRequiredWrapper>,
      },
      {
        path: "csv-feed/:page/",
        element: <AdminRequiredWrapper><CSVFeed /></AdminRequiredWrapper>,
      },
      {
        path: "needs-verification/",
        element: <AdminRequiredWrapper><PropertyVerificationPage /></AdminRequiredWrapper>,
      },
      {
        path: "needs-verification/:page/",
        element: <AdminRequiredWrapper><PropertyVerificationPage /></AdminRequiredWrapper>,
      },
      {
        path: "table/",
        element: <AdminRequiredWrapper><PropertyTablePage allowExport /></AdminRequiredWrapper>,
      },
      {
        path: "table/:page/",
        element: <AdminRequiredWrapper><PropertyTablePage allowExport /></AdminRequiredWrapper>,
      },
    ],
  },
];

export default schedulersRouter;

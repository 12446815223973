import React from "react";
import FormSelect from "react-bootstrap/esm/FormSelect";
import { useToastStore } from "src/store/toast";
import { useAgentMarketCampaignPropertyUpdateRequest } from "src/hooks/api/agent/market-campaign";
import { useRouterParamId } from "src/hooks/router";
import { PropertyStatus } from "src/enums/property";
import { useProfileStore } from "src/store/profile";

interface MarketCampaignPropertyStatusToggleProps {
  property: AgentAPI.MarketCampaign.PropertyListData;
}

const allowedStatusUpdates = [
  PropertyStatus.VERIFIED,
  PropertyStatus.LISTED,
  PropertyStatus.NEEDS_VERIFICATION,
];

const MarketCampaignPropertyStatusToggle: React.FC<MarketCampaignPropertyStatusToggleProps> = (
  {
    property,
  },
) => {
  const marketCampaignId = useRouterParamId("id");
  const {
    isSuperuser,
  } = useProfileStore();
  const {
    addToastSuccess,
    addToastDanger,
  } = useToastStore();

  const [status, setStatus] = React.useState(() => property.status);
  const {
    data,
    mutate,
    error,
    isSuccess,
    isError,
    isLoading,
  } = useAgentMarketCampaignPropertyUpdateRequest(marketCampaignId);

  React.useEffect(() => {
    setStatus(property.status);
  }, [property.status]);

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setStatus(event.target.value as AgentAPI.MarketCampaign.PropertyUpdate.Request["status"]);
    mutate({
      id: property.id,
      status: event.target.value as AgentAPI.MarketCampaign.PropertyUpdate.Request["status"],
    });
  };

  if (!allowedStatusUpdates.includes(property.status)) {
    return (
      <span>{property.status_display}</span>
    );
  }

  React.useEffect(() => {
    if (isSuccess && data?.data?.status) {
      addToastSuccess({
        title: `Property status for ${data.data.title} changed to ${data.data.status_display}`,
      });
      setStatus(data.data.status);
    }
  }, [isSuccess, data]);

  React.useEffect(() => {
    if (isError && error?.response?.data?.detail) {
      addToastDanger({
        title: error.response.data.detail,
      });
    }
  }, [isError, error]);

  if (!isSuperuser) {
    return (
      <span>
        {property.status_display}
      </span>
    );
  }

  return (
    <FormSelect
      value={status}
      disabled={isLoading}
      onChange={handleChange}
    >
      <option value={PropertyStatus.VERIFIED}>
        Verified
      </option>
      <option value={PropertyStatus.LISTED}>
        Listed
      </option>
      <option value={PropertyStatus.NEEDS_VERIFICATION}>
        Need Verification
      </option>
    </FormSelect>
  );
};

export default MarketCampaignPropertyStatusToggle;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "src/store/account";
import PagePreloader from "src/components/loaders";
import { useNextPathStore } from "src/store/redirect-context";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";

interface ILoginRequiredWrapper {
  children: JSX.Element;
}

const LoginRequiredWrapper: React.FC<ILoginRequiredWrapper> = ({ children }) => {
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { setNextPath } = useNextPathStore();

  React.useEffect(() => {
    if (!isAuthenticated) {
      let next = location.pathname;
      if (location.search) {
        next += `?${location.search}`;
      }
      setNextPath(next);
      navigate(`/login/`);
    }
  }, [isAuthenticated]);

  return (
    <React.Suspense fallback={(<PagePreloader />)}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default LoginRequiredWrapper;

import { IStyleFunction } from "src/hooks/styles";
import React from "react";

interface IStyles {
  [key: string]: IStyleFunction;
}

const styles: IStyles = {
  textDivider: (theme) => ({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  }),
  line: (theme) => ({
    flex: "1 2 0",
    display: "inline-block",
    maxWidth: "48%",
    height: 1,
    width: "auto",
    opacity: 0.6,
    background: theme.colorOutline,
  }),
  noTextLine: (theme) => ({
    display: "block",
    height: 1,
    width: "100%",
    opacity: 0.6,
    background: theme.colorOutline,
  }),
  text: (theme) => ({
    flex: "0 1 0",
    lineHeight: 1.3,
    color: theme.colorTextMuted,
    fontSize: theme.fontSizes.sm,
    margin: `0 ${theme.spaces.md}px`,
    whiteSpace: "nowrap",
  }),
};

export default styles;

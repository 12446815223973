/** @jsxImportSource @emotion/react */
import React from "react";
import { SerializedStyles } from "@emotion/react";
import { formatPrice } from "src/utils/formatters/number";
import { formatDate } from "src/utils/formatters/date-time";

interface AsDateProps {
  value?: string;
  format?: string;
  style?: React.CSSProperties;
  className?: string;
}

const AsDate: React.FC<AsDateProps> = (
  {
    value,
    format,
    ...props
  },
) => {
  const formattedValue: string = React.useMemo(() => {
    if (!value) {
      return "";
    }

    return formatDate(value, format);
  }, [value, format]);

  return (
    <span {...props}>
      {formattedValue}
    </span>
  );
};

export default AsDate;

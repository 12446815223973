import React from "react";
import { IStyleBlock } from "src/hooks/styles";

export type IStyles = IStyleBlock<[
  "nav" | "navVertical" | "button"
]>;

const styles: IStyles = {
  nav: (theme) => ({
    display: "flex",
    columnGap: theme.spaces.md,
    paddingBottom: theme.spaces.md,
    borderBottom: `1px solid ${theme.colorOutlineLight}`,
    maxWidth: "100%",
    overflowX: "auto",
  }),
  navVertical: (theme) => ({
    flexDirection: "column",
    borderBottom: "none",
    maxWidth: "100%",
    overflowX: "unset",
    columnGap: 0,
    rowGap: theme.spaces.md,
  }),
  button: (theme) => ({}),
};

export default styles;

/** @jsxImportSource @emotion/react */
import React from "react";
import UIButton, { ButtonProps as UIButtonProps } from "react-bootstrap/esm/Button";
import type { SerializedStyles } from "@emotion/react";

export type ButtonProps = UIButtonProps & {
  icon?: React.ReactNode;
  iconRight?: boolean;
  loading?: boolean;
  css?: SerializedStyles | (SerializedStyles | undefined)[];
  variant?: UIButtonProps["variant"] | "chip" | "outline-gray";
}

const forwardedButton = (
  {
    icon,
    children,
    loading,
    iconRight,
    disabled,
    className,
    css,
    ...props
  }: ButtonProps,
  ref?: React.Ref<HTMLElement>,
) => {
  const classNames = React.useMemo(() => {
    const classes = className ? className.split(" ") : [];
    if (icon) {
      classes.push("btn-icon");
      if (iconRight) {
        classes.push("btn-icon--right");
      }
    }
    if (icon && !children) {
      classes.push("btn-icon--only");
    }
    return classes.join(" ");
  }, [icon, className, children]);

  return (
    <UIButton
      ref={ref}
      disabled={disabled || loading}
      css={css}
      className={classNames}
      {...props}
    >
      {!iconRight && icon}
      {children}
      {iconRight && icon}
    </UIButton>
  );
};

const Button = React.forwardRef<HTMLElement, ButtonProps>(forwardedButton);

export default Button;

/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
  "container" |
  "menuContainer" |
  "toggle" |
  "toggleIcon" |
  "navbar" |
  "menu" |
  "footerMenu" |
  "overlay" |
  "logo"
]>;

const styles: IStyles = {
  container: (theme) => css({
    transition: "all 0.3s ease",
    display: "grid",
    grid: `${theme.header.height}px 1fr/1fr`,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
    background: theme.colorBackground,
    zIndex: 990,
    [theme.mq.sm]: {
      paddingBottom: theme.spaces.md,
    },
  }),
  menuContainer: (theme) => css({
    maxHeight: `calc(100vh - ${theme.header.height}px)`,
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }),
  toggle: (theme) => css({
    border: "none",
    position: "absolute",
    background: "transparent",
    width: 44,
    height: 44,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    outline: "none",
    left: "100%",
    transform: "translate(-50%, -50%) rotate(0deg)",
  }),
  toggleIcon: (theme) => css({
    border: `1px solid ${theme.colorOutlineLight}`,
    borderRadius: "50%",
    background: theme.colorBackground,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    color: theme.colorPrimary,
  }),
  logo: (theme) => css({
    display: "inline-block",
  }),
  menu: (theme) => css({
    flex: "1 3 auto",
    minWidth: 0,
  }),
  footerMenu: (theme) => css({
    flex: "0 0 auto",
    paddingTop: theme.spaces.lg,
    minWidth: 0,
  }),
  navbar: (theme) => css({
    height: theme.header.height,
    flex: `0 0 ${theme.header.height}px`,
    minWidth: 0,
    position: "relative",
  }),
  overlay: (theme) => css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: `100vh`,
    background: "rgba(0, 0, 0, 0.24)",
    zIndex: 980,
  }),
};

export default styles;

import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

export const useParamPageNumber = () => {
  const { page } = useParams<{ page: string }>();

  const pageNumber = React.useMemo(() => {
    if (!page) {
      return 1;
    }

    const parsedPage = parseInt(page, 10);

    if (Number.isNaN(parsedPage)) {
      return 1;
    }

    return parsedPage;
  }, [page]);

  return pageNumber;
};

export const useLimitOffsetPaginationParams = (
  limit: number = 24,
): {
  limit: number;
  offset: number;
} => {
  const page = useParamPageNumber();

  const requestParams = React.useMemo(() => ({
    limit,
    offset: (page - 1) * limit,
  }), [page, limit]);

  return requestParams;
};

type ICursor = string | null;
type ISetCursorFunc = (cursor: ICursor) => void;

export const useCursorPaginationParams = (): [ICursor, ISetCursorFunc] => {
  const [params, setParams] = useSearchParams();

  const setCursor: ISetCursorFunc = (cursor) => {
    // @ts-ignore
    setParams({ cursor });
  };

  return [
    params.get("cursor"),
    setCursor,
  ];
};

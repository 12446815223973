/** @jsxImportSource @emotion/react */
import React from "react";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import { FormControlError } from "src/components/ui/form/error";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { FormControl, FormControlPhone } from "src/components/ui/form/control";
import Row from "react-bootstrap/esm/Row";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormValue } from "src/pages/landlords/forms/schema";

export interface ContactFormPartProps {
  labels?: {
    "contact.name"?: string;
    "contact.email"?: string;
    "contact.phone"?: string;
  };
}

const ContactFormPart: React.FC<ContactFormPartProps> = ({ labels }) => {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<FormValue>();

  return (
    <Row className="mb-5">
      <Col xs={24}>
        <Form.Group className="mb-3">
          <Form.Label>{labels?.["contact.name"] || t("Your name")}</Form.Label>
          <Form.Control
            type="text"
            autoComplete="name"
            isInvalid={!!errors.contact?.name}
            isValid={!errors.contact?.name}
            {...register("contact.name")}
          />
          <FormControlError absolute={false} error={errors.contact?.name?.message} />
        </Form.Group>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Group className="mb-3">
          <Form.Label>{labels?.["contact.email"] || t("Your email")}</Form.Label>
          <Form.Control
            type="email"
            autoComplete="email"
            placeholder="mail@example.com"
            isInvalid={!!errors.contact?.email}
            isValid={!errors.contact?.email}
            {...register("contact.email")}
          />
          <FormControlError absolute={false} error={errors.contact?.email?.message} />
        </Form.Group>
      </Col>
      <Col xs={24} sm={12}>
        <Form.Group className="mb-3">
          <Form.Label>{labels?.["contact.phone"] || t("Your phone number")}</Form.Label>
          <FormControlPhone
            type="text"
            isInvalid={!!errors.contact?.phone}
            isValid={!errors.contact?.phone}
            control={control}
            name="contact.phone"
          />
          <FormControlError absolute={false} error={errors.contact?.phone?.message} />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ContactFormPart;

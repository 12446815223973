import { IStyleBlock } from "src/hooks/styles";

export type IStyles = IStyleBlock<[
  "nav" | "button" | "link" | "linkArrow"
]>;

const styles: IStyles = {
  nav: () => ({
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
  }),
  link: (theme) => ({
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    marginRight: theme.spaces.xs,
    marginLeft: theme.spaces.xs,
  }),
  linkArrow: (theme) => ({
    marginLeft: theme.spaces.xs,
    marginRight: theme.spaces.xs,
    transformOrigin: "center",
  }),
  button: (theme) => ({
    borderRadius: theme.borderRadius,
    padding: 0,
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    marginLeft: theme.spaces.xxs,
    marginRight: theme.spaces.xxs,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeight.semiBold,
  }),
};

export default styles;

import React from "react";
import PropertyTableFilterContext from "src/pages/admin/properties/table-page/context";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { FormGroup } from "src/components/ui/form/group";
import FormCheck from "react-bootstrap/esm/FormCheck";
import FormControl from "react-bootstrap/esm/FormControl";
import FormSelect from "react-bootstrap/esm/FormSelect";
import FormLabel from "src/components/ui/form/form-label";
import { PropertyStatus } from "src/enums/property";
import { useStateListRequest } from "src/hooks/api/area";
import { useAccountAgentListRequest } from "src/hooks/api/admin/account";
import AddressSearch from "src/components/address-search";
import Button from "src/components/ui/form/button";

interface PropertyTableFilterProps {
  onlyState?: boolean;
}

const PropertyTableFilter: React.FC<PropertyTableFilterProps> = ({ onlyState }) => {
  const {
    filter,
    updateFilter,
    removeFromFilter,
    resetFilter,
  } = React.useContext(PropertyTableFilterContext);

  const {
    data: stateData,
  } = useStateListRequest();

  const states = React.useMemo(() => {
    if (!stateData?.data) {
      return [];
    }
    return stateData.data;
  }, [stateData]);

  const handleBooleanChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, checked } = event.target;
    updateFilter({
      ...filter,
      [name]: checked,
    });
  };

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { name, value } = event.target;

    if (!value) {
      removeFromFilter(name);
      return;
    }

    updateFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;

    if (!value) {
      removeFromFilter(name);
      return;
    }

    updateFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleLocationChange = (value: google.maps.LatLngLiteral | undefined) => {
    if (!value) {
      removeFromFilter("location");
      return;
    }

    updateFilter({
      ...filter,
      point: value,
    });
  };

  const {
    data: agentData,
  } = useAccountAgentListRequest();

  return (
    <Row>
      <Col xs={24} md={16} lg={12} className="mb-3">
        <FormGroup className="mb-0">
          <FormLabel>
            Search by address
          </FormLabel>
          <AddressSearch
            initialValue={filter.point}
            onChange={handleLocationChange}
          />
        </FormGroup>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} className="mb-3">
        <FormGroup className="mb-0">
          <FormLabel>
            Select State
          </FormLabel>
          <FormSelect
            name="market_state"
            value={filter.market_state || ""}
            onChange={handleSelectChange}
          >
            <option value="">All</option>
            {states.map((state) => (
              <option key={state.id} value={state.id}>{state.name}</option>
            ))}
          </FormSelect>
        </FormGroup>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} className="mb-3">
        <FormGroup className="mb-0">
          <FormLabel>
            Search by ID or Zillow url
          </FormLabel>
          <FormControl
            type="text"
            name="search"
            value={filter.search || ""}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} className="mb-3">
        <FormGroup className="mb-0">
          <FormLabel>
            Select Category
          </FormLabel>
          <FormSelect
            name="keyword_category"
            value={filter.keyword_category || ""}
            onChange={handleSelectChange}
          >
            <option value="">All</option>
            <option value="arbitrage">Arbitrage</option>
            <option value="co_host">Co-Host</option>
          </FormSelect>
        </FormGroup>
      </Col>
      {!onlyState && (
        <>
          <Col xs={24} sm={12} md={8} lg={6} className="mb-3">
            <FormGroup className="mb-0">
              <FormLabel>
                Contact status
              </FormLabel>
              <FormSelect
                name="contact_status"
                value={filter.contact_status || ""}
                onChange={handleSelectChange}
              >
                <option value="">All</option>
                <option value="not_set">Not set</option>
                <option value="expressed_interest">Expressed interest</option>
                <option value="sublease_ready">Sublease ready</option>
              </FormSelect>
            </FormGroup>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} className="mb-3">
            <FormGroup className="mb-0">
              <FormLabel>
                Property status
              </FormLabel>
              <FormSelect
                name="status"
                value={filter.status || ""}
                onChange={handleSelectChange}
              >
                <option value="">All</option>
                <option value={PropertyStatus.LISTED}>Listed</option>
                <option value={PropertyStatus.VERIFIED}>Verified</option>
                <option value={PropertyStatus.NEEDS_VERIFICATION}>Needs verification</option>
              </FormSelect>
            </FormGroup>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} className="mb-3">
            <FormGroup className="mb-0">
              <FormLabel>
                Filter by agent
              </FormLabel>
              <FormSelect
                name="submitted_by"
                value={filter.submitted_by || ""}
                onChange={handleSelectChange}
              >
                <option value="">All</option>
                {agentData?.data.results.map((agent) => (
                  <option key={agent.id} value={agent.id}>
                    {`${agent.name} <${agent.email}>`}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} className="d-flex flex-column mb-3">
            <FormCheck
              className="mt-auto"
              type="checkbox"
              id="is_apartment"
              label="Show only apartments"
              checked={!!filter.is_apartment}
              name="is_apartment"
              onChange={handleBooleanChange}
            />
            <FormCheck
              type="checkbox"
              id="has_contact_data"
              label="Has property contact"
              checked={!!filter.has_contact_data}
              name="has_contact_data"
              onChange={handleBooleanChange}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} className="d-flex flex-column mb-3">
            <FormCheck
              className="mt-auto"
              type="checkbox"
              id="is_account_edited"
              label="Landlord details verified"
              checked={!!filter.is_account_edited}
              name="is_account_edited"
              onChange={handleBooleanChange}
            />
            <FormCheck
              type="checkbox"
              id="has_description"
              label="Has description"
              checked={!!filter.has_description}
              name="has_description"
              onChange={handleBooleanChange}
            />
          </Col>
        </>
      )}
      <Col xs={24} sm={12} md={8} lg={6} className="d-flex flex-column mb-3">
        <FormCheck
          className="mt-auto"
          type="checkbox"
          id="is_submitted_by_agent"
          label="Only submitted by agents"
          checked={!!filter.is_submitted_by_agent}
          name="is_submitted_by_agent"
          onChange={handleBooleanChange}
        />
        <FormCheck
          type="checkbox"
          id="is_submitted_by_me"
          label="Only my submitted properties"
          checked={!!filter.is_submitted_by_me}
          name="is_submitted_by_me"
          onChange={handleBooleanChange}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} className="d-flex flex-column mb-3">
        <Button
          variant="outline-dark"
          onClick={resetFilter}
        >
          Clear filters
        </Button>
      </Col>
    </Row>
  );
};

export default PropertyTableFilter;

import React from "react";
import Form from "react-bootstrap/esm/Form";
import type { FormGroupProps as UIFormGroupProps } from "react-bootstrap/esm/FormGroup";

type FormGroupProps = UIFormGroupProps & {
  children: React.ReactNode;
}

const FormGroup: React.FC<FormGroupProps> = ({ className, ...props }) => {
  const classNames = React.useMemo(() => {
    const defaultClass = "button-form-group";
    if (className) {
      return `${defaultClass} ${className}`;
    }
    return defaultClass;
  }, [className]);

  return (
    <Form.Group
      className={classNames}
      {...props}
    />
  );
};

export default FormGroup;

/** @jsxImportSource @emotion/react */
import React from "react";
import { IClassBlock } from "src/hooks/styles";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
    "badge"
]>;

const styles: IStyles = {
  badge: (theme) => css({
    borderRadius: 8,
    minHeight: 36,
    padding: `${theme.spaces.xxs}px ${theme.spaces.sm}px`,
    backgroundColor: theme.colorSecondary,
    color: theme.colorTextPrimary,
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.fontSizes.base,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    textDecoration: "none",
    ":hover": {
      color: theme.colorTextPrimary,
    },
  }),
};

export default styles;

import React from "react";
import { useMetaTitleSetter } from "src/hooks/meta";
import { useAgentMarketCampaignListRequest } from "src/hooks/api/agent/market-campaign";
import CenterPreloader from "src/components/loaders/CenterPreloader";
import MarketCampaignCard from "src/pages/agent/market-campaigns/components/MarketCampaignCard";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useLimitOffsetPaginationParams } from "src/hooks/pagination";
import LimitOffsetPagination from "src/components/ui/pagination/LimitOffsetPagination";

const MyMarketCampaignsPage: React.FC = () => {
  const title = "My market campaigns";
  useMetaTitleSetter(title);

  const paginationParams = useLimitOffsetPaginationParams(12);

  const {
    data,
    isFetching,
  } = useAgentMarketCampaignListRequest(paginationParams);

  if (isFetching) {
    return (
      <CenterPreloader />
    );
  }

  return (
    <section>
      <Row>
        {data?.data.results.map((marketCampaign) => (
          <Col
            key={marketCampaign.id}
            className="mb-3"
            xs={24}
            sm={12}
            md={8}
            lg={6}
          >
            <MarketCampaignCard
              key={marketCampaign.id}
              marketCampaign={marketCampaign}
            />
          </Col>
        ))}
        <Col xs={24} className="d-flex justify-content-center">
          <LimitOffsetPagination
            pageSize={paginationParams.limit}
            total={data?.data.count || 0}
            baseUrl="/agent/market-campaigns/"
          />
        </Col>
      </Row>
    </section>
  );
};

export default MyMarketCampaignsPage;

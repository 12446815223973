import create from "zustand";

export interface IToast {
  id: string;
  title: string;
  variant: "danger" | "success" | "warning" | "info";
  body?: string;
  onUndo?: () => void;
}

export interface IToastStore {
  toasts: IToast[];
  addToast: (toast: Omit<IToast, "id">) => void;
  addToastSuccess: (toast: Omit<IToast, "id" | "variant">) => void;
  addToastDanger: (toast: Omit<IToast, "id" | "variant">) => void;
  addToastWarning: (toast: Omit<IToast, "id" | "variant">) => void;
  removeToast: (toast: IToast) => void;
  addToastSuccessWithUndo: (toast: Omit<IToast, "id" | "variant">, callback: () => void) => void;
}

const getToastId = () => `${Date.now().toString()}-${Math.random().toString()}`;

export const useToastStore = create<IToastStore>()(
  (set, get) => ({
    toasts: [],
    addToast: (toast) => set(
      (state) => {
        if (state.toasts.filter((t) => t.title === toast.title && t.variant === toast.variant).length > 0) {
          return {
            ...state,
            toasts: state.toasts.map((t) => {
              if (t.title === toast.title && t.variant === toast.variant) {
                return { ...t, body: toast.body };
              }
              return t;
            }),
          };
        }
        return {
          toasts: [...state.toasts, { ...toast, id: getToastId() }],
        };
      },
    ),
    addToastSuccess: (toast) => get().addToast({
      ...toast,
      variant: "success",
    }),
    addToastSuccessWithUndo: (toast, callback) => get().addToast({
      ...toast,
      variant: "success",
      onUndo: callback,
    }),
    addToastDanger: (toast) => get().addToast({
      ...toast,
      variant: "danger",
    }),
    addToastWarning: (toast) => get().addToast({
      ...toast,
      variant: "warning",
    }),
    removeToast: (toast) => set(
      (state) => ({
        toasts: state.toasts.filter((t) => t.id !== toast.id),
      }),
    ),
  }),
);

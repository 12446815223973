/** @jsxImportSource @emotion/react */
import React from "react";
import {
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel, Header, HeaderGroup, RowSelectionState, TableState,
  useReactTable,
} from "@tanstack/react-table";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import Button from "src/components/ui/form/button";
import FormSelect from "react-bootstrap/esm/FormSelect";
import FormGroup from "src/components/ui/form/group/FormGroup";
import FormLabel from "src/components/ui/form/form-label";
import { useThemedCss } from "src/hooks/styles";
import styles, { IStyles } from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyTable.styles";
import { useAgentMarketCampaignPropertyBulkUpdateRequest } from "src/hooks/api/agent/market-campaign";
import { useToastStore } from "src/store/toast";
import {
  defaultColumns,
  defaultColumn,
} from "src/pages/agent/market-campaigns/components/columns";

type PropertyTableData = AgentAPI.MarketCampaign.PropertyList.Response["results"][0];

const TABLE_STATE_KEY = "market-campaign-property-table-state";

interface MarketCampaignPropertyTableProps {
  properties: PropertyTableData[];
  marketCampaign: AgentAPI.MarketCampaign.MarketCampaignDetailData;
}

const ColumnResizeButton: React.FC<{ header: Header<AgentAPI.MarketCampaign.PropertyListData, unknown> }> = ({ header }) => {
  const tableResizerClasses = header.column.getIsResizing()
    ? "av-table-resizer av-table-resizer--resizing"
    : "av-table-resizer";

  const resizeHandler = header.getResizeHandler();

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    resizeHandler(event);
  };

  if (!header.column.getCanResize()) {
    return null;
  }

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      onMouseDown={handleMouseDown}
      onTouchStart={header.getResizeHandler()}
      className={tableResizerClasses}
    />
  );
};

const MarketCampaignPropertyTable: React.FC<MarketCampaignPropertyTableProps> = (
  {
    properties,
    marketCampaign,
  },
) => {
  const {
    addToastSuccess,
    addToastDanger,
  } = useToastStore();
  const themedCss = useThemedCss<IStyles>(styles);
  const hasPropertyApprovePerm = useProfileHasPermission(MembershipPermission.ACCESS_MARKET_CAMPAIGN_APPROVE_PROPERTIES);

  const [bulkAction, setBulkAction] = React.useState<AgentAPI.MarketCampaign.PropertyMarketCampaignStatus | "">("");

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});

  const columns = React.useMemo<typeof defaultColumns>(() => {
    if (!hasPropertyApprovePerm) {
      return defaultColumns.filter((column) => column.id !== "select-col");
    }

    return defaultColumns;
  }, [hasPropertyApprovePerm]);

  const table = useReactTable<PropertyTableData>({
    data: properties,
    columns,
    defaultColumn,
    columnResizeMode: "onChange" as ColumnResizeMode,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: hasPropertyApprovePerm,
    state: {
      rowSelection,
    },
  });

  const [state, onStateChange] = React.useState<TableState>(() => {
    try {
      if (window.localStorage.getItem(TABLE_STATE_KEY)) {
        return JSON.parse(window.localStorage.getItem(TABLE_STATE_KEY)!);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    return table.initialState;
  });

  React.useEffect(() => {
    window.localStorage.setItem(TABLE_STATE_KEY, JSON.stringify(state));
  }, [state]);

  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange,
  }));

  const isAnyRowSelected = React.useMemo(() => (
    table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
  ), [rowSelection]);

  const handleBulkActionChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setBulkAction(event.target.value as typeof bulkAction);
  };

  const {
    mutate,
    isSuccess,
    isError,
    error,
  } = useAgentMarketCampaignPropertyBulkUpdateRequest(marketCampaign.id);

  React.useEffect(() => {
    if (isSuccess) {
      setBulkAction("");
      setRowSelection({});
      addToastSuccess({
        title: "Bulk action succeeded",
      });
    }

    if (isError && error?.response) {
      addToastDanger({
        title: "Bulk action failed",
        body: error?.response?.data.detail,
      });
    }
  }, [isSuccess, isError, error]);

  const handleBulkAction = () => {
    if (!bulkAction) {
      return;
    }

    const idsToApprove = table.getSelectedRowModel().rows.map((row) => row.original.id);

    mutate(idsToApprove.map((id) => ({
      id,
      market_campaign_status: bulkAction,
    })));
  };

  return (
    <div css={themedCss.tableContainer}>
      <table
        className="table table-group-divider table-bordered av-table-ellipsis"
        css={themedCss.table}
        style={{
          width: table.getCenterTotalSize(),
        }}
      >
        <thead
          style={{
            width: table.getCenterTotalSize(),
          }}
        >
          <tr>
            {table.getFlatHeaders().map((header) => (
              <th
                scope="col"
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  width: header.getSize(),
                  position: "relative",
                }}
              >
                <div className="av-table-ellipsis__cell">
                  {header.isPlaceholder
                    ? null
                    : (
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )
                    )}
                </div>
                <ColumnResizeButton header={header} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const style: React.CSSProperties = {};
            if (row.original.is_added_to_campaign_by_user) {
              style.background = "#FFA1A170";
            }
            if (row.getIsSelected()) {
              style.background = "#FFF3E9";
            }
            return (
              <tr
                key={row.id}
                style={style}
                onClick={row.getToggleSelectedHandler()}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    <div className="av-table-ellipsis__cell">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        {hasPropertyApprovePerm && (
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <div className="d-flex flex-nowrap position-sticky top-0" style={{ maxWidth: 500 }}>
                  <FormGroup className="mb-0 me-3">
                    <FormLabel htmlFor="bulk-action">
                      Choose bulk action
                    </FormLabel>
                    <FormSelect
                      disabled={!isAnyRowSelected}
                      id="bulk-action"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">
                        Select action
                      </option>
                      <option value="not_matched_criteria">
                        Hide from market campaign
                      </option>
                      <option value="active">
                        Show on market campaign
                      </option>
                    </FormSelect>
                  </FormGroup>

                  <Button
                    type="button"
                    variant="primary"
                    className="align-self-end"
                    disabled={!isAnyRowSelected || !bulkAction}
                    onClick={handleBulkAction}
                  >
                    Apply
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default MarketCampaignPropertyTable;

import create from "zustand";
import { persist } from "zustand/middleware";
import * as Sentry from "@sentry/react";

interface ProfileData {
  id: API.Profile.CommonResponse["id"];
  email: API.Profile.CommonResponse["email"];
  name: API.Profile.CommonResponse["name"];
  phoneNumber: API.Profile.CommonResponse["phone_number"];
  activePackage: API.Profile.CommonResponse["active_package"];
  activeMembership: API.Profile.CommonResponse["active_membership"];
  isSuperuser: API.Profile.CommonResponse["is_superuser"];
  isEmailConfirmed: API.Profile.CommonResponse["is_email_confirmed"];
  isPhoneConfirmed: API.Profile.CommonResponse["is_phone_confirmed"];
  billingAttached: API.Profile.CommonResponse["is_billing_attached"];
  restrictedByAreas: API.Profile.CommonResponse["restricted_by_areas"];
  permissions: API.Profile.CommonResponse["permissions"];
  investorProfile: API.Profile.CommonResponse["investor_profile"];
  referralCode: API.Profile.CommonResponse["referral_code"];
  isNewAccount: API.Profile.CommonResponse["is_new_user"];
  isTutorialCompleted: API.Profile.CommonResponse["is_tutorial_completed"];
  isTrialPackage: API.Profile.CommonResponse["is_trial_package"];
}

export interface IProfileStore extends ProfileData {
  reset: () => void;
  setProfile: (profile: ProfileData) => void;
  setPartialProfile: (profile: Partial<ProfileData>) => void;
  setProfileFromResponse: (profile: API.Profile.Get.Response) => void;
}

export const initialState: ProfileData = {
  id: 0,
  email: "",
  name: "",
  phoneNumber: "",
  isSuperuser: false,
  activeMembership: null,
  activePackage: null,
  billingAttached: false,
  isEmailConfirmed: false,
  isPhoneConfirmed: false,
  restrictedByAreas: [],
  permissions: [],
  investorProfile: null,
  referralCode: null,
  isNewAccount: false,
  isTutorialCompleted: true,
  isTrialPackage: false,
};

export const useProfileStore = create<IProfileStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      reset: () => set({ ...initialState }),
      setProfile: (profile) => set(profile),
      setPartialProfile: (profile) => set({ ...get(), ...profile }),
      setProfileFromResponse: (res) => {
        try {
          Sentry.setUser({
            id: res.id.toString(),
            email: `${res.email.slice(0, 2)}***@${res.email.split("@")[1]}`,
            isEmailConfirmed: res.is_email_confirmed.toString(),
            isNewAccount: res.is_new_user.toString(),
            isPhoneConfirmed: res.is_phone_confirmed.toString(),
            isSuperuser: res.is_superuser.toString(),
            activeMembership: (res.active_membership || 0).toString(),
            activePackage: (res.active_package || 0).toString(),
            billingAttached: res.is_billing_attached.toString(),
            isTrialPackage: res.is_trial_package,
          });
        } catch (e) {
          Sentry.captureException(e);
        }

        return set({
          id: res.id,
          email: res.email,
          name: res.name,
          phoneNumber: res.phone_number,
          isSuperuser: res.is_superuser,
          billingAttached: res.is_billing_attached,
          activeMembership: res.active_membership,
          activePackage: res.active_package,
          restrictedByAreas: res.restricted_by_areas,
          isEmailConfirmed: res.is_email_confirmed,
          isPhoneConfirmed: res.is_phone_confirmed,
          permissions: res.permissions,
          investorProfile: res.investor_profile,
          referralCode: res.referral_code,
          isNewAccount: res.is_new_user,
          isTutorialCompleted: res.is_tutorial_completed,
          isTrialPackage: res.is_trial_package,
        });
      },
    }),
    {
      name: "profileStore",
    },
  ),
);

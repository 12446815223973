import axios, { AxiosError, isAxiosError } from "axios";
import config from "src/config";
import { refreshToken } from "src/api/authentication";
import { useAuthStore } from "src/store/account";
import { useToastStore } from "src/store/toast";
import queryClient from "src/query-client";
import * as Sentry from "@sentry/react";

export const makeAnonymousRequest = axios.create({
  baseURL: config.API_URL,
  timeout: 10 * 1000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const makeRequest = axios.create({
  baseURL: config.API_URL,
  timeout: 20 * 1000,
});

makeAnonymousRequest.interceptors.request.use(
  (axiosConfig) => {
    const auth = useAuthStore.getState();

    if (auth.accessToken && auth.isAccessTokenValid()) {
      return {
        ...axiosConfig,
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
          ...axiosConfig.headers,
        },
      };
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

makeRequest.interceptors.request.use(
  async (axiosConfig) => {
    const auth = useAuthStore.getState();
    const controller = new AbortController();

    if (!auth.accessToken && !auth.refreshToken) {
      controller.abort();
    }

    return {
      ...axiosConfig,
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
        ...axiosConfig.headers,
      },
    };
  },
  (error) => {
    Promise.reject(error);
  },
);

makeRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (isAxiosError(error) && error.code === AxiosError.ERR_CANCELED) {
      return Promise.reject(error);
    }

    const {
      addToastDanger,
      addToastWarning,
    } = useToastStore.getState();

    const proceedErrorUnauthenticated = () => {
      addToastWarning({
        title: "Your session has expired.",
      });
      queryClient.cancelQueries();
      queryClient.invalidateQueries().finally(() => {
        useAuthStore.getState().unAuthenticate();
      });
      return Promise.reject(error);
    };

    if (error && error.response?.status === 400 && error.response.data?.detail) {
      let errorMessage = error.response.data.detail;
      if (Array.isArray(errorMessage)) {
        errorMessage = errorMessage.join(" ");
      }
      addToastDanger({
        title: errorMessage,
      });
    }

    if (error && (error.response?.status || 0) >= 500) {
      addToastDanger({
        title: "Oops, something went wrong. Please, try again later.",
      });
      return Promise.reject(error);
    }

    const originalRequest = error.config || {};

    if (isAxiosError(error) && (
      error.code === AxiosError.ERR_NETWORK || error.code === AxiosError.ECONNABORTED
    )) {
      addToastDanger({
        title: "Network connection problems. Please, try again later.",
      });
      return Promise.reject(error);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (error.response.status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;

      const refresh = useAuthStore.getState().refreshToken;

      if (!refresh) {
        return proceedErrorUnauthenticated();
      }

      try {
        const refreshResponse = await refreshToken({ refresh });

        if (refreshResponse.data.access) {
          useAuthStore.setState({
            accessToken: refreshResponse.data.access,
          });
          originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.access}`;
          return makeRequest(originalRequest);
        }
      } catch (err) {
        Sentry.setUser(null);
        return proceedErrorUnauthenticated();
      }
    }
    return Promise.reject(error);
  },
);

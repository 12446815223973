/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { IClassBlock } from "src/hooks/styles";

export type IStyles = IClassBlock<[
    "card" |
    "link" |
    "header" |
    "approvedLabel" |
    "imageOverlay" |
    "image" |
    "placeholder" |
    "favoriteButton" |
    "outOfBounds" |
    "body" |
    "priceLine" |
    "price" |
    "detailLine" |
    "detail" |
    "detailIcon" |
    "detailText" |
    "detailSeparator" |
    "addressLine" |
    "listDate" |
    "bodyGrid" |
    "bodyChip" |
    "bodyBadges" |
    "bodyRegulationContainer" |
    "bodyRegulation" |
    "bodyChipLabel" |
    "bodyChipValue"
]>;

const styles: IStyles = {
  card: (theme) => css({
    padding: theme.spaces.md,
    paddingTop: theme.spaces.xs,
    paddingBottom: theme.spaces.xs + theme.spaces.sm,
  }),
  outOfBounds: (theme) => css({
    position: "absolute",
    top: -8,
    left: -8,
    fontSize: theme.fontSizes.sm,
    background: theme.colorDanger,
    borderRadius: 4,
    color: theme.colorWhite,
    display: "inline-block",
    padding: `${theme.spaces.xxs}px ${theme.spaces.sm}px`,
    zIndex: 4,
  }),
  header: (theme) => css({
    paddingBottom: theme.spaces.md,
  }),
  link: (theme) => css({
    textDecoration: "none",
    color: theme.colorTextPrimary,
  }),
  imageOverlay: (theme) => css({
    marginBottom: theme.spaces.sm,
    padding: 0,
    marginLeft: -theme.spaces.xs,
    marginRight: -theme.spaces.xs,
    width: `calc(100% + ${theme.spaces.md}px)`,
    backgroundColor: theme.colorSecondary,
    overflow: "hidden",
    "&::before": {
      position: "relative",
      zIndex: 2,
      backgroundImage: "radial-gradient(circle at 110% -10%, rgba(0, 0, 0, 0.35) 0px, transparent 32%)",
    },
  }),
  placeholder: () => css({
    maxWidth: "80%",
    objectFit: "initial",
    width: "100%",
    height: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }),
  image: () => css({
    objectFit: "cover",
    zIndex: 1,
  }),
  body: (theme) => css({
    padding: 0,
    paddingTop: theme.spaces.sm,
  }),
  priceLine: (theme) => css({
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spaces.sm,
  }),
  price: (theme) => css({
    fontSize: theme.fontSizes.lg,
    fontWeight: theme.fontWeight.bold,
    lineHeight: theme.lineHeight.xs,
  }),
  listDate: (theme) => css({
    fontSize: theme.fontSizes.xs,
    color: theme.colorTextSecondary,
  }),
  detailLine: (theme) => css({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: theme.spaces.md,
    [theme.mq.xs]: {
      flexWrap: "wrap",
    },
  }),
  detail: (theme) => css({
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  }),
  detailIcon: (theme) => css({
    marginRight: theme.spaces.xxs,
  }),
  detailText: (theme) => css({
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  }),
  detailSeparator: (theme) => css({
    background: "#DFE2E4",
    display: "inline-block",
    width: 1,
    minWidth: 1,
    height: 14,
    margin: `0 ${theme.spaces.xs}px`,
  }),
  addressLine: (theme) => css({
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.lineHeight.xs,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.mq.xs]: {
      whiteSpace: "normal",
    },
  }),
  bodyGrid: (theme) => css({
    display: "grid",
    grid: "auto-flow / repeat(6, 1fr)",
    rowGap: theme.spaces.xs,
    columnGap: theme.spaces.xs,
  }),
  bodyChip: (theme) => css({
    background: theme.colorSecondary,
    padding: theme.spaces.xxs,
    borderRadius: 4,
    gridColumn: "2 span",
    [theme.mq.xs]: {
      gridColumn: "3 span",
    },
  }),
  bodyChipLabel: (theme) => css({
    fontSize: theme.fontSizes.xs,
    color: theme.colorPrimary,
    lineHeight: 2,
    display: "block",
    whiteSpace: "nowrap",
  }),
  bodyBadges: (theme) => css({
    gridColumn: "5 span",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    [theme.mq.xs]: {
      gridColumn: "4 span",
    },
  }),
  bodyChipValue: (theme) => css({
    fontSize: theme.fontSizes.sm,
    display: "block",
    fontWeight: theme.fontWeight.semiBold,
    whiteSpace: "nowrap",
  }),
  favoriteButton: (theme) => css({
    position: "absolute",
    top: theme.spaces.xs,
    right: theme.spaces.xs,
    padding: theme.spaces.sm,
    zIndex: 3,
  }),
  approvedLabel: (theme) => css({
    position: "absolute",
    top: theme.spaces.xs,
    left: theme.spaces.xs,
    zIndex: 3,
    width: "auto",
    height: "auto",
    padding: theme.spaces.sm,
    borderRadius: theme.borderRadius,
    background: `radial-gradient(circle, rgba(255, 255, 255, 0.7) 0%, transparent 100%)`,
    backgroundSize: "100% 50%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  }),
  bodyRegulation: (theme) => css({
    width: 36,
    height: 36,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.mq.xs]: {
      gridColumn: "2 span",
    },
  }),
  bodyRegulationContainer: (theme) => css({
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    [theme.mq.xs]: {
      gridColumn: "2 span",
    },
  }),
};

export default styles;

import { makeRequest } from "src/api/request";

export const updateProperty = async (
  id: AdminAPI.Property.PropertyData["id"],
  data: Partial<AdminAPI.Property.Update.Request>,
) => (
  await makeRequest.patch<AdminAPI.Property.Update.Response>(`/admin/properties/${id}/`, data)
);

export const updatePropertyContact = async (
  id: AdminAPI.Property.PropertyData["id"],
  data: Partial<AdminAPI.Property.UpdateContact.Request>,
) => (
  await makeRequest.put<AdminAPI.Property.UpdateContact.Response>(`/admin/properties/${id}/contact/`, data)
);

export const updatePartialPropertyContact = async (
  id: AdminAPI.Property.PropertyData["id"],
  data: Partial<AdminAPI.Property.UpdateContact.Request>,
) => (
  await makeRequest.patch<AdminAPI.Property.UpdateContact.Response>(`/admin/properties/${id}/contact/`, data)
);

export const getProperty = async (
  id: AdminAPI.Property.PropertyData["id"],
) => (
  await makeRequest.get<AdminAPI.Property.Get.Response>(`/admin/properties/${id}/`)
);

export const verifyProperty = async (
  id: AdminAPI.Property.PropertyData["id"],
) => (
  await makeRequest.post<AdminAPI.Property.Verify.Response>(`/admin/properties/${id}/verify/`)
);

export const rejectProperty = async (
  id: AdminAPI.Property.PropertyData["id"],
  data: AdminAPI.Property.Reject.Request,
) => (
  await makeRequest.post<AdminAPI.Property.Reject.Response>(`/admin/properties/${id}/reject/`, data)
);

export const getVerificationRequiredPropertyList = async (
  params: AdminAPI.Property.VerificationRequiredList.Request,
) => (
  await makeRequest.get<AdminAPI.Property.VerificationRequiredList.Response>("/admin/properties/needs-verification/", { params })
);

export const getPropertyTableList = async (
  params: AdminAPI.Property.PropertyTable.Request,
) => (
  await makeRequest.get<AdminAPI.Property.PropertyTable.Response>("/admin/properties/table/", { params })
);

export const getPropertyContactConversationList = async (
  id: string | number,
) => (
  await makeRequest.get<AdminAPI.Property.ConversationList.Response>(`/admin/properties/${id}/conversation/`)
);

export const postPropertyContactConversationCreate = async (
  id: string | number,
  data: AdminAPI.Property.ConversationCreate.Request | FormData,
) => {
  if (data instanceof FormData) {
    return await makeRequest.postForm<AdminAPI.Property.ConversationCreate.Response>(`/admin/properties/${id}/conversation/`, data);
  }
  return await makeRequest.post<AdminAPI.Property.ConversationCreate.Response>(`/admin/properties/${id}/conversation/`, data);
};

export const postPropertyTableExport = async (
  params: AdminAPI.Property.PropertyTableExport.Request,
) => (
  await makeRequest.post<AdminAPI.Property.PropertyTableExport.Response>("/admin/properties/table/export-csv/", {}, { params })
);

export const patchTableProperty = async (
  id: string | number,
  data: AdminAPI.Property.UpdateTableProperty.Request,
) => (
  await makeRequest.patch<AdminAPI.Property.UpdateTableProperty.Response>(`/admin/properties/table/${id}/`, data)
);

import React from "react";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select/dist/declarations/src/types";

type Option = {
  label: string;
  value: google.maps.LatLngLiteral;
};

export interface AddressSearchProps {
  initialValue?: google.maps.LatLngLiteral;
  onChange: (value: google.maps.LatLngLiteral | undefined) => void;
}

const AddressSearch: React.FC<AddressSearchProps> = (
  {
    initialValue,
    onChange,
  },
) => {
  const { t } = useTranslation();
  const [defaultOptions, setDefaultOptions] = React.useState<Option[]>([]);
  const [locationOption, setLocationOption] = React.useState<Option | null>(() => {
    if (initialValue) {
      return {
        label: JSON.stringify(initialValue),
        value: initialValue,
      };
    }
    return null;
  });
  const geocoderInstance = React.useMemo(() => (
    new google.maps.Geocoder()
  ), []);
  const promiseOptions = React.useCallback((address: string, callback: any) => {
    geocoderInstance.geocode({
      address,
      componentRestrictions: { country: "us" },
    }).then((res) => {
      const options = res.results.map((item) => ({
        label: item.formatted_address,
        value: {
          lat: item.geometry.location.lat(),
          lng: item.geometry.location.lng(),
        },
      }));
      callback(options);
      setDefaultOptions(options);
    });
  }, []);

  const handleChange = (value: SingleValue<Option>) => {
    setLocationOption(value);
    onChange(value?.value);
  };

  return (
    <AsyncSelect
      className="custom-react-select"
      classNamePrefix="react-select"
      placeholder={t("Search address")}
      onChange={handleChange}
      value={locationOption}
      loadingMessage={() => t("Searching for an address")}
      noOptionsMessage={() => t("No address found")}
      defaultOptions={defaultOptions}
      cacheOptions
      isClearable
      loadOptions={debounce(promiseOptions, 500)}
    />
  );
};

export default AddressSearch;

import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import type { SerializedStyles } from "@emotion/react";

type CommonTextProps = {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  css?: SerializedStyles | (SerializedStyles | undefined)[];
  fontWeight?: "normal" | "semibold" | "bold" | "bolder";
  fontSize?: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | string;
  alignment?: "start" | "center" | "end";
  verticalAlignment?: "start" | "center" | "end";
  inherit?: boolean;
  center?: boolean;
  color?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "muted" | string;
}

export type TextProps = CommonTextProps & React.ComponentProps<"span">;

const forwardedText = (
  {
    fontWeight,
    className: initialClassName,
    color,
    fontSize,
    center,
    inherit,
    alignment,
    verticalAlignment,
    onClick,
    role,
    tabIndex,
    css,
    ...props
  }: TextProps,
  ref?: React.Ref<HTMLElement>,
) => {
  const className: string = React.useMemo(() => {
    const classes = initialClassName ? initialClassName.split(" ") : [];

    if (fontWeight) {
      classes.push(`fw-${fontWeight}`);
    }

    if (fontSize) {
      classes.push(`fs-${fontSize}`);
    }

    if (color) {
      classes.push(`text-${color}`);
    }

    if (inherit) {
      classes.push("text-inherit");
    }
    if (verticalAlignment) {
      classes.push(`d-inline-flex flex-nowrap align-items-${verticalAlignment}`);
    }

    if (alignment) {
      classes.push(`text-${alignment}`);
      classes.push("d-block");
    }

    if (center) {
      classes.push("d-inline-flex align-items-center");
    }

    if (onClick) {
      classes.push("cursor-pointer");
    }

    return classes.join(" ");
  }, [fontWeight, fontSize, color, initialClassName, inherit, alignment, center, onClick]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      className={className}
      css={css}
      onClick={onClick}
      role={onClick ? "button" : role}
      tabIndex={onClick ? -1 : tabIndex}
      ref={ref}
      {...props}
    />
  );
};

export type LinkProps = CommonTextProps & RouterLinkProps & React.RefAttributes<HTMLAnchorElement>;

export type TitleProps = CommonTextProps & React.ComponentProps<"h1"> & {
  level: 1 | 2 | 3 | 4 | 5 | 6 | "1" | "2" | "3" | "4" | "5" | "6";
};

export type ExternalLinkProps = CommonTextProps & React.ComponentProps<"a"> & {
  blank?: boolean;
};

type CompoundedText = React.ForwardRefExoticComponent<TextProps> & {
  Link: React.FC<LinkProps>;
  ExternalLink: React.FC<ExternalLinkProps>;
  Title: React.FC<TitleProps>;
  Paragraph: React.FC<TextProps>;
};

const Text = React.forwardRef(forwardedText) as CompoundedText;

const Link: React.FC<LinkProps> = (
  {
    reloadDocument,
    replace,
    state,
    preventScrollReset,
    relative,
    to,
    ...props
  },
) => (
  (
    <RouterLink
      className="link-primary"
      reloadDocument={reloadDocument}
      replace={replace}
      state={state}
      preventScrollReset={preventScrollReset}
      relative={relative}
      to={to}
    >
      <Text {...props} />
    </RouterLink>
  )
);

const Paragraph: React.FC<TextProps> = (props) => (
  <Text {...props} className="d-block" />
);

const Title: React.FC<TitleProps> = (
  {
    level,
    ...props
  },
) => {
  const finalProps = React.useMemo(() => {
    const classNames = [];
    if (props.alignment) {
      classNames.push(`text-${props.alignment}`);
    }
    if (props.className) {
      classNames.push(props.className);
    }

    return {
      className: classNames.join(" "),
      children: props.children,
    };
  }, [props]);
  return (
    React.createElement(`h${level}`, finalProps)
  );
};

const ExternalLink: React.FC<ExternalLinkProps> = (
  {
    fontWeight,
    color,
    inherit,
    blank,
    className: initialClassName,
    children,
    ...props
  },
) => {
  const className: string = React.useMemo(() => {
    const classes = initialClassName ? initialClassName.split(" ") : [];

    if (fontWeight) {
      classes.push(`fw-${fontWeight}`);
    }

    if (color) {
      classes.push(`link-${color}`);
    }

    if (inherit) {
      classes.push("link-inherit");
    }

    return classes.join(" ");
  }, [fontWeight, color, initialClassName, inherit]);

  const extraProps = React.useMemo(() => {
    const extra: {[key: string]: string} = {};
    if (blank) {
      extra.target = "_blank";
      extra.rel = "noopener noreferrer";
    }
    return extra;
  }, [blank]);

  return (
    <a
      className={className}
      {...props}
      {...extraProps}
    >
      {children}
    </a>
  );
};

Text.Link = Link;
Text.Paragraph = Paragraph;
Text.ExternalLink = ExternalLink;
Text.Title = Title;

export default Text;

import React from "react";
import { useThemedStyles } from "src/hooks/styles";

import styles from "./Divider.styles";

type DividerProps = React.ComponentProps<"div"> & {
  text?: string;
  className?: string;
  style?: React.CSSProperties;
};

const Divider: React.FC<DividerProps> = (
  {
    text,
    style = {},
    className,
    ...props
  },
) => {
  const themedStyles = useThemedStyles(styles);

  if (!text) {
    return (
      <span className={className} style={{ ...themedStyles.noTextLine, ...style }} />
    );
  }

  return (
    <div className={className} style={{ ...themedStyles.textDivider, ...style }} {...props}>
      <span style={themedStyles.line} />
      <span style={themedStyles.text}>{text}</span>
      <span style={themedStyles.line} />
    </div>
  );
};

export default Divider;

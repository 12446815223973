import { makeRequest, makeAnonymousRequest } from "src/api/request";

export const getStateList = async () => (
  await makeAnonymousRequest.get<API.Area.List.Response>("/locations/areas/states/")
);

export const getStateWithBoundaryList = async () => (
  await makeAnonymousRequest.get<API.Area.ListWithBoundary.Response>("/locations/areas/states-with-boundary/")
);

export const getCityWithBoundaryList = async (params?: API.Area.ListWithBoundary.Request) => (
  await makeAnonymousRequest.get<API.Area.ListWithBoundary.Response>("/locations/areas/cities-with-boundary/", { params })
);

export const getAreaByParent = async (id: API.Area.AreaData["id"]) => (
  await makeRequest.get<API.Area.List.Response>(`/locations/areas/${id}/children/`)
);

export const getAreaList = async (params: API.Area.List.Request) => (
  await makeRequest.get<API.Area.List.Response>(`/locations/areas/`, { params })
);

export const getAreaSearchList = async (params: API.Area.Search.Request) => (
  await makeRequest.get<API.Area.Search.Response>(`/locations/areas/search/`, { params })
);

export const getAreaWithPropertyList = async (params: API.Area.List.Request) => (
  await makeRequest.get<API.Area.List.Response>(`/locations/areas/with-properties/`, { params })
);

export const getAreaWithScrapedList = async (params: API.Area.List.Request) => (
  await makeRequest.get<API.Area.List.Response>(`/locations/areas/with-scraped/`, { params })
);

export const listCityWithSTRByState = async (stateId: API.Area.AreaData["id"]) => (
  await makeRequest.get<API.Area.List.Response>(`/locations/areas/${stateId}/with-str/`)
);

export const getAreaBoundary = async (areaId: API.Area.AreaData["id"]) => (
  await makeRequest.get<API.Area.AreaBoundary.Response>(`/locations/areas/${areaId}/boundary/`)
);

import type { RouteObject } from "react-router-dom";
import React from "react";
import AdminRequiredWrapper from "src/pages/admin/AdminRequiredWrapper";
import lazyWithRetry from "src/utils/lazy-with-retry";

const Dashboard = lazyWithRetry(() => import("src/pages/admin/components/dashboard"));
const MembershipsPage = lazyWithRetry(() => import("src/pages/admin/memberships/MembershipsPage"));
const PurchasedServicesPage = lazyWithRetry(() => import("src/pages/admin/memberships/purchased-services"));

const membershipsRouter: RouteObject[] = [
  {
    path: "memberships/",
    element: <AdminRequiredWrapper><MembershipsPage /></AdminRequiredWrapper>,
    children: [
      {
        index: true,
        element: <AdminRequiredWrapper><Dashboard statisticKey="memberships" /></AdminRequiredWrapper>,
      },
      {
        path: "services/",
        element: <AdminRequiredWrapper><PurchasedServicesPage /></AdminRequiredWrapper>,
      },
      {
        path: "services/:page/",
        element: <AdminRequiredWrapper><PurchasedServicesPage /></AdminRequiredWrapper>,
      },
    ],
  },
];

export default membershipsRouter;

import { makeRequest } from "src/api/request";

export const getAgentMarketCampaignList = async (params?: AgentAPI.MarketCampaign.List.Request) => (
  await makeRequest.get<AgentAPI.MarketCampaign.List.Response>(`/agent/market-campaigns/`, { params })
);

export const getAgentMarketCampaignDetail = async (id: string | number) => (
  await makeRequest.get<AgentAPI.MarketCampaign.Detail.Response>(`/agent/market-campaigns/${id}/`)
);

export const patchAgentMarketCampaignUpdate = async (id: string | number, data: AgentAPI.MarketCampaign.Update.Request) => (
  await makeRequest.patch<AgentAPI.MarketCampaign.Update.Response>(`/agent/market-campaigns/${id}/`, data)
);

export const postAgentMarketCampaignCreateProperty = async (
  id: string | number,
  data: AgentAPI.MarketCampaign.SubmitProperty.Request,
) => (
  await makeRequest.post<AgentAPI.MarketCampaign.SubmitProperty.Response>(`/agent/market-campaigns/${id}/submit-property/`, data)
);

export const getAgentMarketCampaignPropertyList = async (
  id: string | number,
  params?: AgentAPI.MarketCampaign.PropertyList.Request,
) => (
  await makeRequest.get<AgentAPI.MarketCampaign.PropertyList.Response>(
    `/agent/market-campaigns/${id}/properties/`,
    { params },
  )
);

export const postAgentMarketCampaignReject = async (
  id: string | number,
  data: AgentAPI.MarketCampaign.Reject.Request,
) => (
  await makeRequest.post<AgentAPI.MarketCampaign.Reject.Response>(
    `/agent/market-campaigns/${id}/reject/`,
    data,
  )
);

export const deleteAgentMarketCampaign = async (id: string | number) => (
  await makeRequest.delete<AgentAPI.MarketCampaign.Delete.Response>(
    `/agent/market-campaigns/${id}/`,
  )
);

export const putAgentMarketCampaignPropertyBulkUpdate = async (
  id: string | number,
  data: AgentAPI.MarketCampaign.PropertyBulkUpdate.Request,
) => (
  await makeRequest.post<AgentAPI.MarketCampaign.PropertyBulkUpdate.Response>(
    `/agent/market-campaigns/${id}/properties/`,
    data,
  )
);

export const patchAgentMarketCampaignPropertyUpdate = async (
  id: string | number,
  { id: propertyId, ...data }: Partial<AgentAPI.MarketCampaign.PropertyUpdate.Request> & { id: number | string; },
) => (
  await makeRequest.patch<AgentAPI.MarketCampaign.PropertyUpdate.Response>(
    `/agent/market-campaigns/${id}/properties/${propertyId}/`,
    data,
  )
);

export const postAgentMarketCampaignParserExecutionCreate = async (
  id: string | number,
  data: AgentAPI.MarketCampaign.ParserExecutionCreate.Request,
) => (
  await makeRequest.post<AgentAPI.MarketCampaign.ParserExecutionCreate.Response>(
    `/agent/market-campaigns/${id}/parser-executions/`,
    data,
  )
);

export const postAgentMarketCampaignAddProperty = async (
  id: string | number,
  data: AgentAPI.MarketCampaign.AddPropertyToCampaign.Request,
) => (
  await makeRequest.post<AgentAPI.MarketCampaign.AddPropertyToCampaign.Response>(
    `/agent/market-campaigns/${id}/add-to-campaign/`,
    data,
  )
);

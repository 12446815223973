import React from "react";
import { HeaderGroup } from "@tanstack/react-table";
import TableHeader from "src/pages/admin/properties/table-page/components/TableHeader";

interface TableHeaderGroupProps {
  headerGroup: HeaderGroup<AdminAPI.Property.PropertyTableData>;
}

const TableHeaderGroup: React.FC<TableHeaderGroupProps> = (
  {
    headerGroup,
  },
) => (
  <tr key={headerGroup.id}>
    {headerGroup.headers.map((header) => (
      <TableHeader key={header.id} header={header} />
    ))}
  </tr>
);

export default TableHeaderGroup;

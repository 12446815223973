/** @jsxImportSource @emotion/react */
import { IClassBlock } from "src/hooks/styles";
import blueBg from "src/assets/images/login-blue-bg.url.svg";
import quoteBg from "src/assets/images/login-blue-quote.url.svg";
import { css } from "@emotion/react";

export type IStyles = IClassBlock<[
  "layout" |
  "wrapperOpen" |
  "wrapper" |
  "navbar" |
  "navbarNoSidebar" |
  "navbarUnauthorized" |
  "portal" |
  "navbarRight" |
  "content" |
  "contentOpen" |
  "scrollable" |
  "scrollableNoSidebar" |
  "footer" |
  "sideMenu" |
  "title" |
  "informationContainer" |
  "promo" |
  "promoImage" |
  "promoArticle" |
  "promoQuote" |
  "promoQuoteAuthor" |
  "promoQuoteAuthorLine" |
  "outletContainer"
]>;

const styles: IStyles = {
  layout: (theme) => css({
    height: "100vh",
  }),
  wrapper: (theme) => css({
    display: "grid",
    grid: `1fr/${theme.menu.sideWidth}px 1fr`,
    maxWidth: "100vw",
    transition: "all 0.3s ease",
    [theme.mq.sm]: {
      display: "block",
      grid: "none",
      position: "relative",
    },
  }),
  wrapperOpen: (theme) => css({
    grid: `1fr/${theme.menu.sideWidthOpen}px 1fr`,
  }),
  navbar: (theme) => css({
    height: theme.header.height,
    paddingLeft: theme.content.paddingX,
    paddingRight: theme.content.paddingX,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    transition: "all 0.3s ease",
    maxWidth: "100%",
    [theme.mq.md]: {
      paddingLeft: theme.content.paddingX / 2,
      paddingRight: theme.content.paddingX / 2,
    },
  }),
  navbarNoSidebar: (theme) => css({
    background: theme.colorBackground,
    zIndex: 890,
    boxShadow: `0 1px 0 ${theme.colorOutlineLight}`,
  }),
  navbarUnauthorized: (theme) => css({
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    maxWidth: "100vw",
  }),
  navbarRight: (theme) => css({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
  }),
  content: (theme) => css({
    height: `calc(100vh - ${theme.header.height}px)`,
    overflowY: "auto",
    maxWidth: `calc(100vw - ${theme.menu.sideWidth}px)`,
    [theme.mq.sm]: {
      maxWidth: "100vw",
    },
  }),
  contentOpen: (theme) => css({
    maxWidth: `calc(100vw - ${theme.menu.sideWidthOpen}px)`,
    [theme.mq.sm]: {
      maxWidth: "100vw",
    },
  }),
  scrollable: (theme) => css({
    minHeight: `calc(100vh - ${theme.header.height}px)`,
    paddingTop: theme.content.paddingY,
    paddingBottom: theme.content.paddingY / 2,
    paddingLeft: theme.content.paddingX,
    paddingRight: theme.content.paddingX,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    [theme.mq.sm]: {
      paddingLeft: theme.content.paddingX / 2,
      paddingRight: theme.content.paddingX / 2,
      paddingTop: theme.content.paddingY / 2,
      paddingBottom: theme.content.paddingY,
    },
  }),
  scrollableNoSidebar: (theme) => css({
    display: "block",
  }),
  portal: (theme) => css({
    paddingLeft: theme.spaces.md,
    paddingRight: theme.spaces.md,
    "&:empty": {
      display: "none",
    },
    [theme.mq.md]: {
      position: "fixed",
      width: "100%",
      left: 0,
      bottom: 0,
      background: theme.colorWhite,
      padding: theme.spaces.md,
      paddingLeft: 40,
      paddingRight: 40,
      boxShadow: `0 0 2px rgba(0,0,0,0.26)`,
    },
    [theme.mq.sm]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  }),
  footer: () => css({}),
  promo: (theme) => css({
    paddingLeft: theme.spaces.xl,
    paddingTop: theme.header.height + theme.spaces.xl,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  promoImage: (theme) => css({
    marginTop: 60,
    marginBottom: 20,
    marginLeft: "auto",
    width: "100%",
    maxWidth: 608,
    height: "auto",
  }),
  promoArticle: (theme) => css({
    color: theme.colorWhite,
    maxWidth: "80%",
  }),
  promoQuote: (theme) => css({
    paddingLeft: 45,
    paddingTop: 45,
    background: `url(${quoteBg}) no-repeat 0 0/auto`,
  }),
  promoQuoteAuthor: (theme) => css({
    paddingTop: theme.spaces.lg,
    paddingLeft: 45 + theme.spaces.lg,
    position: "relative",
  }),
  promoQuoteAuthorLine: (theme) => css({
    position: "absolute",
    top: theme.spaces.lg + 6,
    left: 0,
    width: 45,
    height: 1,
    background: theme.colorWhite,
  }),
  informationContainer: (theme) => css({
    minHeight: "100vh",
    background: `${theme.colorPrimary} no-repeat 100% 0/auto`,
    backgroundImage: `url(${blueBg})`,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  outletContainer: (theme) => css({
    paddingTop: theme.header.height,
    minHeight: "100vh",
  }),
  sideMenu: (theme) => css({
    zIndex: 990,
    [theme.mq.sm]: {
      position: "absolute",
      top: 0,
      left: 0,
      minHeight: "100vh",
    },
  }),
  title: (theme) => css({
    fontSize: theme.fontSizes.titles.h2,
  }),
};

export default styles;

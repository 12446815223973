import React from "react";
import { ChipMenu } from "src/components/ui/menu";
import { Outlet } from "react-router-dom";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";

const STRVerificationPage = () => {
  const hasVerificationPerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION);
  const hasDatabasePerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_STR_DATABASE_MODIFICATION);
  const items = React.useMemo(() => {
    const allowedItems = [];

    if (hasVerificationPerm) {
      allowedItems.push({ to: "/agent/str/verification/", title: "STR Verification" });
    }

    if (hasDatabasePerm) {
      allowedItems.push({ to: "/agent/str/database/", title: "STR Regulations" });
      allowedItems.push({ to: "/agent/str/state-database/", title: "STR Regulations state" });
    }

    return allowedItems;
  }, [hasVerificationPerm, hasDatabasePerm]);

  return (
    <section>
      <ChipMenu
        level={3}
        style={{ marginBottom: 24 }}
        items={items}
      />
      <Outlet />
    </section>
  );
};

export default STRVerificationPage;

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useAuthStore } from "src/store/account";
import PagePreloader from "src/components/loaders";
import Modal from "react-bootstrap/esm/Modal";
import { useBooleanState } from "src/hooks/boolean";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "src/components/ui/form/button";
import { Link, Navigate } from "react-router-dom";
import Text from "src/components/ui/text";
import { useProfileHasAnyPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";
import Divider from "src/components/ui/divider";
import BookCall from "src/components/book-call";

interface IDemoWrapper {
  children: JSX.Element;
  demoComponent?: JSX.Element;
  permission: MembershipPermission | MembershipPermission[];
}

const DemoWrapper: React.FC<IDemoWrapper> = (
  {
    children,
    demoComponent,
    permission,
  },
) => {
  const { isAuthenticated } = useAuthStore();
  const { t } = useTranslation();
  const {
    state: isModalShow,
    setTrue: showModal,
    setFalse: closeModal,
  } = useBooleanState(false);

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.bubbles = false;
    showModal();
    return false;
  };

  const canAccessFeature = useProfileHasAnyPermission(permission);

  if (!canAccessFeature) {
    return (
      <React.Suspense fallback={(<PagePreloader />)}>
        <div style={{ position: "relative" }}>
          {demoComponent || (
            <div className="px-5 text-center">
              <h3 className="mb-5">
                {isAuthenticated ? (
                  t("Oops! This feature is not available to your membership level.")
                ) : (
                  t("Oops! This feature is only available for Airvana members.")
                )}
              </h3>
              {isAuthenticated ? (
                <Link to="/membership/select/" className="text-decoration-none">
                  <Button>
                    {t("Upgrade your membership")}
                  </Button>
                </Link>
              ) : (
                <Link to="/sign-up/" className="text-decoration-none">
                  <Button>
                    {t("Sign up for free")}
                  </Button>
                </Link>
              )}
            </div>
          )}
          <a
            onClick={handleClick}
            tabIndex={-1}
            title=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
          />
        </div>
        <Modal show={isModalShow} onHide={closeModal}>
          <Modal.Header closeButton onHide={closeModal}>
            {isAuthenticated ? (
              t("Oops! This feature is not available to your membership level.")
            ) : (
              t("Oops! This feature is only available for Airvana members.")
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={{ span: 22, offset: 1 }}>
                <Text fontSize="7" alignment="start" style={{ marginBottom: 16 }}>
                  {isAuthenticated ? (
                    t("Upgrade your membership today to get nationwide access to all our features:")
                  ) : (
                    t("Sign up for free today to get nationwide access to all our features:")
                  )}
                </Text>
                <ul className="av-list av-list--small">
                  <li className="av-list__item">
                    {t("Evaluate thousands of properties at once with automated underwriting and evaluation")}
                  </li>
                  <li className="av-list__item">
                    {t("Customize and edit financial analysis for any property")}
                  </li>
                  <li className="av-list__item">
                    {t("Explore deals across hundreds of markets, all in one place")}
                  </li>
                  <li className="av-list__item">
                    {t("Sort and filter properties to view opportunities relevant your investment criteria")}
                  </li>
                  <li className="av-list__item">
                    {t("Receive automatic alerts for deals that match your investment criteria")}
                  </li>
                  <li className="av-list__item">
                    {t("Explore hundreds of new properties every day")}
                  </li>
                </ul>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {isAuthenticated && (
              <>
                <Link className="w-100 d-block" to="/membership/select/">
                  <Button className="w-100">
                    {t("Upgrade your membership")}
                  </Button>
                </Link>
                <Divider className="my-4 w-100" text={t("or") as string} />
                <BookCall className="w-100" buttonText={t("Speak with sales")} />
              </>
            )}
            {!isAuthenticated && (
              <Link className="w-100 d-block" to="/sign-up/">
                <Button className="w-100">
                  {t("Sign up for free")}
                </Button>
              </Link>
            )}
          </Modal.Footer>
        </Modal>
      </React.Suspense>
    );
  }

  return (
    <React.Suspense fallback={(<PagePreloader />)}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default DemoWrapper;

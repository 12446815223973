import type { RouteObject } from "react-router-dom";
import React from "react";
import AdminRequiredWrapper from "src/pages/admin/AdminRequiredWrapper";
import lazyWithRetry from "src/utils/lazy-with-retry";

const ScrapersPage = lazyWithRetry(() => import("src/pages/admin/scrapers/ScrapersPage"));
const ParserExecutionListPage = lazyWithRetry(() => import("src/pages/admin/scrapers/parser-executions/list"));
const ParserTaskListPage = lazyWithRetry(() => import("src/pages/admin/scrapers/parser-tasks/list"));
const MarketRequestsListPage = lazyWithRetry(() => import("src/pages/admin/scrapers/market-requests/list/MarketRequestListPage"));
const MarketCampaignListPage = lazyWithRetry(() => import("src/pages/admin/scrapers/market-campaigns/list/MarketCampaignListPage"));
const DashboardPage = lazyWithRetry(() => import("src/pages/admin/scrapers/dashboard"));

const scrapersRouter: RouteObject[] = [
  {
    path: "scrapers/",
    element: <AdminRequiredWrapper><ScrapersPage /></AdminRequiredWrapper>,
    children: [
      {
        index: true,
        element: <AdminRequiredWrapper><DashboardPage /></AdminRequiredWrapper>,
      },
      {
        path: "tasks/",
        element: <AdminRequiredWrapper><ParserTaskListPage /></AdminRequiredWrapper>,
      },
      {
        path: "tasks/:page/",
        element: <AdminRequiredWrapper><ParserTaskListPage /></AdminRequiredWrapper>,
      },
      {
        path: "executions/",
        element: <AdminRequiredWrapper><ParserExecutionListPage /></AdminRequiredWrapper>,
      },
      {
        path: "executions/:page/",
        element: <AdminRequiredWrapper><ParserExecutionListPage /></AdminRequiredWrapper>,
      },
      {
        path: "market-requests/",
        element: <AdminRequiredWrapper><MarketRequestsListPage /></AdminRequiredWrapper>,
      },
      {
        path: "market-requests/:page/",
        element: <AdminRequiredWrapper><MarketRequestsListPage /></AdminRequiredWrapper>,
      },
      {
        path: "market-campaigns/",
        element: <AdminRequiredWrapper><MarketCampaignListPage /></AdminRequiredWrapper>,
      },
      {
        path: "market-campaigns/:page/",
        element: <AdminRequiredWrapper><MarketCampaignListPage /></AdminRequiredWrapper>,
      },
    ],
  },
];

export default scrapersRouter;

import React from "react";
import UIFormControl, { FormControlProps as UIFormControlProps } from "react-bootstrap/esm/FormControl";
import Icon, { IconName } from "src/components/ui/icons";

type FormControlProps = UIFormControlProps & {
  icon?: React.ReactElement;
  autoComplete?: string;
  iconName?: IconName;
};

const forwardedFormControl = (
  {
    iconName,
    ...props
  }: FormControlProps,
  ref: React.ForwardedRef<typeof UIFormControl>,
) => {
  if (iconName) {
    return (
      <div className="form-control-with-icon">
        <Icon name={iconName} className="form-control-with-icon__icon" size={20} variant="primary" />
        <UIFormControl
          ref={ref}
          {...props}
        />
      </div>
    );
  }

  return (
    <UIFormControl
      ref={ref}
      {...props}
    />
  );
};

export default React.forwardRef(forwardedFormControl);

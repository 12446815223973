import { UseQueryResult } from "@tanstack/react-query/src/types";
import { AxiosError, AxiosResponse } from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getAgentMarketCampaignList,
  getAgentMarketCampaignDetail,
  patchAgentMarketCampaignUpdate,
  postAgentMarketCampaignCreateProperty,
  getAgentMarketCampaignPropertyList,
  postAgentMarketCampaignReject,
  putAgentMarketCampaignPropertyBulkUpdate,
  deleteAgentMarketCampaign,
  patchAgentMarketCampaignPropertyUpdate,
  postAgentMarketCampaignParserExecutionCreate,
  postAgentMarketCampaignAddProperty,
} from "src/api/agent/market-campaign";

type IUseAgentMarketCampaignListRequest = (
  params?: AgentAPI.MarketCampaign.List.Request,
) => UseQueryResult<
  AxiosResponse<AgentAPI.MarketCampaign.List.Response>,
  AxiosError<AgentAPI.MarketCampaign.List.ErrorResponse>
>;

export const useAgentMarketCampaignListRequest: IUseAgentMarketCampaignListRequest = (params) => (
  useQuery(
    ["marketCampaignList", "useAgentMarketCampaignListRequest", params],
    async () => await getAgentMarketCampaignList(params),
    {
      keepPreviousData: true,
    },
  )
);

type IUseAgentMarketCampaignDetailRequest = (
  id: number | string,
) => UseQueryResult<
  AxiosResponse<AgentAPI.MarketCampaign.Detail.Response>,
  AxiosError<AgentAPI.MarketCampaign.Detail.ErrorResponse>
>;

export const useAgentMarketCampaignDetailRequest: IUseAgentMarketCampaignDetailRequest = (id) => (
  useQuery(
    ["marketCampaignDetail", "useAgentMarketCampaignDetailRequest", id.toString()],
    async () => await getAgentMarketCampaignDetail(id),
  )
);

type IUseAgentMarketCampaignCreatePropertyRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.SubmitProperty.Response>,
  AxiosError<AgentAPI.MarketCampaign.SubmitProperty.ErrorResponse>,
  AgentAPI.MarketCampaign.SubmitProperty.Request
>;

export const useAgentMarketCampaignCreatePropertyRequest: IUseAgentMarketCampaignCreatePropertyRequest = (id) => (
  useMutation({
    mutationFn: async (data) => (
      await postAgentMarketCampaignCreateProperty(id, data)
    ),
  })
);

type IUseAgentMarketCampaignUpdateRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.Update.Response>,
  AxiosError<AgentAPI.MarketCampaign.Update.ErrorResponse>,
  AgentAPI.MarketCampaign.Update.Request
>;

export const useAgentMarketCampaignUpdateRequest: IUseAgentMarketCampaignUpdateRequest = (id) => (
  useMutation({
    mutationFn: async (data) => (
      await patchAgentMarketCampaignUpdate(id, data)
    ),
  })
);

type IUseAgentMarketCampaignRejectRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.Reject.Response>,
  AxiosError<AgentAPI.MarketCampaign.Reject.ErrorResponse>,
  AgentAPI.MarketCampaign.Reject.Request
>;

export const useAgentMarketCampaignRejectRequest: IUseAgentMarketCampaignRejectRequest = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => (
      await postAgentMarketCampaignReject(id, data)
    ),
    onSuccess: (data) => {
      queryClient.setQueriesData(
        ["marketCampaignList"],
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            data: {
              // @ts-ignore
              ...oldData.data,
              // @ts-ignore
              results: oldData.data.results.map((item) => {
                if (item.id === id) {
                  return data.data;
                }

                return item;
              }),
            },
          };
        },
      );
    },
  });
};

type IUseAgentMarketCampaignDeleteRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.Delete.Response>,
  AxiosError<AgentAPI.MarketCampaign.Delete.ErrorResponse>,
  AgentAPI.MarketCampaign.Delete.Request
>;

export const useAgentMarketCampaignDeleteRequest: IUseAgentMarketCampaignDeleteRequest = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => (
      await deleteAgentMarketCampaign(id)
    ),
    onSuccess: (data) => {
      queryClient.setQueriesData(
        ["marketCampaignList"],
        (oldData: any) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            data: {
              // @ts-ignore
              ...oldData.data,
              // @ts-ignore
              results: oldData.data.results.filter((item) => (
                item.id.toString() !== id.toString()
              )),
            },
          };
        },
      );
    },
  });
};

type IUseAgentMarketCampaignPropertyListRequest = (
  id: number | string,
  params?: AgentAPI.MarketCampaign.PropertyList.Request,
) => UseQueryResult<
  AxiosResponse<AgentAPI.MarketCampaign.PropertyList.Response>,
  AxiosError<AgentAPI.MarketCampaign.PropertyList.ErrorResponse>
>;

export const useAgentMarketCampaignPropertyListRequest: IUseAgentMarketCampaignPropertyListRequest = (id, params) => (
  useQuery(
    ["useAgentMarketCampaignPropertyListRequest", id.toString(), params],
    async () => await getAgentMarketCampaignPropertyList(id, params),
  )
);

type IUseAgentMarketCampaignPropertyBulkUpdateRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.PropertyBulkUpdate.Response>,
  AxiosError<AgentAPI.MarketCampaign.PropertyBulkUpdate.ErrorResponse>,
  AgentAPI.MarketCampaign.PropertyBulkUpdate.Request
>;

export const useAgentMarketCampaignPropertyBulkUpdateRequest: IUseAgentMarketCampaignPropertyBulkUpdateRequest = (id) => {
  const queryClient = useQueryClient();

  return (
    useMutation({
      mutationFn: async (data) => (
        await putAgentMarketCampaignPropertyBulkUpdate(id, data)
      ),
      onSuccess: (data) => {
        queryClient.setQueriesData(
          ["useAgentMarketCampaignPropertyListRequest", id.toString()],
          (oldData) => {
            if (!oldData) {
              return oldData;
            }

            const propertyById = data.data.reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {} as Record<AgentAPI.MarketCampaign.PropertyListData["id"], AgentAPI.MarketCampaign.PropertyListData>);

            return {
              ...oldData,
              data: {
                // @ts-ignore
                ...oldData.data,
                // @ts-ignore
                results: oldData.data.results.map((item) => {
                  if (propertyById[item.id]) {
                    return {
                      ...item,
                      market_campaign_status: propertyById[item.id].market_campaign_status,
                      market_campaign_status_display: propertyById[item.id].market_campaign_status_display,
                    };
                  }

                  return item;
                }),
              },
            };
          },
        );
      },
    })
  );
};

type IUseAgentMarketCampaignPropertyUpdateRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.PropertyUpdate.Response>,
  AxiosError<AgentAPI.MarketCampaign.PropertyUpdate.ErrorResponse>,
  Partial<AgentAPI.MarketCampaign.PropertyUpdate.Request> & { id: number | string; }
>;

export const useAgentMarketCampaignPropertyUpdateRequest: IUseAgentMarketCampaignPropertyUpdateRequest = (id) => {
  const queryClient = useQueryClient();

  return (
    useMutation({
      mutationFn: async (data) => (
        await patchAgentMarketCampaignPropertyUpdate(id, data)
      ),
      onSuccess: (data) => {
        queryClient.setQueriesData(
          ["useAgentMarketCampaignPropertyListRequest", id.toString()],
          // @ts-ignore
          (oldData) => {
            if (!oldData) {
              return oldData;
            }

            return {
              ...oldData,
              data: {
                // @ts-ignore
                ...oldData.data,
                // @ts-ignore
                results: oldData.data.results.map((item) => {
                  if (data.data.id === item.id) {
                    return {
                      ...item,
                      status: data.data.status,
                      status_display: data.data.status_display,
                      contact: data.data.contact,
                    };
                  }

                  return item;
                }),
              },
            };
          },
        );
      },
    })
  );
};

type IUseAgentMarketCampaignParserExecutionCreateRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.ParserExecutionCreate.Response>,
  AxiosError<AgentAPI.MarketCampaign.ParserExecutionCreate.ErrorResponse>,
  AgentAPI.MarketCampaign.ParserExecutionCreate.Request
>;

export const useAgentMarketCampaignParserExecutionCreateRequest:
  IUseAgentMarketCampaignParserExecutionCreateRequest = (id) => (
    useMutation({
      mutationFn: async (data) => (
        await postAgentMarketCampaignParserExecutionCreate(id, data)
      ),
    })
  );

type IUseAgentMarketCampaignAddPropertyRequest = () => UseMutationResult<
  AxiosResponse<AgentAPI.MarketCampaign.AddPropertyToCampaign.Response>,
  AxiosError<AgentAPI.MarketCampaign.AddPropertyToCampaign.ErrorResponse>,
  AgentAPI.MarketCampaign.AddPropertyToCampaign.Request & { id: number | string }
>;

export const useAgentMarketCampaignAddPropertyRequest:
  IUseAgentMarketCampaignAddPropertyRequest = () => (
    useMutation({
      mutationFn: async ({ id, ...data }) => (
        await postAgentMarketCampaignAddProperty(id, data)
      ),
    })
  );

import React from "react";
import { useTranslation } from "react-i18next";
import { useToastStore } from "src/store/toast";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import Button from "../../ui/form/button";

interface ReferralLinkProps {
  code: string;
  queryString?: string;
  url: string;
  title: string;
  className?: string;
}

const ReferralLink: React.FC<ReferralLinkProps> = (
  {
    code,
    url,
    title,
    className,
    queryString = "",
  },
) => {
  const { t } = useTranslation();
  const { addToastDanger, addToastSuccess } = useToastStore();
  const link = React.useMemo(() => (
    `${url}?referral=${code}${queryString}`
  ), [code, url, queryString]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(link).then(() => {
      addToastSuccess({ title: t("Referral link to \"{{title}}\" successfully copied to your clipboard.", { title }) });
    }).catch(() => {
      addToastDanger({
        title: t("Failed to copy link"),
      });
    });
  };

  return (
    <Form.Group className={className}>
      <Form.Label>{title}</Form.Label>
      <InputGroup>
        <FormControl
          value={link}
          readOnly
        />
        <Button
          variant="outline-primary"
          onClick={handleClick}
        >
          {t("Copy")}
        </Button>
      </InputGroup>
    </Form.Group>
  );
};

export default ReferralLink;

import { makeRequest } from "src/api/request";

export const getAccountAgentList = async (
  params?: AdminAPI.Account.AgentList.Request,
) => (
  await makeRequest.get<AdminAPI.Account.AgentList.Response>("/admin/accounts/agents/", { params })
);

export const getAccountProfileDetail = async (
  id: number | string,
) => (
  await makeRequest.get<AdminAPI.Account.ProfileDetail.Response>(`/admin/accounts/${id}/profile/`)
);

import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 5 * 1000,
      cacheTime: 60 * 1000,
      refetchInterval: false,
      retry: false,
    },
  },
});

export default queryClient;

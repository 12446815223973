import { Breakpoint, useResponsiveStore } from "src/store/responsive";
import React from "react";

const RESPONSIVE_LOWER_MAP: Record<Breakpoint, Breakpoint[]> = {
  xs: [],
  sm: ["xs"],
  md: ["xs", "sm"],
  lg: ["xs", "sm", "md"],
  xl: ["xs", "sm", "md", "lg"],
  xxl: ["xs", "sm", "md", "lg", "xl"],
  xxxl: ["xs", "sm", "md", "lg", "xl", "xxl"],
};

export const useBreakpointIsLower = (breakpoint: Breakpoint) => {
  const { breakpoint: currentBreakpoint } = useResponsiveStore();
  return React.useMemo(() => (
    RESPONSIVE_LOWER_MAP[breakpoint].includes(currentBreakpoint)
  ), [currentBreakpoint, breakpoint]);
};

export const useBreakpointIsLowerOrEqual = (breakpoint: Breakpoint) => {
  const { breakpoint: currentBreakpoint } = useResponsiveStore();
  return currentBreakpoint === breakpoint || RESPONSIVE_LOWER_MAP[breakpoint].includes(currentBreakpoint);
};

export const useBreakpointIsHigher = (breakpoint: Breakpoint) => {
  const { breakpoint: currentBreakpoint } = useResponsiveStore();
  return !RESPONSIVE_LOWER_MAP[breakpoint].includes(currentBreakpoint);
};

export const useBreakpointIsHigherOrEqual = (breakpoint: Breakpoint) => {
  const { breakpoint: currentBreakpoint } = useResponsiveStore();
  return currentBreakpoint === breakpoint || !RESPONSIVE_LOWER_MAP[breakpoint].includes(currentBreakpoint);
};

import React from "react";
import { Navigate } from "react-router-dom";
import PagePreloader from "src/components/loaders";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";

interface ILoginRequiredWrapper {
  children: JSX.Element;
  permission?: MembershipPermission;
}

const AgentRequiredWrapper: React.FC<ILoginRequiredWrapper> = (
  {
    children,
    permission = MembershipPermission.ACCESS_AGENT_DASHBOARD,
  },
) => {
  const hasPermission = useProfileHasPermission(permission);

  if (!hasPermission) {
    return (
      <h1>
        You do not have permission to access this page.
      </h1>
    );
  }

  return (
    <React.Suspense fallback={(<PagePreloader />)}>
      {children}
    </React.Suspense>
  );
};

export default AgentRequiredWrapper;

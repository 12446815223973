import React from "react";
import { useRouterParamId } from "src/hooks/router";
import { useAgentMarketCampaignDetailRequest } from "src/hooks/api/agent/market-campaign";
import CenterPreloader from "src/components/loaders/CenterPreloader";
import MarketCampaignWorkSummaryCard from "src/pages/agent/market-campaigns/components/MarketCampaignWorkSummaryCard";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ReferralLink from "src/components/account/referral/ReferralLink";
import { useProfileStore } from "src/store/profile";
import config from "src/config";
import MarketCampaignInvestorProfile from "src/pages/agent/market-campaigns/components/MarketCampaignInvestorProfile";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";

const MarketCampaignWorkDashboardPage: React.FC = () => {
  const id = useRouterParamId("id");
  const {
    referralCode,
  } = useProfileStore();
  const hasInvestorProfileViewPerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_MARKET_CAMPAIGNS);
  const {
    data,
    isFetching,
  } = useAgentMarketCampaignDetailRequest(id);

  if (isFetching) {
    return (
      <CenterPreloader />
    );
  }

  if (!data?.data) {
    return null;
  }

  return (
    <Row>
      {referralCode && (
        <Col xs={24} className="mb-4">
          <ReferralLink
            code={referralCode}
            queryString={`&market_campaign=${id}`}
            url={`${config.PUBLIC_URL}/landlords/submit/`}
            title="Send this link to a landlord"
          />
        </Col>
      )}
      <Col xs={24} sm={12} md={8} lg={6} className="mb-4">
        <MarketCampaignWorkSummaryCard
          name="Properties submitted by agent"
          value={data!.data.properties_submitted_by_agent.toString()}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} className="mb-4">
        <MarketCampaignWorkSummaryCard
          name="Properties submitted by referral link"
          value={data!.data.properties_submitted_by_referral.toString()}
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} className="mb-4">
        <MarketCampaignWorkSummaryCard
          name="Total properties submitted for campaign"
          value={data!.data.properties_submitted.toString()}
        />
      </Col>
      {hasInvestorProfileViewPerm && data?.data && (
        <Col xs={24}>
          <MarketCampaignInvestorProfile
            marketCampaign={data!.data}
          />
        </Col>
      )}
    </Row>
  );
};

export default MarketCampaignWorkDashboardPage;

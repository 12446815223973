/** @jsxImportSource @emotion/react */
import React from "react";
import styles, { IStyles } from "src/components/attachment/AttachmentWithNote.styles";
import { useThemedCss } from "src/hooks/styles";

interface AttachmentWithNoteProps {
  className?: string;
  link: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

const AttachmentWithNote: React.FC<AttachmentWithNoteProps> = (
  {
    className,
    link,
    title,
    subtitle,
  },
) => {
  const themedCss = useThemedCss<IStyles>(styles);

  const fileExt = React.useMemo(() => {
    const parsedLink = new URL(link);
    const ext = parsedLink.pathname.split(".").pop();

    if (!ext) {
      return "unknown";
    }

    return ext;
  }, [link]);

  return (
    <article className={className} css={themedCss.card}>
      <a
        css={themedCss.download}
        href={link}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {fileExt}
      </a>
      <div css={themedCss.content}>
        <h3 css={themedCss.title}>
          {title}
        </h3>
        <p css={themedCss.text}>
          {subtitle}
        </p>
      </div>
    </article>
  );
};

export default AttachmentWithNote;

/** @jsxImportSource @emotion/react */
import React from "react";
import { useAgentMarketCampaignDetailRequest } from "src/hooks/api/agent/market-campaign";
import { useRouterParamId } from "src/hooks/router";
import { useThemedCss } from "src/hooks/styles";
import styles, { IStyles } from "src/pages/agent/market-campaigns/work/MarketCampaignWorkPage.styles";
import MarketCampaignCard from "src/pages/agent/market-campaigns/components/MarketCampaignCard";
import CenterPreloader from "src/components/loaders/CenterPreloader";
import { Outlet } from "react-router-dom";
import { ChipMenu } from "src/components/ui/menu";
import { useTranslation } from "react-i18next";
import { useMetaTitleSetter } from "src/hooks/meta";
import { useBooleanState } from "src/hooks/boolean";
import Button from "src/components/ui/form/button";
import Icon from "src/components/ui/icons";

const MarketCampaignWorkPage: React.FC = () => {
  const id = useRouterParamId("id");
  const themedCss = useThemedCss<IStyles>(styles);
  const { t } = useTranslation();

  const {
    state: isSidebarOpen,
    toggle: toggleSidebar,
  } = useBooleanState(true);

  const {
    data,
    isFetching,
  } = useAgentMarketCampaignDetailRequest(id);

  const title = React.useMemo(() => {
    if (!data?.data) {
      return t("Market campaign");
    }

    return t("Market campaign: {{name}}", { name: `${data.data.market.name}, ${data.data.market_state.code}` });
  }, [data]);

  useMetaTitleSetter(title);

  const gridCss = React.useMemo(() => {
    if (isSidebarOpen) {
      return themedCss.grid;
    }

    return [themedCss.grid, themedCss.gridNoSidebar];
  }, [isSidebarOpen]);

  const sidebarToggle = React.useMemo(() => {
    if (isSidebarOpen) {
      return themedCss.sidebarToggle;
    }

    return [themedCss.sidebarToggle, themedCss.sidebarToggleClosed];
  }, [isSidebarOpen]);

  if (isFetching) {
    return (
      <CenterPreloader />
    );
  }

  return (
    <>
      <ChipMenu
        level={5}
        items={[
          { to: `/agent/market-campaigns/detail/${id}/`, title: "Overview" },
          { to: `/agent/market-campaigns/detail/${id}/properties/`, title: "Properties submitted" },
          { to: `/agent/market-campaigns/detail/${id}/rent-market/`, title: "Rent market" },
          { to: `/agent/market-campaigns/detail/${id}/contacts/`, title: "Contacts" },
          { to: `/agent/market-campaigns/detail/${id}/submit-property/`, title: "Submit a property" },
        ]}
      />
      <section css={gridCss} className="pt-4">
        <div css={themedCss.sidebar}>
          <Button
            variant="transparent"
            icon={<Icon size={16} name="arrow-left" />}
            css={sidebarToggle}
            onClick={toggleSidebar}
          />
          {isSidebarOpen && (
            <div css={themedCss.sticky}>
              <MarketCampaignCard
                className="mb-4"
                marketCampaign={data!.data}
                showLink={false}
              />
            </div>
          )}
        </div>
        <div className="overflow-scroll">
          {!data!.data.is_active && (
            <h3 className="text-danger text-center mb-5">
              Market campaign is not active
            </h3>
          )}
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default MarketCampaignWorkPage;

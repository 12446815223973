/** @jsxImportSource @emotion/react */
import React from "react";
import { css, keyframes } from "@emotion/react";
import { IClassBlock } from "src/hooks/styles";

export type IStyles = IClassBlock<[
  "card" |
  "placeholder" |
  "imagePlaceholder" |
  "pricePlaceholder" |
  "datePlaceholder" |
  "detail" |
  "addressPlaceholder" |
  "detailIconPlaceholder" |
  "detailTextPlaceholder" |
  "chip" |
  "chipLabelPlaceholder" |
  "chipValuePlaceholder" |
  "regulationPlaceholder"
]>;

const background = keyframes`
  from {
    background-color: #E4E6E8;
    // background: linear-gradient(45deg, 0% #000, 10% #fff, 100% #fff); 
  }
  50% {
    background-color: #d0d2d4;
  }
  to {
    background-color: #E4E6E8;
    //background: linear-gradient(45deg, 0% #fff, 90% #fff, 100% #000);
  }
`;

const styles: IStyles = {
  card: (theme) => css({}),
  placeholder: (theme) => css({
    display: "inline-block",
    backgroundColor: theme.colorOutline,
    borderRadius: 4,
    animation: `${background} 2s linear infinite`,
  }),
  pricePlaceholder: (theme) => css({
    height: 24,
    width: 92,
  }),
  datePlaceholder: (theme) => css({
    height: 20,
    width: 64,
    opacity: 0.5,
  }),
  detailIconPlaceholder: (theme) => css({
    opacity: 0.5,
    width: 18,
    height: 18,
  }),
  detail: (theme) => css({
    marginRight: theme.spaces.sm,
  }),
  detailTextPlaceholder: (theme) => css({
    height: 18,
    width: 24,
    opacity: 0.7,
  }),
  addressPlaceholder: (theme) => css({
    height: 18,
    width: "80%",
    opacity: 0.5,
  }),
  chip: (theme) => css({
    lineHeight: 0,
    fontSize: 0,
  }),
  chipLabelPlaceholder: (theme) => css({
    height: 13,
    width: "60%",
    opacity: 0.3,
    display: "block",
    marginBottom: theme.spaces.xxs,
  }),
  chipValuePlaceholder: (theme) => css({
    height: 16,
    width: "40%",
    opacity: 0.7,
  }),
  regulationPlaceholder: (theme) => css({
    height: 36,
    width: 36,
    opacity: 0.5,
    borderRadius: "50%",
  }),
  imagePlaceholder: (theme) => css({}),
};

export default styles;

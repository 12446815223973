import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import config from "src/config";
import router from "src/pages/router";
import reportWebVitals from "src/reportWebVitals";
import * as Sentry from "@sentry/react";

import "src/index.scss";
import debounce from "lodash.debounce";
import { useResponsiveStore } from "src/store/responsive";
import en from "src/locale/en.json";
import Toasts from "src/components/toast";
import ThemeProvider from "react-bootstrap/esm/ThemeProvider";
import queryClient from "src/query-client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if (config.SENTRY_DSN && config.ENVIRONMENT === "production") {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      /^ChunkLoadError.*/gi,
    ],
  });
}

i18n
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        translation: en,
      },
    },
    lng: "en",
    fallbackLng: "en",
    defaultNS: "translation",
    fallbackNS: "translation",
    ns: ["translation"],
    interpolation: {
      escapeValue: false,
    },
  });

const RouterWrapper: React.FC = () => (
  <>
    <RouterProvider router={router} />
    <Toasts />
  </>
);

const App: React.FC = () => {
  const { setWindowWidth } = useResponsiveStore();

  React.useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 150);

    window.addEventListener("resize", debouncedHandleResize, true);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize, true);
    };
  }, []);

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider
          breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs"]}
          minBreakpoint="xs"
        >
          <RouterWrapper />
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

root.render(<App />);

if (config.ENVIRONMENT === "development") {
  // eslint-disable-next-line no-console
  reportWebVitals(console.warn);
}

import moment from "moment";

export const formatDateTime = (date: string, format: string = "MMMM Do YYYY, h:mm:ss a"): string => {
  const dateObject = moment(date);
  return dateObject.format(format);
};

export const formatDate = (date: string, format: string = "MMM Do, YYYY"): string => {
  const dateObject = moment(date);
  return dateObject.format(format);
};

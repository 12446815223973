/* eslint-disable react/no-array-index-key */
import React from "react";
import Text from "src/components/ui/text";
import Icon from "src/components/ui/icons";

interface FormErrorProps {
  error?: string | string[];
  className?: string;
}

const FormError: React.FC<FormErrorProps> = (
  {
    error,
    className,
  },
) => {
  if (!error) {
    return null;
  }

  const classNames = React.useMemo(() => {
    if (className) {
      return `form-control-error ${className}`;
    }
    return "form-control-error";
  }, [className]);

  const icon = (
    <Icon name="hint" variant="danger" size={20} style={{ minWidth: 20 }} className="form-control-error__icon me-3" />
  );

  if (Array.isArray(error)) {
    return (
      <div className={classNames}>
        {error.map((errorItem, index) => (
          <Text
            key={`${index}-${errorItem}`}
            color="danger"
            fontSize="6"
            className="form-control-error form-control-error--with-icon"
          >
            {icon}
            {errorItem}
          </Text>
        ))}
      </div>
    );
  }

  return (
    <div className={classNames}>
      <Text
        color="danger"
        fontSize="6"
        className="form-control-error form-control-error--with-icon"
      >
        {icon}
        {error}
      </Text>
    </div>
  );
};

export default FormError;

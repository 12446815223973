import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "src/store/account";
import PagePreloader from "src/components/loaders";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";

interface ILoginRequiredWrapper {
  children: JSX.Element;
}

const AnonymousRequiredWrapper: React.FC<ILoginRequiredWrapper> = ({ children }) => {
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <React.Suspense fallback={(<PagePreloader />)}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default AnonymousRequiredWrapper;

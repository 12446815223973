import React from "react";
import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, Link, useRouteError } from "react-router-dom";

interface IRouterError {
  status: number;
  data?: {
    message?: string;
  }
}

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const error = useRouteError() as IRouterError;

  const title: string = React.useMemo(() => {
    if (isRouteErrorResponse(error)) {
      return t("Some error occurred.");
    }
    return t("Unknown error");
  }, [error]);

  return (
    <section>
      <h1>{title}</h1>

      {error.status && (
        <h2>{error.status}</h2>
      )}

      {error.data?.message && (
        <p>{error.data.message}</p>
      )}

      <Link to="/properties/">
        {t("Back to the properties")}
      </Link>
    </section>
  );
};

export default ErrorPage;

import { makeRequest, makeAnonymousRequest } from "src/api/request";

export const getPropertyList = async (params: API.Property.List.Request) => (
  await makeRequest.get<API.Property.List.Response>("/properties/", { params })
);

export const getApartmentPropertyList = async (params: API.Property.List.Request) => (
  await makeRequest.get<API.Property.List.Response>("/properties/apartments/", { params })
);

export const getVerifiedPropertyList = async (params: API.Property.List.Request) => (
  await makeRequest.get<API.Property.List.Response>("/properties/verified/", { params })
);

export const getArbitragePropertyList = async (params: API.Property.List.Request) => (
  await makeRequest.get<API.Property.List.Response>("/properties/arbitrage/", { params })
);

export const getCoHostPropertyList = async (params: API.Property.List.Request) => (
  await makeRequest.get<API.Property.List.Response>("/properties/co-host/", { params })
);

export const getPropertyMapList = async (params: API.Property.Map.Request) => (
  await makeRequest.get<API.Property.Map.Response>("/properties/map/", { params })
);

export const getApartmentPropertyMapList = async (params: API.Property.Map.Request) => (
  await makeRequest.get<API.Property.Map.Response>("/properties/apartments-map/", { params })
);

export const getVerifiedPropertyMapList = async (params: API.Property.Map.Request) => (
  await makeRequest.get<API.Property.Map.Response>("/properties/verified-map/", { params })
);

export const getArbitragePropertyMapList = async (params: API.Property.Map.Request) => (
  await makeRequest.get<API.Property.Map.Response>("/properties/arbitrage-map/", { params })
);

export const getCoHostPropertyMapList = async (params: API.Property.Map.Request) => (
  await makeRequest.get<API.Property.Map.Response>("/properties/co-host-map/", { params })
);

export const getPropertyDetail = async (
  id: API.Property.PropertyListData["id"],
) => (
  await makeRequest.get<API.Property.Detail.Response>(`/properties/${id}/`)
);

export const getPropertyDetailByCode = async (
  code: string,
) => (
  await makeAnonymousRequest.get<API.Property.Detail.Response>(`/properties/shared/${code}/`)
);

export const togglePropertyFavorite = async (id: API.Property.PropertyListData["id"]) => (
  await makeRequest.patch<API.Property.ToggleFavorite.Response>(`/properties/${id}/toggle-favorite/`)
);

export const togglePropertyDeal = async (id: API.Property.PropertyListData["id"]) => (
  await makeRequest.patch<API.Property.ToggleDeal.Response>(`/properties/${id}/toggle-deal/`)
);

export const getPropertyShareCode = async (id: API.Property.PropertyListData["id"]) => (
  await makeRequest.get<API.Property.ToggleDeal.Response>(`/properties/${id}/share-code/`)
);

export const getPublicPropertyList = async (params: API.Property.List.Request) => (
  await makeAnonymousRequest.get<API.Property.List.Response>("/properties/public/", { params })
);

export const getPublicPropertyDetail = async (
  id: API.Property.PropertyListData["id"],
) => (
  await makeAnonymousRequest.get<API.Property.Detail.Response>(`/properties/public/${id}/`)
);

export const createPropertyLandlord = async (
  data: API.Landlord.SubmitProperty.Request,
) => (
  await makeAnonymousRequest.post<API.Landlord.SubmitProperty.Response>("/properties/public/", data)
);

export const postPropertyPhotoLandlord = async (
  id: string | number,
  data: API.Landlord.SubmitPropertyPhoto.Request,
) => (
  await makeAnonymousRequest.postForm<API.Landlord.SubmitPropertyPhoto.Response>(
    `/properties/public/${id}/photos/`,
    data,
  )
);

export const updatePropertyLandlord = async (
  id: API.Landlord.LandlordPropertyData["id"],
  data: API.Landlord.UpdateProperty.Request,
) => (
  await makeAnonymousRequest.put<API.Landlord.UpdateProperty.Response>(`/properties/public/${id}/`, data)
);

export const findPropertyForVerification = async (
  data: API.Landlord.SearchProperty.Request,
) => (
  await makeAnonymousRequest.post<API.Landlord.SearchProperty.Response>("/properties/public/search/", data)
);

export const createLandlordConnect = async (
  id: API.Property.PropertyListData["id"],
) => (
  await makeRequest.post<API.Property.ConnectAgent.Response>(`/properties/${id}/connect/`)
);

export const underwritePropertyCustomAssumptions = async (
  id: API.Property.PropertyListData["id"],
  data: API.Property.CustomAssumptions.Request,
) => (
  await makeRequest.post<API.Property.CustomAssumptions.Response>(`/properties/${id}/underwrite/`, data)
);

export const getPropertyDealCustomAssumptions = async (
  id: API.Property.PropertyListData["id"],
) => (
  await makeRequest.get<API.Property.CustomAssumptions.Response>(`/properties/${id}/deal-assumptions/`)
);

export const getPropertyCustomCompSet = async (
  id: API.Property.PropertyListData["id"],
) => (
  await makeRequest.get<API.Property.CompSet.Response>(`/properties/${id}/comp-set/`)
);

export const getLandlordProperty = async (
  id: API.Property.PropertyListData["id"],
  params: API.Property.LandlordProperty.Request,
) => (
  await makeAnonymousRequest.get<API.Property.LandlordProperty.Response>(`/properties/${id}/landlord/`, { params })
);

export const deleteLandlordProperty = async (
  id: API.Property.PropertyListData["id"],
  params: API.Property.LandlordProperty.Request,
) => (
  await makeAnonymousRequest.delete<API.Property.LandlordProperty.Response>(`/properties/${id}/landlord/`, { params })
);

export const createPropertyCSVExport = async (
  { file_name: fileName, use_tracers_api: useTracersAPI, ...params }: API.Property.ListExport.Request,
) => (
  await makeRequest.post<API.Property.ListExport.Response>(
    `/properties/csv-export/`,
    { file_name: fileName, use_tracers_api: useTracersAPI },
    { params },
  )
);

export const createVerifiedPropertyCSVExport = async (
  { file_name: fileName, use_tracers_api: useTracersAPI, ...params }: API.Property.ListExport.Request,
) => (
  await makeRequest.post<API.Property.ListExport.Response>(
    `/properties/verified/csv-export/`,
    { file_name: fileName, use_tracers_api: useTracersAPI },
    { params },
  )
);

import React from "react";
import Text from "src/components/ui/text";
import * as Sentry from "@sentry/react";
import { FallbackRender } from "@sentry/react/types/errorboundary";
import Button from "src/components/ui/form/button";
import { useProfileStore } from "src/store/profile";

interface ErrorBoundaryProps {
  children: React.ReactElement;
}

const fallbackRenderer: FallbackRender = (
  {
    error,
    resetError,
    componentStack,
    eventId,
  },
) => {
  const {
    isEmailConfirmed,
    isPhoneConfirmed,
    isSuperuser,
    id,
    isNewAccount,
    email,
  } = useProfileStore();
  React.useEffect(() => {
    if (eventId && error) {
      Sentry.captureException(error, {
        user: {
          id: id.toString(),
          isEmailConfirmed,
          isPhoneConfirmed,
          isSuperuser,
          isNewAccount,
          email: `${email.slice(0, 4)}@${email.split("@")[1]}`,
        },
        tags: {
          errorBoundary: true,
        },
        extra: {
          componentStack,
        },
      });
    }
  }, [eventId, error]);
  return (
    <div className="py-5">
      <Text.Title level={3} color="primary" className="mb-5" alignment="center">
        Something went wrong.
      </Text.Title>
      <Text className="mb-4" alignment="center">
        Please try again later.
      </Text>
      <div className="text-center py-5">
        <Button onClick={() => window.location.reload()}>
          Reset page
        </Button>
      </div>

      <Text className="mb-2" fontSize="7" color="muted" alignment="center">
        {`Event ID: ${eventId}`}
      </Text>
    </div>
  );
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = (
  {
    children,
  },
) => (
  <Sentry.ErrorBoundary fallback={fallbackRenderer}>
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;

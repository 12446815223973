import { getAccountAgentList, getAccountProfileDetail } from "src/api/admin/account";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";

type IUseAccountAgentListRequest = (
  params?: AdminAPI.Account.AgentList.Request,
  enabled?: boolean,
) => UseQueryResult<
  AxiosResponse<AdminAPI.Account.AgentList.Response>,
  AxiosError<AdminAPI.Account.AgentList.ErrorResponse>
>;

export const useAccountAgentListRequest: IUseAccountAgentListRequest = (params, enabled) => (
  useQuery(
    ["useAccountAgentListRequest", params],
    async () => await getAccountAgentList(params),
    { enabled },
  )
);

type IUseProfileAdminDetailRequest = (
  id: number | string,
) => UseQueryResult<
  AxiosResponse<AdminAPI.Account.ProfileDetail.Response>,
  AxiosError<AdminAPI.Account.ProfileDetail.ErrorResponse>
>;

export const useProfileAdminDetailRequest: IUseProfileAdminDetailRequest = (id) => (
  useQuery(
    ["useProfileAdminDetailRequest", id.toString()],
    async () => await getAccountProfileDetail(id),
  )
);

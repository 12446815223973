import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  AsDateTime,
  AsDate,
  AsNumber,
  AsPrice,
} from "src/components/ui/formatters";
import ClipboardCell from "src/pages/admin/properties/table-page/components/ClipboardCell";
import Text from "src/components/ui/text";
import { PropertyStatus } from "src/enums/property";
import Icon from "src/components/ui/icons";

import type { ColumnDef } from "@tanstack/react-table";
import KeywordCategoryUpdateCell from "src/pages/admin/properties/table-page/components/KeywordCategoryUpdateCell";
import DescriptionCell from "src/pages/admin/properties/table-page/components/DescriptionCell";
import KeywordCategoryVerifiedCheckbox
  from "src/pages/admin/properties/table-page/components/KeywordCategoryVerifiedCheckbox";
import MarketCampaignCell from "src/pages/admin/properties/table-page/components/MarketCampaignCell";
import ContactWithTracersCell from "src/pages/admin/properties/table-page/components/ContactWithTracersCell";
import MarketCampaignPropertyContactConversationHistory
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyContactConversationHistory";
import MarketCampaignPropertyContactConversationModal
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyContactConversationModal";

export const defaultColumn: Partial<ColumnDef<AdminAPI.Property.PropertyTableData>> = {
  minSize: 50,
  maxSize: 500,
  enableResizing: true,
};

const columnHelper = createColumnHelper<AdminAPI.Property.PropertyTableData>();

const idColumn: ColumnDef<
  AdminAPI.Property.PropertyTableData,
  AdminAPI.Property.PropertyTableData["id"]
> = columnHelper.accessor("id", {
  header: "ID",
  size: 100,
});

const descriptionColumn: ColumnDef<
  AdminAPI.Property.PropertyTableData,
  AdminAPI.Property.PropertyTableData["description"]
> = columnHelper.accessor("description", {
  header: (context) => (
    <div className="d-flex flex-nowrap justify-content-between">
      Description
      <div className="d-flex flex-nowrap">
        <button
          type="button"
          className="btn btn-sm btn-secondary px-1 py-0 ms-2"
          onClick={() => (
            context.table.setState((prevState) => ({
              ...prevState,
              // @ts-ignore
              descriptionVisible: !prevState.descriptionVisible,
            }))
          )}
        >
          {
            // @ts-ignore
            context.table.getState().descriptionVisible ? "Hide description" : "Show full description"
          }
        </button>
      </div>
    </div>
  ),
  size: 300,
  maxSize: 1000,
  cell: (cell) => (
    <DescriptionCell cell={cell} />
  ),
});

const keywordCategoryColumn: ColumnDef<
  AdminAPI.Property.PropertyTableData,
  AdminAPI.Property.PropertyTableData["keyword_category"]
> = columnHelper.accessor("keyword_category", {
  header: "Keyword category",
  size: 180,
  cell: (cell) => (
    <KeywordCategoryUpdateCell property={cell.row.original} />
  ),
});

const externalUrlColumn: ColumnDef<
  AdminAPI.Property.PropertyTableData,
  AdminAPI.Property.PropertyTableData["external_url"]
> = columnHelper.accessor("external_url", {
  header: "Zillow URL",
  size: 110,
  cell: (cell) => (
    <ClipboardCell value={cell.getValue()} showExternalLink />
  ),
});

const keywordCategoryVerifiedColumn: ColumnDef<
  AdminAPI.Property.PropertyTableData,
  AdminAPI.Property.PropertyTableData["is_keyword_category_verified"]
> = columnHelper.accessor("is_keyword_category_verified", {
  header: "Keywords verified",
  size: 110,
  cell: (cell) => (
    <KeywordCategoryVerifiedCheckbox property={cell.row.original} />
  ),
});

export const keywordVerificationColumns: ColumnDef<AdminAPI.Property.PropertyTableData, any>[] = [
  idColumn,
  externalUrlColumn,
  descriptionColumn,
  keywordCategoryColumn,
  keywordCategoryVerifiedColumn,
];

const defaultColumns: ColumnDef<AdminAPI.Property.PropertyTableData, any>[] = [
  idColumn,
  {
    header: "Location",
    enableResizing: false,
    columns: [
      columnHelper.accessor("address", {
        header: "Address",
        size: 300,
      }),
      columnHelper.accessor("market.name", {
        header: "City",
        size: 200,
      }),
      columnHelper.accessor("market_state.code", {
        header: "State",
        size: 100,
      }),
      columnHelper.accessor("zip_code", {
        header: "Zip",
        size: 120,
      }),
      columnHelper.display({
        header: "Copy addr",
        size: 80,
        cell: (cell) => {
          const addressParts = [
            cell.row.original.address,
            cell.row.original.market.name,
            cell.row.original.market_state.code,
            cell.row.original.zip_code,
          ];
          return (
            <ClipboardCell value={addressParts.join(" ")} />
          );
        },
      }),
    ],
  },
  {
    header: "Property",
    enableResizing: false,
    columns: [
      columnHelper.accessor("bedrooms", {
        header: "Beds",
        size: 80,
        cell: (cell) => (
          <AsNumber value={cell.getValue()} />
        ),
      }),
      columnHelper.accessor("bathrooms", {
        header: "Baths",
        size: 80,
        cell: (cell) => (
          <AsNumber value={cell.getValue()} />
        ),
      }),
      columnHelper.accessor("rent_price", {
        header: "Rent",
        size: 120,
        cell: (cell) => (
          <AsPrice value={cell.getValue()} />
        ),
      }),
      columnHelper.accessor("home_type_display", {
        header: "Home type",
        size: 120,
      }),
    ],
  },
  {
    header: "Landlord",
    enableResizing: false,
    columns: [
      columnHelper.accessor("contact.business_name", {
        header: "Business name",
        size: 180,
        cell: (cell) => (
          cell.row.original.contact?.business_name || "N/A"
        ),
      }),
      columnHelper.accessor("contact.name", {
        header: "Name",
        size: 180,
        cell: (cell) => (
          <ContactWithTracersCell
            cell={cell}
            tracerColumn="name"
            originalValue={(
              <span>{cell.row.original.contact?.name || "N/A"}</span>
            )}
          />
        ),
      }),
      columnHelper.accessor("contact.email", {
        header: "Email",
        size: 200,
        cell: (cell) => (
          <ContactWithTracersCell
            cell={cell}
            tracerColumn="email"
            originalValue={(
              cell.row.original.contact?.email ? (
                <Text.ExternalLink href={`mailto:${cell.getValue()}`}>
                  {cell.getValue()}
                </Text.ExternalLink>
              ) : <span>N/A</span>
            )}
          />
        ),
      }),
      columnHelper.accessor("contact.phone", {
        header: "Phone",
        size: 180,
        cell: (cell) => (
          <ContactWithTracersCell
            cell={cell}
            tracerColumn="phone"
            originalValue={(
              cell.row.original.contact?.phone ? (
                <Text.ExternalLink href={`tel:${cell.row.original.contact.phone}`}>
                  {cell.row.original.contact.phone}
                </Text.ExternalLink>
              ) : <span>N/A</span>
            )}
          />
        ),
      }),
      columnHelper.accessor("is_account_edited", {
        header: "Landlord contact edited",
        size: 180,
        cell: (cell) => (
          cell.row.original.is_account_edited ? "Yes" : "No"
        ),
      }),
      columnHelper.accessor("contact.contact_status_display", {
        header: "Contact status",
        size: 80,
        cell: (cell) => (
          cell.row.original.contact?.contact_status_display || "N/A"
        ),
      }),
    ],
  },
  {
    header: "Submitted by",
    enableResizing: false,
    columns: [
      columnHelper.accessor("submitted_by.name", {
        header: "Name",
        size: 80,
        cell: (cell) => (
          cell.row.original.submitted_by ? (
            cell.row.original.submitted_by.name
          ) : "N/A"
        ),
      }),
      columnHelper.accessor("submitted_by.email", {
        header: "Email",
        size: 80,
        cell: (cell) => (
          cell.row.original.submitted_by ? (
            <Text.ExternalLink href={`mailto:${cell.row.original.submitted_by.email}`}>
              {cell.row.original.submitted_by.name}
            </Text.ExternalLink>
          ) : "N/A"
        ),
      }),
    ],
  },
  columnHelper.accessor("created_at", {
    header: "Created at",
    size: 80,
    cell: (cell) => (
      <AsDateTime value={cell.getValue()} />
    ),
  }),
  columnHelper.accessor("external_listed_at", {
    header: "Available for rent at",
    size: 80,
    cell: (cell) => (
      <AsDate value={cell.getValue()} />
    ),
  }),
  externalUrlColumn,
  columnHelper.accessor("status", {
    header: "Status",
    size: 100,
    cell: (cell) => (
      <Text>
        {cell.row.original.status_display}
        {cell.getValue() === PropertyStatus.VERIFIED && (
          <Icon name="check-mark-inverted" variant="primary" className="ms-1" />
        )}
      </Text>
    ),
  }),
  columnHelper.accessor("market_campaign_status", {
    header: "Market campaign status",
    size: 200,
    cell: (cell) => (
      cell.row.original.market_campaign_status === null ? "N/A" : cell.row.original.market_campaign_status_display
    ),
  }),
  columnHelper.display({
    header: "Add to market campaign",
    size: 200,
    cell: (cell) => (
      <MarketCampaignCell
        property={cell.row.original}
      />
    ),
  }),
  columnHelper.accessor("landlord_url", {
    header: "Landlord URL",
    size: 110,
    cell: (cell) => (
      <ClipboardCell value={cell.getValue()} showExternalLink />
    ),
  }),
  descriptionColumn,
  keywordCategoryColumn,
  keywordCategoryVerifiedColumn,
  columnHelper.display({
    header: "Landlord Conversation",
    cell: (cell) => (
      <MarketCampaignPropertyContactConversationModal
        prop={cell.row.original}
      />
    ),
  }),
];

export default defaultColumns;

/** @jsxImportSource @emotion/react */
import React from "react";
import { useThemedCss } from "src/hooks/styles";
import styles, { IStyles } from "src/components/loaders/PropertyCardLoader.styles";
import cardStyles, { IStyles as ICardStyles } from "src/components/property/PropertyCard.styles";
import Card from "react-bootstrap/esm/Card";
import Placeholder from "src/components/property/PropertyCardPlaceholder";

const PropertyCardLoader: React.FC = () => {
  const themedCss = useThemedCss<IStyles>(styles);
  const cardThemedCss = useThemedCss<ICardStyles>(cardStyles);

  return (
    <Card
      css={[themedCss.card, cardThemedCss.card]}
    >
      <Card.ImgOverlay
        css={cardThemedCss.imageOverlay}
        className="ratio ratio-16x10"
      >
        <Placeholder css={[themedCss.imagePlaceholder, cardThemedCss.image]} />
      </Card.ImgOverlay>
      <Card.Header css={cardThemedCss.header}>
        <div css={cardThemedCss.priceLine}>
          <span css={[themedCss.placeholder, themedCss.pricePlaceholder]} />
          <span css={[themedCss.placeholder, themedCss.datePlaceholder]} />
        </div>
        <div css={cardThemedCss.detailLine}>
          {[...Array(3)].map((_, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <span css={[cardThemedCss.detail, themedCss.detail]} key={key}>
              <span css={[themedCss.placeholder, themedCss.detailIconPlaceholder, cardThemedCss.detailIcon]} />
              <span css={[themedCss.placeholder, themedCss.detailTextPlaceholder]} />
            </span>
          ))}
        </div>

        <div css={cardThemedCss.addressLine}>
          <span css={[themedCss.addressPlaceholder, themedCss.placeholder]} />
        </div>
      </Card.Header>

      <Card.Body css={cardThemedCss.body}>
        <div css={cardThemedCss.bodyGrid}>
          {[...Array(5)].map((_, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <div css={[cardThemedCss.bodyChip, themedCss.chip]} key={key}>
              <span css={[cardThemedCss.bodyChipLabel, themedCss.placeholder, themedCss.chipLabelPlaceholder]} />
              <span css={[cardThemedCss.bodyChipValue, themedCss.placeholder, themedCss.chipValuePlaceholder]} />
            </div>
          ))}
          <div css={cardThemedCss.bodyRegulationContainer}>
            <span css={[themedCss.placeholder, themedCss.regulationPlaceholder]} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PropertyCardLoader;

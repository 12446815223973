/** @jsxImportSource @emotion/react */
import React from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Logo } from "src/components/ui/logo";
import Container from "react-bootstrap/esm/Container";
import Navbar from "react-bootstrap/esm/Navbar";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useThemedCss } from "src/hooks/styles";
import styles from "src/pages/Layout.styles";
import { useResponsiveStore } from "src/store/responsive";
import Text from "src/components/ui/text";
import homeEngineScreen from "src/assets/images/home-engine-screen.png";
import homeEngineScreen2x from "src/assets/images/home-engine-screen@2x.png";
import { setExternalNavigate } from "src/utils/navigate";
import { useBreakpointIsLower } from "src/hooks/responsive";
import { useStoreReferralFromQuery } from "src/hooks/referral";
import debounce from "lodash.debounce";

const Layout: React.FC = () => {
  useStoreReferralFromQuery();
  const themedCss = useThemedCss(styles);
  const location = useLocation();
  const isMobile = useBreakpointIsLower("md");

  const [contentStyles] = React.useState<React.CSSProperties>(() => (
    {
      paddingTop: 0,
      minHeight: "100vh",
    }
  ));

  const navigate = useNavigate();

  React.useEffect(() => setExternalNavigate(navigate), [navigate]);

  const fullScreen = React.useMemo(() => !(
    ((
      location.pathname.startsWith("/login")
      && !location.pathname.includes("forgot-password")
    ) || location.pathname.startsWith("/sign-up")) && !isMobile
  ), [location, isMobile]);

  const [navbarStyle, setNavbarStyle] = React.useState<React.CSSProperties>({});

  React.useEffect(() => {
    const handleWindowScroll = debounce(() => {
      const { scrollY } = window;

      let opacity = 1;
      if (scrollY < 180) {
        opacity = scrollY / 180;
      }

      if (opacity < 0.2) {
        setNavbarStyle({});
      } else {
        setNavbarStyle({
          backgroundColor: `rgba(255, 255, 255, ${opacity})`,
        });
      }
    }, 100);
    handleWindowScroll();
    window.addEventListener("scroll", handleWindowScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  const logoAppearance = React.useMemo(() => (
    (isMobile || fullScreen || !!navbarStyle.backgroundColor) ? "default" : "white"
  ), [fullScreen, isMobile, navbarStyle]);

  const promo = React.useMemo(() => {
    if (fullScreen) {
      return null;
    }
    if (location.pathname.startsWith("/login")) {
      return (
        <div css={themedCss.promo}>
          <article
            css={[
              themedCss.promoArticle,
              themedCss.promoQuote,
            ]}
          >
            {"Working with airvana has been so amazing! They helped me fully source and set up my property for our "
              + "company operations! They negotiated the lease with the landlord and also helped me design the "
              + "interior. I had so many options to pick from using the listing engine! "
              + "Their team was by my side at every step of our acquisition journey! Thank you!"}
            <div css={themedCss.promoQuoteAuthor}>
              <div css={themedCss.promoQuoteAuthorLine} />
              <Text alignment="start" inherit fontWeight="bold">
                Joselande
              </Text>
              <Text alignment="start" inherit fontSize="7">
                California
              </Text>
            </div>
          </article>
          <img
            src={homeEngineScreen}
            alt="Sourcing engine"
            srcSet={`${homeEngineScreen2x} 1.5x`}
            loading="lazy"
            css={themedCss.promoImage}
          />
        </div>
      );
    }
    if (location.pathname.startsWith("/sign-up")) {
      return (
        <div css={themedCss.promo}>
          <article css={themedCss.promoArticle}>
            <Text alignment="start" fontSize="1" fontWeight="normal" style={{ marginBottom: 16, lineHeight: 1.2 }}>
              Investing in Short-term-rentals has never been
              {" "}
              <Text fontWeight="bold">easier.</Text>
            </Text>
            <Text alignment="start" style={{ paddingRight: "30%", opacity: 0.8 }}>
              Airvana helps small businesses and investors locate, research, and acquire arbitrage opportunities. Get
              started today
            </Text>
          </article>
          <img
            src={homeEngineScreen}
            alt="Sourcing engine"
            srcSet={`${homeEngineScreen2x} 1.5x`}
            loading="lazy"
            css={themedCss.promoImage}
          />
        </div>
      );
    }
    return null;
  }, [location, fullScreen]);

  const navbarCss = React.useMemo(() => {
    if (isMobile) {
      return [themedCss.navbar, themedCss.navbarUnauthorized, themedCss.navbarNoSidebar];
    }
    return [themedCss.navbar, themedCss.navbarUnauthorized];
  }, [isMobile]);

  return (
    <div css={themedCss.layout}>
      <Navbar css={navbarCss} style={navbarStyle}>
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <Logo appearance={logoAppearance} />
            </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container fluid style={contentStyles}>
        <Row gutter={0}>
          {!fullScreen && (
            <Col css={themedCss.informationContainer} xs={12}>
              {promo}
            </Col>
          )}
          <Col css={themedCss.outletContainer} xs={fullScreen ? 24 : 12}>
            <main css={fullScreen ? undefined : themedCss.scrollable}>
              <Outlet />
            </main>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Layout;

/** @jsxImportSource @emotion/react */
import React from "react";
import { useThemedCss } from "src/hooks/styles";
import styles, { IStyles } from "src/components/photo-upload/PhotoUpload.styles";
import Button from "src/components/ui/form/button";
import Icon from "src/components/ui/icons";

const getFileID = (file: File) => `${file.size}-${Date.now()}`;

export type PhotoUploadValue = {
  file: File;
  id: string;
};

type PhotoPreviewProps = PhotoUploadValue & {
  isSingle: boolean;
  onDelete: (id: string) => void;
}

const PhotoPreview: React.FC<PhotoPreviewProps> = (
  {
    id,
    file,
    isSingle,
    onDelete,
  },
) => {
  const themedCss = useThemedCss<IStyles>(styles);
  const [preview, setPreview] = React.useState<string | null>(null);

  React.useEffect(() => {
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleClick = () => {
    onDelete(id);
  };

  return (
    <div className="ratio ratio-4x3">
      <div css={themedCss.photoPreviewContainer}>
        {preview ? (
          <img
            css={[themedCss.photoPreview, isSingle && themedCss.photoPreviewSingle]}
            src={preview}
            alt="Preview"
          />
        ) : (
          <span>
            Loading...
          </span>
        )}
        <Button
          variant="transparent"
          css={themedCss.photoDelete}
          onClick={handleClick}
          icon={(<Icon name="cross" size={24} variant="inherit" />)}
        />
      </div>
    </div>
  );
};

type StateUpload = PhotoUploadValue | null;
type StateUploadMultiple = PhotoUploadValue[];

type PhotoUploadProps<T = StateUpload> = {
  className?: string;
  onChange?: (files: T) => void;
  multiple?: boolean;
} & ({
  onChange: (files: StateUpload) => void;
  multiple: false;
} | {
  onChange: (files: StateUploadMultiple) => void;
  multiple: true;
});

const PhotoUpload = <T, >(
  {
    className,
    onChange,
    multiple = false,
  }: PhotoUploadProps<T>,
) => {
  const themedCss = useThemedCss<IStyles>(styles);
  const [files, setFiles] = React.useState<StateUpload | StateUploadMultiple>(() => {
    if (multiple) {
      return [] as StateUploadMultiple;
    }
    return null as StateUpload;
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      return;
    }

    if (multiple) {
      const newFiles = Array.from(event.target.files).map((file) => ({
        file,
        id: getFileID(file),
      }));
      setFiles((prevState) => [
        ...(prevState as StateUploadMultiple),
        ...newFiles,
      ]);
    } else {
      setFiles({
        file: event.target.files[0],
        id: getFileID(event.target.files[0]),
      });
    }
  };

  React.useEffect(() => {
    if (onChange) {
      if (multiple) {
        onChange(files as T);
      }
    }
  }, [files]);

  const handleDelete = (id: string) => {
    if (multiple) {
      setFiles((prevState) => (
        (prevState as StateUploadMultiple).filter((file) => file.id !== id)
      ));
      return;
    }

    setFiles(null);
  };

  return (
    <>
      {multiple && (
        <div css={themedCss.grid}>
          {(files as StateUploadMultiple).map((file) => (
            <PhotoPreview
              key={file.id}
              {...file}
              isSingle={false}
              onDelete={handleDelete}
            />
          ))}
        </div>
      )}

      {!multiple && files && (
        <PhotoPreview
          file={(files as StateUpload)!.file}
          id={(files as StateUpload)!.id}
          isSingle
          onDelete={handleDelete}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={className} css={themedCss.label}>
        <input
          type="file"
          css={themedCss.input}
          multiple={multiple}
          onChange={handleChange}
        />

        <div css={themedCss.dropArea}>
          <span css={themedCss.placeholder}>
            {multiple && (files as StateUploadMultiple).length === 0 ? (
              "Click in the area and select files or drag your files from your system to this box."
            ) : (
              "Click in the area and select a file or drag your file from your system to this box."
            )}
          </span>
        </div>
      </label>
    </>
  );
};

export default PhotoUpload;

/** @jsxImportSource @emotion/react */
import React, { HTMLAttributes } from "react";
import { useTheme } from "src/hooks/styles";
import { SerializedStyles } from "@emotion/react";
import lazyWithRetry from "src/utils/lazy-with-retry";

export type IconName =
  "arrow-left" |
  "arrow-down" |
  "arrow-external" |
  "chart" |
  "cross" |
  "map-location" |
  "map-pin" |
  "filter" |
  "furnishing" |
  "check-mark" |
  "check-out" |
  "check-mark-inverted" |
  "documents" |
  "email" |
  "exit" |
  "chain" |
  "fingerprint" |
  "gear" |
  "help" |
  "hint" |
  "letter-notification" |
  "lock" |
  "note" |
  "pie-chart" |
  "property-search" |
  "more" |
  "retry" |
  "bed" |
  "bath" |
  "heart" |
  "note-pen" |
  "building" |
  "paper-clip" |
  "video-play" |
  "video-play-circle" |
  "ruler" |
  "time-oclock" |
  "check" |
  "wallet" |
  "phone" |
  "share" |
  "home-up" |
  "lightning" |
  "eye" |
  "plus" |
  "minus" |
  "membership-check" |
  "card-mc" |
  "card-visa" |
  "jogger" |
  "shining" |
  "tools" |
  "communication" |
  "search-doc" |
  "search";

export type IconType = "default"
  | "primary"
  | "secondary"
  | "danger"
  | "warning"
  | "success"
  | "info"
  | "inherit"
  | "light"
  | "muted"
  | string;

interface IconProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  name: IconName;
  size?: number;
  variant?: IconType;
  css?: SerializedStyles | SerializedStyles[];
  style?: React.CSSProperties;
}

interface FallbackIconProps {
  size: IconProps["size"];
  style?: React.CSSProperties;
}

const FallbackIcon: React.FC<FallbackIconProps> = (
  {
    size,
    style = {},
  },
) => (
  <div
    style={{
      ...style,
      width: size,
      height: size,
      backgroundColor: "transparent",
    }}
  />
);

const Icon: React.FC<IconProps> = (
  {
    className,
    name,
    css,
    style = {},
    variant = "inherit",
    size = 16,
    ...props
  },
) => {
  const {
    colorPrimary,
    colorTextSecondary,
    colorTextPrimary,
    colorDanger,
    colorWarning,
    colorSuccess,
    colorWhite,
    colorTextMuted,
  } = useTheme();
  const color: string = React.useMemo(() => {
    if (variant === "primary") {
      return colorPrimary;
    }
    if (variant === "secondary") {
      return colorTextSecondary;
    }
    if (variant === "danger") {
      return colorDanger;
    }
    if (variant === "warning") {
      return colorWarning;
    }
    if (variant === "success") {
      return colorSuccess;
    }
    if (variant === "light") {
      return colorWhite;
    }
    if (variant === "muted") {
      return colorTextMuted;
    }
    if (variant === "inherit") {
      return "currentColor";
    }
    return colorTextPrimary;
  }, [variant]);

  const transformedName = React.useMemo(() => (
    name.split("-").map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join("")
  ), [name]);

  const Svg = React.useMemo(() => lazyWithRetry(
    () => import(
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      `./autogenerated/${transformedName}`
    ),
  ), [transformedName]);

  return (
    <React.Suspense fallback={(<FallbackIcon style={style} size={size} />)}>
      <Svg
        css={css}
        className={className}
        width={size}
        height={size}
        style={style}
        color={color}
        {...props}
      />
    </React.Suspense>
  );
};

export default Icon;

import React from "react";
import { useParams } from "react-router-dom";

export const useRouterParamId = (key: string): number => {
  const params = useParams<{ [key: string]: string | undefined }>();
  const id = React.useMemo(() => {
    if (params && params[key]) {
      return parseInt(params[key]!, 10);
    }
    return -1;
  }, [params, key]);

  return Number.isNaN(id) ? -1 : id;
};

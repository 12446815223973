import React from "react";
import lazyWithRetry from "src/utils/lazy-with-retry";
import { PageTutorialProps } from "src/components/tutorial/PageTutorial";
import { createPortal } from "react-dom";
import { useProfileStore } from "src/store/profile";
import { useTutorialStore } from "src/store/tutorial";

const PageTutorialPortal: React.FC<PageTutorialProps> = (props) => {
  const {
    isTutorialCompleted,
  } = useProfileStore();

  const PageTutorialLazy = React.useMemo(() => {
    if (window.localStorage.getItem("isTutorialCompleted") === "true") {
      return null;
    }

    if (!isTutorialCompleted) {
      return lazyWithRetry(
        () => import("src/components/tutorial/PageTutorial"),
      );
    }

    return null;
  }, [isTutorialCompleted]);

  if (!PageTutorialLazy || isTutorialCompleted) {
    return null;
  }

  return createPortal(
    (
      <React.Suspense>
        <PageTutorialLazy {...props} />
      </React.Suspense>
    ),
    document.getElementById("tutorial-portal-root") as HTMLElement,
  );
};

export default PageTutorialPortal;

import type { RouteObject } from "react-router-dom";
import React from "react";
import AgentRequiredWrapper from "src/pages/agent/AgentRequiredWrapper";
import lazyWithRetry from "src/utils/lazy-with-retry";
import { MembershipPermission } from "src/enums/permission";

const AgentsPage = lazyWithRetry(() => import("src/pages/agent/agents/AgentsPage"));
const AgentsOverviewPage = lazyWithRetry(() => import("src/pages/agent/agents/overview/AgentsOverviewPage"));
const AgentsStatisticsPage = lazyWithRetry(() => import("src/pages/agent/agents/statistics/AgentsStatisticsPage"));

const scrapersRouter: RouteObject[] = [
  {
    path: "agents/",
    element: (
      <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STATISTICS}>
        <AgentsPage />
      </AgentRequiredWrapper>
    ),
    children: [
      {
        index: true,
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STATISTICS}>
            <AgentsOverviewPage />
          </AgentRequiredWrapper>
        ),
      },
      {
        path: "statistics/",
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STATISTICS}>
            <AgentsStatisticsPage />
          </AgentRequiredWrapper>
        ),
      },
    ],
  },
];

export default scrapersRouter;

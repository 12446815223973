/* eslint-disable max-len */
import React from "react";
import { useTheme } from "src/hooks/styles";

interface LogoProps {
  appearance?: "default" | "white";
  className?: string;
  showBeta?: boolean;
  animated?: boolean;
}

const Logo: React.FC<LogoProps> = (
  {
    className,
    appearance = "default",
    showBeta = true,
    animated = false,
  },
) => {
  const { colorPrimary } = useTheme();
  const color = React.useMemo(() => {
    if (appearance === "white") {
      return "#fff";
    }
    return "#252323";
  }, [appearance]);
  const [currentHighlight, setCurrentHighlight] = React.useState(() => (
    Math.floor(Math.random() * 7)
  ));

  React.useEffect(() => {
    if (!animated) {
      return () => {};
    }

    const interval = setInterval(() => {
      setCurrentHighlight((prev) => {
        if (prev === 6) {
          return 0;
        }
        return prev + 1;
      });
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [colors, setColors] = React.useState<string[]>(() => (
    Array.from({ length: 7 }).map(() => color)
  ));

  React.useEffect(() => {
    if (!animated) {
      setColors(Array.from({ length: 7 }).map(() => color));
      return;
    }
    setColors(
      Array.from({ length: 7 }).map((_, i) => {
        const prevIndex = currentHighlight - 1 < 0 ? 6 : currentHighlight - 1;
        const nextIndex = (currentHighlight + 1) % 7;
        if (i === currentHighlight || i === nextIndex || i === prevIndex) {
          return colorPrimary;
        }
        return color;
      }),
    );
  }, [animated, color, currentHighlight]);

  const classNames = React.useMemo(() => {
    const classes = [];
    if (className) {
      classes.push(className);
    }
    if (animated) {
      classes.push("av-logo-animated");
    }
    return classes.join(" ");
  }, [className, animated]);

  return (
    <svg className={classNames} xmlns="http://www.w3.org/2000/svg" width="182" height="37" viewBox="0 0 182 37" fill="none">
      <path
        d="M20.5268 8.6717C20.2734 8.99545 19.7896 9.98982 19.4671 10.8686C19.1215 11.7704 18.7529 12.5336 18.6377 12.6261C18.5225 12.6954 18.4304 12.9267 18.4304 13.1579C18.4304 13.3892 18.177 14.0829 17.8544 14.7073C17.5319 15.3317 17.2785 15.9098 17.2785 16.0023C17.2785 16.0948 17.0251 16.7192 16.7256 17.3898C16.4261 18.0604 16.0114 19.1242 15.781 19.7254C15.5506 20.3267 15.2742 20.8817 15.182 20.9511C15.0668 21.0204 14.9747 21.2286 14.9747 21.3904C14.9747 21.5754 14.7213 22.2692 14.4448 22.9398C14.1453 23.6104 13.7537 24.5354 13.5924 24.9748C13.4081 25.4142 13.1316 25.9923 12.9704 26.2467C12.8091 26.5011 12.6709 27.0792 12.6709 27.5648C12.6709 28.6517 13.5233 29.3454 14.3757 28.9523C15.0208 28.6517 16.0805 26.5936 17.3937 23.0092C18.5686 19.8179 21.4023 13.0654 21.6096 12.9961C21.7248 12.9729 21.8861 13.1579 21.9552 13.4586C22.1165 14.1292 23.1762 16.8117 23.6139 17.6211C23.7982 17.9448 23.9595 18.3611 23.9595 18.5461C23.9595 18.7311 24.1668 19.2398 24.3972 19.7254C24.6276 20.1879 24.904 20.8586 24.9732 21.1823C25.0653 21.5061 25.3418 22.2692 25.5952 22.8704C26.102 24.0036 26.4476 24.7898 26.724 25.5529C27.9681 28.7673 28.4749 29.3454 29.6268 28.9061C30.6866 28.4898 30.5253 26.7786 29.2121 24.2811C28.9818 23.8417 28.7975 23.3098 28.7975 23.1248C28.7975 22.9398 28.5901 22.4542 28.3367 22.0842C28.0833 21.6911 27.8759 21.2748 27.8759 21.1129C27.8759 20.9511 27.6225 20.2804 27.323 19.6098C27.0235 18.9392 26.4015 17.4361 25.9408 16.3029C25.457 15.1467 24.9501 13.9673 24.7889 13.6436C24.6046 13.3198 24.3511 12.6492 24.1899 12.1404C24.0286 11.6317 23.7521 11.0073 23.6139 10.7529C23.4527 10.4986 23.1992 9.89732 23.038 9.4117C22.6003 8.0242 21.3562 7.6542 20.5268 8.6717Z"
        fill={colors[0]}
      />
      <path
        d="M39.6253 8.55635C39.1876 8.99573 39.1645 9.31948 39.1645 18.7082C39.1645 29.3689 39.1185 28.9989 40.5929 28.9526C40.9845 28.9526 41.4223 28.8139 41.5605 28.6751C41.837 28.4207 42.0673 10.337 41.8139 9.31948C41.5144 8.11698 40.4316 7.74698 39.6253 8.55635Z"
        fill={colors[1]}
      />
      <path
        d="M53.402 8.34813C53.0104 8.55626 52.9873 9.29626 52.9873 18.6388V28.675L53.5863 28.9063C54.3927 29.23 54.5309 29.2069 55.0608 28.675C55.4985 28.2356 55.5215 27.9119 55.5215 19.5175V10.8225L58.1248 10.915C60.5899 10.9844 60.7972 11.0306 61.6035 11.655C64.322 13.7131 63.1701 17.7831 59.7375 18.3613C59.0463 18.5 58.7468 18.6619 58.6547 19.0319C58.3782 20.0725 58.5395 20.5581 59.6684 22.0613C60.3134 22.9169 60.8203 23.68 60.8203 23.7725C60.8203 23.865 61.3962 24.6975 62.0873 25.6225C62.7785 26.5475 63.3544 27.4263 63.3544 27.5419C63.3544 27.6575 63.5157 27.8194 63.7 27.8888C63.8843 27.9813 64.0456 28.1663 64.0456 28.3513C64.0456 29.1144 65.9347 29.3225 66.3954 28.6056C66.6719 28.1663 66.6028 27.1256 66.2802 26.7788C66.119 26.6169 65.3818 25.53 64.6215 24.3969C63.8613 23.2406 63.124 22.2 62.9858 22.0844C62.8476 21.9456 62.5711 21.5756 62.3868 21.2519C62.0182 20.6506 62.0182 20.6275 62.9858 19.9569C67.1787 16.9969 66.764 11.1231 62.2025 8.85688C60.7051 8.11688 60.682 8.09376 57.2494 8.11688C55.3142 8.11688 53.6094 8.20938 53.402 8.34813Z"
        fill={colors[2]}
      />
      <path
        d="M73.422 8.51007C73.1916 8.74132 73.0304 9.11132 73.0304 9.31945C73.0304 9.52757 73.7676 11.4007 74.6661 13.4588C75.5645 15.5401 76.5091 17.6907 76.7395 18.2688C76.9929 18.8469 77.4076 19.7026 77.638 20.1651C77.8914 20.6276 78.0987 21.1132 78.0987 21.252C78.0987 21.3676 78.4443 22.2232 78.859 23.1713C79.2967 24.0963 79.8496 25.322 80.08 25.9001C81.3471 28.8832 81.8078 29.4151 82.7985 28.9526C83.3744 28.6982 83.6739 28.1895 85.0332 25.0213C85.2866 24.4895 85.6782 23.6107 85.9316 23.0557C86.1851 22.5007 86.3924 21.9688 86.3924 21.8301C86.3924 21.7145 86.6689 21.0438 86.9914 20.3501C88.1663 17.8294 88.4658 17.1819 89.9402 13.8288C91.7372 9.71257 91.8754 9.15757 91.3225 8.5332C90.378 7.49257 89.18 8.27882 88.581 10.3369C88.3737 11.0076 88.1202 11.5626 88.0051 11.5626C87.8668 11.5626 87.7747 11.7707 87.7747 12.0251C87.7747 12.2794 87.4521 13.1813 87.0375 14.0369C86.6458 14.9157 86.2081 15.8638 86.0929 16.1876C85.8625 16.7888 85.7013 17.1819 84.5494 19.7257C84.1808 20.6045 83.8582 21.4138 83.8582 21.5294C83.8582 21.6682 83.6509 22.1538 83.3975 22.6163C83.1671 23.0788 82.8906 23.7495 82.8215 24.0963C82.6602 24.9751 82.0843 24.9057 81.9 24.0038C81.8078 23.6107 81.4853 22.8245 81.1858 22.2463C80.8863 21.6682 80.6329 21.0438 80.6329 20.8357C80.6329 20.6276 80.4947 20.2576 80.3104 20.0032C80.1491 19.7488 79.8727 19.1244 79.7114 18.6157C79.5501 18.1301 79.1585 17.2282 78.882 16.627C78.5825 16.0257 78.3291 15.4707 78.3291 15.3782C78.3291 15.3088 78.0757 14.6613 77.7532 13.9676C77.4306 13.2738 77.1772 12.6032 77.1772 12.5107C77.1772 12.3951 76.9238 11.7707 76.6013 11.1463C76.2787 10.4988 76.0253 9.8282 76.0253 9.62007C76.0253 9.04195 74.9656 8.09382 74.3435 8.09382C74.044 8.09382 73.6294 8.27882 73.422 8.51007Z"
        fill={colors[3]}
      />
      <path
        d="M104.546 8.3715C104.339 8.53337 103.97 9.13462 103.717 9.71275C103.464 10.2909 103.049 11.239 102.772 11.8402C102.519 12.4415 102.289 13.0196 102.289 13.1121C102.289 13.2277 102.012 13.8059 101.713 14.4534C101.39 15.0777 101.137 15.7484 101.137 15.9102C101.137 16.0952 100.952 16.5115 100.699 16.8352C100.469 17.1821 100.215 17.8527 100.123 18.3152C100.031 18.8009 99.8466 19.3096 99.6853 19.4715C99.524 19.6334 99.2476 20.2346 99.0633 20.8127C98.902 21.3909 98.6716 21.969 98.5795 22.0846C98.4873 22.2234 97.9344 23.4952 97.3354 24.9521C96.1375 27.8659 96.1375 28.444 97.2202 28.9065C97.7962 29.1377 98.0035 29.1377 98.4873 28.814C98.8099 28.6059 99.0633 28.3284 99.0633 28.1896C99.0633 28.0509 99.3167 27.4265 99.6392 26.8021C99.9387 26.1777 100.146 25.669 100.1 25.669C100.008 25.669 100.423 24.6284 101.183 22.9402C101.436 22.3852 101.943 21.1365 102.335 20.1652C102.703 19.1709 103.21 17.9684 103.464 17.4365C103.694 16.9277 103.901 16.3496 103.901 16.1646C103.901 15.9796 104.039 15.6327 104.224 15.3784C104.408 15.124 104.685 14.4765 104.846 13.9215C105.191 12.719 105.514 12.7652 105.906 14.0834C106.067 14.6384 106.274 15.1934 106.389 15.3552C106.505 15.494 106.735 16.0259 106.896 16.5346C107.057 17.0434 107.426 18.0146 107.726 18.6852C108.025 19.3559 108.278 20.0265 108.278 20.1884C108.278 20.3502 108.371 20.5815 108.486 20.7202C108.578 20.8359 109.154 22.1309 109.73 23.5877C111.389 27.8196 111.895 28.8371 112.448 28.9759C113.416 29.2302 114.268 28.6059 114.268 27.6115C114.268 27.2877 112.817 23.6571 112.057 22.1077C111.872 21.7377 111.734 21.2984 111.734 21.1365C111.734 20.9746 111.527 20.489 111.296 20.0727C111.043 19.6565 110.72 18.8702 110.559 18.3152C110.398 17.7834 110.214 17.344 110.122 17.344C110.006 17.344 109.868 17.0665 109.799 16.7427C109.523 15.679 106.85 9.389 106.389 8.71837C105.906 8.04775 105.145 7.909 104.546 8.3715Z"
        fill={colors[4]}
      />
      <path
        d="M123.138 8.78774C122.838 9.34274 122.792 10.8227 122.792 18.3384C122.792 23.2409 122.862 27.5652 122.931 27.9815C123.184 29.1609 125.073 29.4615 125.465 28.3746C125.557 28.0971 125.695 24.6515 125.764 20.6971C125.879 14.8927 125.949 13.5977 126.202 13.8752C126.363 14.0602 126.801 14.6384 127.17 15.1471C127.538 15.6559 127.93 16.1646 128.045 16.3034C128.137 16.419 128.437 16.8352 128.736 17.2284C129.013 17.5984 129.404 18.1302 129.612 18.3846C129.819 18.639 131.639 21.0902 133.666 23.819C135.97 26.9177 137.56 28.8602 137.905 28.9759C138.297 29.1146 138.643 29.0684 139.034 28.814L139.61 28.444V18.639C139.61 9.45837 139.587 8.83399 139.195 8.48712C138.596 7.93212 137.629 8.00149 137.214 8.60274C136.892 9.04212 136.846 10.1984 136.846 16.5346L136.823 23.9346L136.316 23.2409C136.039 22.8709 135.625 22.3621 135.417 22.154C135.187 21.9227 135.003 21.6684 135.003 21.599C135.003 21.5065 134.242 20.4427 133.344 19.2402C132.422 18.0377 131.501 16.8352 131.316 16.5577C131.132 16.3034 130.856 15.9796 130.694 15.8871C130.533 15.7946 130.395 15.5634 130.395 15.4015C130.395 15.2396 130.095 14.8002 129.704 14.4302C129.335 14.0602 129.013 13.644 129.013 13.5052C129.013 13.3665 128.759 13.0196 128.437 12.719C128.114 12.4184 127.861 12.0484 127.861 11.8634C127.861 11.7015 127.769 11.5627 127.676 11.5627C127.561 11.5627 126.985 10.8921 126.386 10.0596C124.797 7.88587 123.806 7.49274 123.138 8.78774Z"
        fill={colors[5]}
      />
      <path
        d="M156.244 8.71811C155.99 9.08811 155.714 9.61998 155.622 9.94373C155.529 10.2675 155.161 11.1462 154.815 11.9094C154.47 12.6725 154.101 13.5512 154.009 13.875C153.917 14.1987 153.686 14.6612 153.525 14.9156C153.341 15.17 153.203 15.5169 153.203 15.6787C153.203 15.8637 152.949 16.5112 152.627 17.1587C152.304 17.7831 152.051 18.4075 152.051 18.5231C152.051 18.6156 151.797 19.2862 151.475 20.0031C151.152 20.6969 150.645 21.8531 150.323 22.5469C150 23.2637 149.747 23.8881 149.747 23.9575C149.747 24.0269 149.493 24.6281 149.217 25.2756C148.526 26.8019 148.318 27.6806 148.48 28.2356C148.664 28.7906 149.632 29.2069 150.231 29.0219C150.668 28.8831 151.843 26.64 152.143 25.3912C152.212 25.0444 152.419 24.6281 152.58 24.4662C152.742 24.3044 152.995 23.7494 153.133 23.2406C153.433 22.2231 155.414 17.3206 157.004 13.7594L157.418 12.8344L157.718 13.5281C157.879 13.8981 158.017 14.3606 158.04 14.4994C158.04 14.6612 158.248 15.1237 158.501 15.54C158.755 15.9562 158.962 16.4881 158.962 16.6962C158.962 16.9044 159.169 17.4362 159.4 17.8525C159.653 18.2687 159.976 19.055 160.137 19.5869C160.298 20.1419 160.483 20.5812 160.575 20.5812C160.667 20.5812 160.736 20.72 160.736 20.8587C160.736 21.0206 161.45 22.8937 162.303 25.0212C163.662 28.3744 163.915 28.8831 164.422 28.9756C165.274 29.1375 165.781 28.9294 166.104 28.3281C166.426 27.7037 166.288 27.1719 164.837 23.8187C164.376 22.7319 163.823 21.3906 163.639 20.8125C163.431 20.2344 163.201 19.7256 163.109 19.6562C163.017 19.5869 162.855 19.2862 162.763 18.9625C162.671 18.6387 162.118 17.2975 161.565 15.9562C160.989 14.615 160.114 12.4875 159.607 11.2156C159.1 9.94373 158.524 8.71811 158.317 8.48686C157.764 7.86248 156.773 7.97811 156.244 8.71811Z"
        fill={colors[6]}
      />
    </svg>
  );
};

export default Logo;

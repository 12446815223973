/** @jsxImportSource @emotion/react */
import React from "react";
import { useTranslation } from "react-i18next";
import Text from "src/components/ui/text";
import Form from "react-bootstrap/esm/Form";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useThemedCss } from "src/hooks/styles";
import styles, { IStyles } from "src/pages/agent/market-campaigns/work/MarketCampaignWorkSubmitPropertyPage.styles";
import Button from "src/components/ui/form/button";
import { useAgentMarketCampaignCreatePropertyRequest } from "src/hooks/api/agent/market-campaign";
import { useToastStore } from "src/store/toast";
import { useBooleanState } from "src/hooks/boolean";
import {
  FormValue,
  baseSchema,
  landlordFormSchema,
  contactSchema,
} from "src/pages/landlords/forms/schema";
import ContactFormPart from "src/pages/landlords/forms/ContactFormPart";
import * as yup from "yup";
import lazyWithRetry from "src/utils/lazy-with-retry";
import CenterPreloader from "src/components/loaders/CenterPreloader";
import { isObject } from "src/utils/helpers";
import { useRouterParamId } from "src/hooks/router";
import PhotoUpload from "src/components/photo-upload";
import type { PhotoUploadValue } from "src/components/photo-upload/PhotoUpload";
import { usePropertyPhotoUploadLandlordRequest } from "src/hooks/api/property";
import MarketCampaignPropertyContactConversationHistory
  from "src/pages/agent/market-campaigns/components/MarketCampaignPropertyContactConversationHistory";
import Divider from "src/components/ui/divider";

const AdditionalInformationFormPart = lazyWithRetry(
  () => import(/* webpackChunkName: "AdditionalInformationFormPart" */ "src/pages/landlords/forms/AdditionalInformationFormPart"),
);
const RentalInformationFormPart = lazyWithRetry(
  () => import(/* webpackChunkName: "RentalInformationFormPart" */ "src/pages/landlords/forms/RentalInformationFormPart"),
);
const PropertyInformationFormPart = lazyWithRetry(
  () => import(/* webpackChunkName: "PropertyInformationFormPart" */ "src/pages/landlords/forms/PropertyInformationFormPart"),
);
const LocationFormPart = lazyWithRetry(
  () => import(/* webpackChunkName: "LocationFormPart" */ "src/pages/landlords/forms/LocationFormPart"),
);

const schema = baseSchema.concat(yup.object({
  landlord_form: landlordFormSchema,
  contact: contactSchema,
}).required());

const initialFormValue: FormValue = {
  contact: {
    name: "",
    email: "",
    phone: "",
    business_name: "",
  },
  address: "",
  city: "",
  state: "",
  zip_code: "",
  rent_price: "",
  external_listed_at: "",
  hoa_monthly: "",
  bedrooms: "",
  bathrooms: "",
  home_type: "",
  property_area: "",
  deposit_amount: "",
  external_url: "",
  authorize_list_property: true,
  referral_code: null,
  market_campaign: null,
  landlord_form: {
    furnishing: "",
    apply_options: [],
    income_requirement: "",
    lease_length: [],
    amenities: [],
    aware_str_regulations: "",
    additional_information: "",
    have_other_properties: false,
    consider_selling: "",
    consider_selling_period: "",
    interested_in_portfolio_expand: "",
    interested_in_portfolio_expand_details: "",
    want_be_reached: "",
    have_management_team: "",
    open_for_property_management: "",
  },
};

const MarketCampaignWorkSubmitPropertyPage: React.FC = () => {
  const { t } = useTranslation();
  const themedCss = useThemedCss<IStyles>(styles);
  const { addToastDanger, addToastSuccess } = useToastStore();
  const id = useRouterParamId("id");

  const [photos, setPhotos] = React.useState<PhotoUploadValue[]>([]);

  const formMethods = useForm<FormValue>(
    {
      defaultValues: initialFormValue,
      mode: "onBlur",
      reValidateMode: "onBlur",
      resolver: yupResolver(schema),
    },
  );
  const {
    handleSubmit,
    setError,
    setFocus,
    clearErrors,
    reset,
  } = formMethods;

  const {
    state: isCompleted,
    setTrue: setIsCompleted,
    setFalse: setIsNotCompleted,
  } = useBooleanState(false);

  const {
    state: isPhotosCompleted,
    setTrue: setIsPhotosCompleted,
  } = useBooleanState(false);

  const {
    mutate,
    isError: isRequestError,
    error: requestError,
    isSuccess: isRequestSuccess,
    isLoading: isRequestLoading,
    data: mutateData,
  } = useAgentMarketCampaignCreatePropertyRequest(id);

  const {
    mutate: mutatePhoto,
    isError: isPhotoRequestError,
    isSuccess: isPhotoRequestSuccess,
    status: photoRequestStatus,
  } = usePropertyPhotoUploadLandlordRequest();

  React.useEffect(() => {
    if (isRequestSuccess && mutateData?.data) {
      clearErrors();
      reset(initialFormValue);
      addToastSuccess({
        title: t("Property has been submitted."),
      });
      setIsCompleted();
      if (photos.length > 0) {
        const formData = photos.map((photo) => {
          const fd = new FormData();

          fd.append("photo", photo.file);
          fd.append("property_hash", mutateData.data.property_hash);

          return fd;
        });
        mutatePhoto({
          id: mutateData.data.id,
          formData,
        });
      }
    }
  }, [isRequestSuccess, mutateData]);

  React.useEffect(() => {
    if (isPhotoRequestSuccess) {
      addToastSuccess({
        title: t("Photos have been uploaded."),
      });
      setPhotos([]);
      setIsPhotosCompleted();
    }
  }, [isPhotoRequestSuccess]);

  React.useEffect(() => {
    if (isRequestError && requestError?.response?.data) {
      let focusField: string | null = null;
      if (requestError.response.data.detail) {
        addToastDanger({
          title: requestError.response.data.detail,
        });
      }
      if (requestError.response.data.market_campaign) {
        addToastDanger({
          title: t("Error occurred"),
          body: requestError.response.data.market_campaign.join(" "),
        });
      }

      // @ts-ignore
      Object.keys(initialFormValue).forEach((key: keyof FormValue & string) => {
        if (!requestError.response!.data[key] || typeof requestError.response!.data[key] === "string") {
          return;
        }

        if (Array.isArray(requestError.response!.data[key])) {
          if (focusField === null) {
            focusField = key;
          }
          setError(key, {
            // @ts-ignore
            code: "server",
            // @ts-ignore
            message: requestError.response.data[key].join(" "),
          });
          return;
        }

        // @ts-ignore
        if (isObject(requestError.response!.data[key])) {
          if (initialFormValue[key].detail) {
            addToastDanger({
              title: initialFormValue[key].detail,
            });
          }
          Object.keys(initialFormValue[key]).forEach((key2) => {
            // @ts-ignore
            if (requestError.response!.data[key][key2]) {
              if (focusField === null) {
                focusField = `${key}.${key2}`;
              }
              // @ts-ignore
              setError(`${key}.${key2}`, {
                // @ts-ignore
                code: "server",
                // @ts-ignore
                message: requestError.response!.data[key][key2].join(" "),
              });
            }
          });
        }
      });
      if (focusField !== null) {
        setFocus(focusField);
      }
    }
  }, [isRequestError, requestError]);

  const onSubmit = (formValue: FormValue) => {
    // @ts-ignore
    const newData: FormValue = Object.keys(formValue).reduce<FormValue>((acc, key: keyof FormValue) => {
      if (typeof formValue[key] === "object" && formValue[key] !== null) {
        return {
          ...acc,
          [key]: Object.keys(formValue[key]).reduce<FormValue["landlord_form"] | FormValue["contact"]>(
            // @ts-ignore
            (acc2, key2: keyof FormValue["contact"] & string | keyof FormValue["landlord_form"] & string) => {
              if (formValue[key][key2] === "") {
                return acc2;
              }
              return {
                ...acc2,
                [key2]: formValue[key][key2],
              };
            },
            {},
          ),
        };
      }
      if (formValue[key] === "") {
        return acc;
      }
      return {
        ...acc,
        [key]: formValue[key],
      };
    }, {});

    let phone = "";

    if (newData.contact.phone) {
      phone = newData.contact.phone.startsWith("+1") ? newData.contact.phone : `+1${newData.contact.phone}`;
    }

    mutate({
      ...newData,
      contact: {
        ...newData.contact,
        phone,
      },
      referral_code: null,
      market_campaign: id,
    });
  };

  const handleResetClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setIsNotCompleted();
    reset();
  };

  const handlePhotoChange = (files: PhotoUploadValue[]) => {
    setPhotos(files);
  };

  if (isCompleted) {
    return (
      <section css={themedCss.section}>
        <Text.Title alignment="center" level={1} className="mb-4">
          {t("Property has been submitted.")}
        </Text.Title>
        {!isPhotosCompleted && !isPhotoRequestError && photos.length > 0 && (
          <Text alignment="center" color="success" className="mb-3">
            {t("Uploading photos...")}
          </Text>
        )}
        {isPhotoRequestError && (
          <Text alignment="center" color="danger" className="mb-3">
            {t("Error occurred while uploading photos.")}
          </Text>
        )}
        {mutateData?.data && (
          <>
            <h3>
              Add conversation with landlord
            </h3>
            <MarketCampaignPropertyContactConversationHistory
              prop={mutateData.data}
            />
            <Divider className="my-3" />
          </>
        )}
        <div className="text-center pt-3">
          <Button
            variant="primary"
            onClick={handleResetClick}
            loading={photoRequestStatus === "loading"}
          >
            {t("Submit another property")}
          </Button>
        </div>
      </section>
    );
  }

  return (
    <section css={themedCss.section}>
      <FormProvider {...formMethods}>
        <Form
          name="landlord-submit-property"
          onSubmit={handleSubmit(onSubmit)}
          css={themedCss.form}
        >
          <Text.Title level={3} className="mb-4">
            {t("Owner contact information")}
          </Text.Title>
          <ContactFormPart
            labels={{
              "contact.name": t("Landlord name") as string,
              "contact.email": t("Landlord email") as string,
              "contact.phone": t("Landlord phone number") as string,
            }}
          />

          <Text.Title level={3} className="mb-4">
            {t("Property location")}
          </Text.Title>
          <React.Suspense fallback={<CenterPreloader />}>
            <LocationFormPart />
          </React.Suspense>

          <Text.Title level={3} className="mb-4">
            {t("Rental information")}
          </Text.Title>
          <React.Suspense fallback={<CenterPreloader />}>
            <RentalInformationFormPart />
          </React.Suspense>

          <Text.Title level={3} className="mb-4">
            {t("Property information")}
          </Text.Title>
          <React.Suspense fallback={<CenterPreloader />}>
            <PropertyInformationFormPart />
          </React.Suspense>

          <Text.Title level={3} className="mb-4">
            {t("Photos")}
          </Text.Title>
          <React.Suspense fallback={<CenterPreloader />}>
            <PhotoUpload<PhotoUploadValue[]>
              multiple
              onChange={handlePhotoChange}
              className="mb-5"
            />
          </React.Suspense>

          <Text.Title level={3} className="mb-4">
            {t("Additional information (landlord's answers)")}
          </Text.Title>
          <React.Suspense fallback={<CenterPreloader />}>
            <AdditionalInformationFormPart />
          </React.Suspense>

          <div css={themedCss.formFooter}>
            <Button
              type="submit"
              variant="primary"
              className="mt-5 w-100"
              loading={isRequestLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("Submit property")}
            </Button>
          </div>
        </Form>
      </FormProvider>
    </section>
  );
};

export default MarketCampaignWorkSubmitPropertyPage;

import { useState } from "react";

export type UseBooleanStateFunc = (initialState: boolean | (() => boolean)) => {
  state: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
  set: (value: boolean) => void;
};

export const useBooleanState: UseBooleanStateFunc = (initialState) => {
  const [state, setState] = useState<boolean>(initialState);

  const setTrue = () => {
    setState(true);
  };

  const setFalse = () => {
    setState(false);
  };

  const toggle = () => {
    setState(!state);
  };

  const set = (newState: boolean) => {
    setState(newState);
  };

  return {
    state,
    setTrue,
    setFalse,
    toggle,
    set,
  };
};
